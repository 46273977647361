import React, { useState, useEffect, useRef} from 'react';
import '../../spinner.css';
import { toast } from 'react-toastify';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import '../../spinner.css';
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { getPedagogyData, postLearning, getUserPedagogyLearningData } from '../../services/LearningService';
import VideoContent from './LearnerTheoryContent/VideoContent';
import ImageContent from './LearnerTheoryContent/ImageContent';
import TextContent from './LearnerTheoryContent/TextContent';
import AudioContent from './LearnerTheoryContent/AudioContent';
import GameContent from './LearnerTheoryContent/GameContent';
import {getCurrentUTCTimestamp} from '../../services/Utils';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import moment from 'moment';

export default function LearnerTheory() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [pedagogy, setPedagogy] = useState([]);
    const [pedagogyLearning, setPedagogyLearning] = useState([]);
    const [module, setModule] = useState({});
    const [startTs, setStartTs] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const [showTimer, setShowTimer] = useState(false);
    const [remainingTime, setRemainingTime] = useState(null);
    const currentTime = useRef(null);
    const prevTime = useRef(null);
    const isNewTimeFirstTick = useRef(false);
    const swiperRef = useRef(null);
    const [shakeActive, setShakeActive] = useState(true);

    const renderTime = ({ remainingTime }) => {
        if (currentTime.current !== remainingTime) {
            isNewTimeFirstTick.current = true;
            prevTime.current = currentTime.current;
            currentTime.current = remainingTime;
        } else {
            isNewTimeFirstTick.current = false;
        }

        // force one last re-render when the time is over to trigger the last animation
        if (remainingTime === 0) {
            setTimeout(() => {
                setRemainingTime((val) => (val === null ? 0 : val - 1));
            }, 20);
        }

        const isTimeUp = isNewTimeFirstTick.current;

        return (
            <div className="time-wrapper">
                <div key={remainingTime} className={`time ${isTimeUp ? 'up' : ''}`}>
                    {remainingTime}
                </div>
                {prevTime.current !== null && (
                    <div
                        key={prevTime.current}
                        className={`time ${!isTimeUp ? 'down' : ''}`}
                    >
                        {prevTime.current}
                    </div>
                )}
            </div>
        );
    };

    const handleTimerEnd = () => {
        setShowTimer(false);
        if(selectedIndex + 1 < pedagogy.length){
            nextPedagogy(selectedIndex+1);
        }
    }

    const handleRepeat = () => {
        setShowTimer(false);
        nextPedagogy(selectedIndex);
        
    }

    const nextAutoPedagogy = async (idx) => {
        setShowTimer(true);

    };

    const goToSlide = (index) => {
        if (swiperRef.current) {
          swiperRef.current.swiper.slideTo(index);
        }
    };

    useEffect(() => {
        setStartTs(moment.utc());
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
            const isAlreadySentHi = sessionStorage.getItem('qBotHi');
            if(isAlreadySentHi === undefined || isAlreadySentHi === null || isAlreadySentHi !== userDetails.user.first_name){
                const dfMessenger = document.querySelector('df-messenger');
                dfMessenger.renderCustomText('Hello '+userDetails.user.first_name+', I am your friendly assistant. Let us make your learning journey seamless and enjoyable! Just type your question, and I will provide you with the information you need. Happy learning!', true);
                sessionStorage.setItem('qBotHi', userDetails.user.first_name);
            }
            getPedagogy();
        }
        const timer = setTimeout(() => {
            setShakeActive(false);
        }, 5000);
        return () => clearTimeout(timer);
    },[]);

    const getPedagogy = async () => {
        const userDetails = getUserDetails();
        let module = sessionStorage.getItem('currentModule') !== undefined ? JSON.parse(sessionStorage.getItem('currentModule')) : {};
        setModule(module);
        let module_id = module.module_id;
        if(module_id !== undefined){
            setLoading(true);
            const pedagogyData = await getPedagogyData(userDetails.token, module_id);
            if(pedagogyData.error){
              toast.error("No Pedogogy Found!");
              setPedagogy([]);
            }else if(pedagogyData.data.length > 0){
                setPedagogy(pedagogyData.data);
                await getUserPedagogyLearning(module_id, userDetails.token, pedagogyData.data);
                
                setSelectedIndex(0);
                await addLearning(module, 0, pedagogyData.data);
            }else{
                setPedagogy(pedagogyData.data);
            }          
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Optional smooth scrolling animation
            });
            setLoading(false);
        }else{
            setPedagogy([]);
            toast.info("No Pedogogy Found!");
            navigate('/learner/courses');
        }
    };

    const getUserPedagogyLearning = async (module_id, token, pedagogy_data) => {
        const pedagogyLearningData = await getUserPedagogyLearningData({'module_id': module_id}, token);
        if(pedagogyLearningData.error){
          setPedagogyLearning({});
        }else{
            let data = pedagogyLearningData.data;
            let learningMap = {};
            for(let i = 0;i<data.length;i++){
                learningMap[data[i].pedagogy_id] = 1;
            }
            learningMap[pedagogy_data[0].id] = 1;
            setPedagogyLearning(learningMap);
            
        }

    };

    const getNextModule = async () => {
        await addLearning(module, selectedIndex, pedagogy);
        let courses = sessionStorage.getItem('modules') !== undefined ? JSON.parse(sessionStorage.getItem('modules')).courses : [];
        for(let i=0; i<courses.length; i++){
            if(module.course_id === courses[i].course_id){
                let allModules = courses[i].modules;
                let foundNextModule = false;
                for(let j=0; j<allModules.length; j++){
                    if(parseInt(module.module_seq_no) + 1 === parseInt(allModules[j].module_seq_no)){
                        foundNextModule = true;
                        let currentModule = Object.assign(allModules[j]);
                        currentModule['course_id'] = courses[i].course_id;
                        currentModule['course_code'] = courses[i].course_code;
                        currentModule['course_name'] = courses[i].course_name;
                        currentModule['course_desc'] = courses[i].course_desc;
                        currentModule['color'] = courses[i].color;
                        setModule(currentModule);
                        sessionStorage.setItem("currentModule", JSON.stringify(currentModule));
                        if(currentModule.module_type === 'ASSESSMENT'){
                            navigate('/learner/assessment');
                        }else if(currentModule.module_type === 'THEORY' || currentModule.module_type === 'GAME' || currentModule.module_type === 'APPLICATION'){
                            await getPedagogy();
                        }
                    }
                }
                if(!foundNextModule){
                    navigate('/learner/courses');
                }
            }
        }

    };

    const getPrevModule = async () => {
        await addLearning(module, selectedIndex, pedagogy);
        let courses = sessionStorage.getItem('modules') !== undefined ? JSON.parse(sessionStorage.getItem('modules')).courses : [];
        for(let i=0; i<courses.length; i++){
            if(module.course_id === courses[i].course_id){
                let allModules = courses[i].modules;
                for(let j=0; j<allModules.length; j++){
                    if(parseInt(module.module_seq_no) - 1 === parseInt(allModules[j].module_seq_no)){
                        let currentModule = Object.assign(allModules[j]);
                        currentModule['course_id'] = courses[i].course_id;
                        currentModule['course_code'] = courses[i].course_code;
                        currentModule['course_name'] = courses[i].course_name;
                        currentModule['course_desc'] = courses[i].course_desc;
                        currentModule['color'] = courses[i].color;
                        setModule(currentModule);
                        sessionStorage.setItem("currentModule", JSON.stringify(currentModule));
                        if(currentModule.module_type === 'ASSESSMENT'){
                            navigate('/learner/assessment');
                        }else if(currentModule.module_type === 'THEORY'){
                            await getPedagogy();
                        }else if(currentModule.module_type === 'APPLICATION'){
                            navigate('/learner/application');
                        }
                    }
                }
            }
        }
    };

    const addLearning = async (module, idx, pedagogies) => {
        if(pedagogies.length > 0){
            let currentTs = moment.utc();
            let secs = currentTs.diff(startTs, "seconds");
            setStartTs(currentTs);
            const userDetails = getUserDetails();
            let objectToSend = {module_id: module.module_id, pedagogy_id: pedagogies[idx].id, learning_sec: secs, cohort_id: userDetails.user.cohort_id};
            const resp = await postLearning(userDetails.token, objectToSend);
            if(resp.error){
            }
        }

    };

    const nextPedagogy = async (idx) => {
        setLoading1(true);
        await addLearning(module, selectedIndex, pedagogy);
        await addLearning(module, idx, pedagogy);
        setSelectedIndex(idx);
        let updatedLearning = { ...pedagogyLearning };
        updatedLearning[pedagogy[idx].id] = 1;
        setPedagogyLearning(updatedLearning);
        await new Promise(resolve => setTimeout(resolve, 1000));
        goToSlide(idx);
        setLoading1(false);
    };



    return (
        <>
        <div>
          {loading ? (
            <div className="spinner-container">
              <img src='images/loading.gif' alt='GIF' />
            </div>
          ) : (
            <div className='py-10'>
                <div className='lg:px-12 px-6 lg:pb-2 pb-1 md:border-b-2 md:border-[#D0D0D0]'>
                    <h3 className='text-2xl text-secondary inter-700 mb-3 lg:hidden'>{module.course_name}</h3>
                    <div className='beardcrumb-wrap mb-5'>
                        <ul className='flex flex-wrap items-center'>
                            <li className='prev-page text-sm lg:text-lg inter-400 relative lg:mr-6 mr-4'>
                                <a href='/learner/courses'>My Courses</a>
                            </li>
                            <li className='prev-page text-sm lg:text-lg inter-400 relative lg:mr-6 mr-4'>
                                <a href='/learner/courses'>{module.course_name}</a>
                            </li>
                            <li className='current-page text-sm lg:text-lg inter-400 relative lg:mr-6 mr-4'>
                                Module {module.module_seq_no}
                            </li>
                        </ul>
                    </div>
                    <div className='course-heading-wrap lg:mb-6 mb-5'>
                        {/* <h3 className='text-tertiary text-xl lg:text-2xl inter-500 mb-1'>Lesson {module.module_seq_no}</h3> */}
                        <h2 className='text-[#1D548D] text-2xl inter-500'>{module.module_desc}</h2>
                    </div>

                </div>
                {pedagogy.length===0? (
                    <div className='lg:pt-10 pt-0 lg:px-12 px-6 pb-8 border-b-2 border-[#D0D0D0]'>
                        <h4 className='text-lg text-bold inter-400 mb-4'>Nothing to learn in this module.</h4>
                    </div>
                ):(
                    <>
                    {(loading1 || showTimer) ? (
                        <>
                        {loading1 ? (
                        <div className="spinner-container">
                            <img src='images/loading.gif' alt='GIF' />
                        </div>
                        ) : (
                        <div className='px-0 lg:px-12'>
                            <div className="timer-wrapper lg:mt-10 mt-8 items-center relative w-full h-[200px] md:h-[430px] lg:h-[650px] bg-[#eee]">
                                <div className='vdo-rply-btn-wrap flex justify-center mr-4 md:mr-6'>
                                    <button onClick={() => handleRepeat()} className='video-replay-btn w-20 h-12 rounded-full flex items-center justify-center'>
                                        <img className='max-h-full transition-transform duration-1000' src='../images/replay-icon.svg' alt='replay icon' />
                                    </button>
                                </div>
                                <CountdownCircleTimer
                                isPlaying
                                duration={3}
                                colors={[ "#F7B801", "#A30000", "#A30000"]}
                                colorsTime={[3, 2, 1]}
                                onComplete={handleTimerEnd}
                                >
                                {renderTime}
                                </CountdownCircleTimer>
                                <div className='vdo-next-btn-wrap flex justify-center ml-4 md:ml-6'>
                                    <button onClick={() => handleTimerEnd()} className='video-next-btn w-20 h-12 rounded-full flex items-center justify-center transition-transform duration-1000'>
                                        <img className='max-h-full' src='../images/next-icon.svg' alt='next icon' />
                                    </button>
                                </div>
                            </div>
                        </div>
                        )}
                        </>

                    ) : (
                        <div>
                            <VideoContent onCompleteVideo={nextAutoPedagogy} pedagogyValue={pedagogy[selectedIndex]} idx={selectedIndex} total={pedagogy.length}/>
                            <ImageContent pedagogyValue={pedagogy[selectedIndex]} />
                            <TextContent pedagogyValue={pedagogy[selectedIndex]} />
                            <AudioContent pedagogyValue={pedagogy[selectedIndex]} />
                            <GameContent pedagogyValue={pedagogy[selectedIndex]} />
                        </div>
                    )}

                        
                        <div className='dash-course-wrap mt-2 md:mt-3 lg-mt-5'>
                            <div className='course-slider-wrap mt-2 lg:pl-12 md:pl-6 md:pr-6 pl-0 pr-0 lg:pr-0 pb-5'>
                                <div className='xl:mt-2 lg:mt-2 md:mt-2 mt-4'>
                                    <div className='course-slider-row pb-3 flex overflow-hidden'>
                                        <div className='course-slider dtl-course-slider overflow-hidden pt-4 md:pt-12 md:pl-2 md:pb-7 pb-3 w-full'>
                                            <Swiper
                                                ref={swiperRef}
                                                spaceBetween={10}
                                                slidesPerView={1.5}
                                                freeMode={true}
                                                navigation={true}
                                                speed={1500}
                                                mousewheel={true}
                                                modules={[Navigation, FreeMode]}
                                                breakpoints={{
                                                640: {
                                                    slidesPerView:1.5,
                                                    spaceBetween:10,
                                                },
                                                768: {
                                                    slidesPerView:3.5,
                                                    spaceBetween:10,
                                                },
                                                1024: {
                                                    slidesPerView:4.5,
                                                    spaceBetween:20,
                                                },
                                                1400: {
                                                    slidesPerView:5.5,
                                                },
                                                }}
                                                className='overflow-visible'
                                            >
                                                {pedagogy.map((value, index) => (
                                                    <SwiperSlide key={index}>
                                                        <div>
                                                            <a onClick={() => nextPedagogy(index)} className={`cursor-pointer ${selectedIndex === index ? 'course-card-active' : ''} course-card flex flex-col justify-between ${selectedIndex === index ? 'bg-[#58C181]' : 'bg-[#6E3174]'} min-h-52 2xl:min-h-[230px] rounded-3xl py-6 lg:pl-5 xl:pl-7 pl-5 lg:pr-5 xl:pr-10 pr-5 block hover:scale-x-[1.04] hover:scale-y-[1.09] hover:shadow-lg transition-all duration-300 ease-in-out`}>
                                                                <div className='course-icon mb-4'>
                                                                    <img className='lg:w-6 h-6 2xl:w-8' src={pedagogyLearning[value.id] ===1 ? '../images/greentick.svg' : '../images/book-w.svg'} alt='greentick'/>
                                                                </div>
                                                                <p className='lg:text-lg 2xl:text-xl lg:leading-6 text-white inter-600'>{value.pedagogy_seq_no}. {value.title}</p>
                                                                <div className='play-icon-wrap'>
                                                                    <img className='play-icon w-20 mx-auto' src='../images/play-icon.svg' alt='play icon'/>
                                                                </div>
                                                            </a>  
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}


                <div className='lg:py-10 md:py-8 py-0 lg:px-12 px-6 course-navigate-btns flex flex-wrap justify-between'>
                    <a onClick={() => getPrevModule()} className='w-full md:w-max mb-4 md:mb-0 cursor-pointer bg-secondary hover:bg-black flex items-center justify-center px-6 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Previous Module</a>
                    <a onClick={() => getNextModule()} className='w-full md:w-max cursor-pointer bg-secondary hover:bg-black flex items-center justify-center px-6 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Next Module</a>
                </div>
            </div>
          )}
        </div>
        {/* In chat-bbl-text we need to add class "active-chat" on click chat bubble */}
        <div className={shakeActive? 'chat-bbl-text fixed bottom-7 right-24 z-50 shake' : 'chat-bbl-text fixed bottom-7 right-24 z-50'}>
            <p className='text-xs inter-500 text-white bg-[#620b57] py-3 px-3 rounded-md relative'>
                Ask Me Anything
            </p>
        </div>
        <df-messenger
            project-id="qlitex"
            agent-id="5e294f2b-c5c9-49a4-9170-20db654a8460"
            language-code="en"
            max-query-length="-1"
            allow-feedback="all">
            <df-messenger-chat-bubble
            chat-title="Q-Bot" chat-icon="https://storage.googleapis.com/q-lite/Icon-01.svg" chat-title-icon="https://storage.googleapis.com/mediajenie/QLITE_Logo-01.svg">
            </df-messenger-chat-bubble>
        </df-messenger>
        </>
    );
}