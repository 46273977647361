import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import '../spinner.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { getUserDetails, logout, storeUserDetails } from '../services/UserService';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LearnerDashboard from './LearnerDashboard';
import LearnerCourses from './learnings/LearnerCourses';
import LearnerModules from './learnings/LearnerModules';
import LearnerPedagogy from './learnings/LearnerPedagogy';
import LearnerHeader from './layout/LearnerHeader';
import LeanerSidebar from './layout/LearnerSidebar';
import LearnerAssessment from './learnings/LearnerAssessment';
import LearnerCongrats from './learnings/LearnerCongrats';
import LearnerTheory from './learnings/LearnerTheory';
import LearnerCertificate from './learnings/LearnerCertificate';
import LearnerCertificateCompletion from './learnings/LearnerCertificateCompletion';
import LearnerScore from './learnings/LearnerScore';
import LearnerResetPassword from './learnings/LearnerResetPassword';
import LearnerProfile from './learnings/LearnerProfile';
import Swal from 'sweetalert2';
import { learnerTeacherIntroVideo } from '../utility/constants';

export default function LearnerRoutes() {
  const navigate = useNavigate();
  useEffect(() => {
    const userDetails = getUserDetails();
    if(userDetails.token === undefined){
      navigate('/login');
    }else if(userDetails.user.role === "SUPER_ADMIN"){
      navigate('/superadmin/dashboard');
    }
    else if(userDetails.user.role === "ACCOUNT_OWNER"){
      navigate('/admin/dashboard');
    }else if(userDetails.user.role === "LEARNER" && userDetails.user.is_first_time_login === true){
      userDetails.user.is_first_time_login = false;
      storeUserDetails(userDetails);
        Swal.fire({
          title: "Introduction",
          html: 
          `                        
          <video autoPlay controls>
              <source src=${learnerTeacherIntroVideo} type="video/mp4" />
              Your browser does not support the video tag.
          </video>
          ` ,
          width: '80%',
          height: '50%',
          showClass: {
            popup: `
              animate__animated
              animate__fadeInUp
              animate__faster
            `
          },
          hideClass: {
            popup: `
              animate__animated
              animate__fadeOutDown
              animate__faster
            `
          },
          showConfirmButton: true,
          confirmButtonText: "Close",
          allowOutsideClick: false
        });
    }

    

  },[]);
  return (
    <div className='flex'>
      <div className='sidebar-wrap fixed top-0 left-0 h-full hidden lg:block z-50'>
        <LeanerSidebar />
      </div>
      <div className='dash-rigth'>
        <div className='header-wrap'>
          <LearnerHeader />
        </div>
         <Routes>
          <Route path="*" element={<LearnerDashboard/>} />
          <Route path="/learner/dashboard" element={<LearnerDashboard/>} />
          <Route path="/learner/courses" element={<LearnerCourses/>} />
          <Route path="/learner/modules" element={<LearnerModules/>} />
          <Route path="/learner/pedagogy" element={<LearnerPedagogy/>} />
          <Route path="/learner/assessment" element={<LearnerAssessment/>} />
          <Route path="/learner/theory" element={<LearnerTheory/>} />
          <Route path="/learner/assessmentcomplete" element={<LearnerCongrats/>} />
          <Route path="/learner/profile" element={<LearnerProfile/>} />
          <Route path="/learner/passwordreset" element={<LearnerResetPassword/>} />
          <Route path="/learner/score" element={<LearnerScore/>} />
          <Route path="/learner/certificate" element={<LearnerCertificate/>} />
          <Route path="/learner/certificatecompletion" element={<LearnerCertificateCompletion/>} />
         </Routes>
      </div>
    </div>
  );
};
