import { API_URL } from "../utility/constants";


export async function getAllCourse(token) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminCourse/getAllCourse", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getCourseInSubscription(token, course_id) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminCourse/getCourseInSubscription?course_id="+course_id, {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function addCourse(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/adminCourse/addNewCourse`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function updateCourse(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/adminCourse/updateCourse`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function getModuleByCourseId(token, course_id) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminModule/getModuleByCourseId?course_id="+course_id, {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function createModule(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/adminModule/createModule`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function updateModule(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/adminModule/updateModule`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function addCourseInSubscription(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/adminCourse/addCourseInSubscription`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function deleteCourseInSubscription(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/adminCourse/deleteCourseInSubscription`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function getAllModules(token) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminModule/getAllModules", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function deleteModule(token, module_id) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminModule/deleteModuleById?module_id="+module_id, {
            method: 'DELETE',
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getPedagogy(token, module_id) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminPedagogy/getPedagogy?module_id="+module_id, {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function addPedagogy(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/adminPedagogy/addPedagogy`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function updatePedagogy(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/adminPedagogy/updatePedagogy`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function deletePedagogy(token, pedagogy_id) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminPedagogy/deletepedagogyById?pedagogy_id="+pedagogy_id, {
            method: 'DELETE',
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getAssessments(token, module_id) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminAssessment/getAssessments?module_id="+module_id, {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function createAssessment(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/adminAssessment/createAssessment`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function updateAssessment(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/adminAssessment/updateAssessment`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function deleteAssessment(token, assessment_id) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminAssessment/deleteAssessmentById?assessment_id="+assessment_id, {
            method: 'DELETE',
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function updateSequenceNumber(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/adminPedagogy/updateSequenceNumber`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function updateCourseSequenceNumber(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/adminCourse/updateCourseSequenceNumber`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function updateModuleSequenceNumber(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/adminModule/updateModuleSequenceNumber`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function deleteMultiplePedagogy(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/adminPedagogy/deleteMultiplePedagogy`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};