import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { getAllCohortDataWithAllStatus } from '../../services/CohortService';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { pageSizes } from '../../utility/constants';
import { formatSixDigitNumber, capitalizeFirstLetter } from '../../services/CommonService';
import { updateCohortData } from '../../services/CohortService';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'


export default function ManageCohort() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [cohorts, setCohorts] = useState([]);
    const [colDefs, setColDefs] = useState([
        {headerName: "Unique Id", field: "uid", resizable: true },
        {headerName: "Cohort Name", field: "cohort_desc", resizable: true },
        {headerName: "Org Name", field: "org_name", resizable: true },
        {headerName: "No. Of Learners", field: "num_of_learners", resizable: true },
        {
            headerName: 'Add Learners',
            resizable: true,
            cellRenderer: params => {
                const onClick = () => {
                    sessionStorage.setItem("addLearnersInCohort", JSON.stringify(params.data));
                    navigate('/admin/createlearner');
                };
                return (
                    <>
                        <div className='relative course-navigate-btns flex items-center justify-between h-full'>
                            <a onClick={onClick} className='cursor-pointer bg-secondary hover:bg-black flex items-center justify-center px-4 h-7 text-white inter-600 text-xs rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Add Learners</a>
                        </div>
                    </>
                );
            },
            
        },
        {
            headerName: 'Status',
            valueGetter: (params) => {
                return params.data.status;
            },
            cellRenderer: params => {
                const onClick = () => {
                    sessionStorage.setItem("cohortEdit", JSON.stringify(params.data));
                    navigate('/admin/createcohort');
                };
                return (
                    <>
                        <div onClick={onClick} className='cursor-pointer flex items-center h-full' >
                            {params.data.status === 'ACTIVE' ? (
                                <div className='active-status text-xs inter-500 leading-none bg-[#EBF9F1] text-[#1F9254] py-2 px-2 rounded-3xl'>Active</div>
                            ) : (
                                null
                            )}
                            {params.data.status === 'SUSPENDED' ? (
                                <div className='suspend-status text-xs inter-500 leading-none bg-[#FEF2E5] text-[#CD6200] py-2 px-2 rounded-3xl'>Suspended</div>
                            ) : (
                                null
                            )}
                            {params.data.status === 'CANCELLED' ? (
                                <div className='cancel-status text-xs inter-500 leading-none bg-[#FBE7E8] text-[#A30D11] py-2 px-2 rounded-3xl'>Cancelled</div>
                            ) : (
                                null
                            )}
                        </div>
                    </>
                );
            },
            sortable: true,
            filter: true
        },
        {
            headerName: 'Actions',
            resizable: true,
            cellRenderer: params => {
                const onClick = () => {
                    sessionStorage.setItem("cohortEdit", JSON.stringify(params.data));
                    navigate('/admin/createcohort');
                };
                const onClickPermission = () => {
                    sessionStorage.setItem("cohortCourseEdit", JSON.stringify(params.data));
                    navigate('/admin/cohortcourses');
                };
                const onClickDelete = async () => {
                    Swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        cancelButtonText: 'No, cancel!',
                        reverseButtons: true
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            const userDetails = getUserDetails();
                            let cohortData = Object.assign(params.data);
                            cohortData["status"] = 'CANCELLED';
                            setLoading(true);
                            let resp = await updateCohortData(cohortData, userDetails.token);
                            if(resp.error){
                                toast.error(resp.data);
                            }else{
                                getCohorts(userDetails.token);
                                toast.success(resp.data);
                            }
                            setLoading(false);
                        } 
                    });

                };

                return (
                    <>
                        <div className='relative course-navigate-btns flex items-center justify-between h-full'>
                            <a onClick={onClickPermission} className='cursor-pointer bg-tertiary hover:bg-black flex items-center justify-center px-4 h-7 text-white inter-600 text-xs rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Permissions</a>
                            <a onClick={onClick} className='cursor-pointer flex items-center justify-center transition duration-300'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path d="M11.083 4H4.08301C3.55257 4 3.04387 4.21071 2.66879 4.58579C2.29372 4.96086 2.08301 5.46957 2.08301 6V20C2.08301 20.5304 2.29372 21.0391 2.66879 21.4142C3.04387 21.7893 3.55257 22 4.08301 22H18.083C18.6134 22 19.1221 21.7893 19.4972 21.4142C19.8723 21.0391 20.083 20.5304 20.083 20V13" stroke="#624DE3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M18.583 2.49998C18.9808 2.10216 19.5204 1.87866 20.083 1.87866C20.6456 1.87866 21.1852 2.10216 21.583 2.49998C21.9808 2.89781 22.2043 3.43737 22.2043 3.99998C22.2043 4.56259 21.9808 5.10216 21.583 5.49998L12.083 15L8.08301 16L9.08301 12L18.583 2.49998Z" stroke="#624DE3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </a>
                            <a onClick={onClickDelete} className='cursor-pointer flex items-center justify-center transition duration-300'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path d="M3.08301 6H5.08301H21.083" stroke="#A30D11" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8.08301 6V4C8.08301 3.46957 8.29372 2.96086 8.66879 2.58579C9.04387 2.21071 9.55257 2 10.083 2H14.083C14.6134 2 15.1221 2.21071 15.4972 2.58579C15.8723 2.96086 16.083 3.46957 16.083 4V6M19.083 6V20C19.083 20.5304 18.8723 21.0391 18.4972 21.4142C18.1221 21.7893 17.6134 22 17.083 22H7.08301C6.55257 22 6.04387 21.7893 5.66879 21.4142C5.29372 21.0391 5.08301 20.5304 5.08301 20V6H19.083Z" stroke="#A30D11" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M10.083 11V17" stroke="#A30D11" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M14.083 11V17" stroke="#A30D11" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </a>
                        </div>
                    </>
                );
            },
            
        }
    ]);
    const defaultColDef = useMemo(() => {
        return {
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            flex: 1,
            minWidth: 150,
        };
    }, []);
    const onGridReady = (params) => {
        params.api.sizeColumnsToFit();
    };
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
          setUserDetails(userDetails);
          getCohorts(userDetails.token);
        }
    },[]);
    const getCohorts = async (token) => {
        const cohortsData = await getAllCohortDataWithAllStatus(token);
        if(cohortsData.error){
            return [];
        }else{
            let chs = [];
            for(let i=0;i<cohortsData.data.length; i++){
                if(cohortsData.data[i].status !== 'CANCELLED'){
                    cohortsData.data[i]['num_of_learners'] = cohortsData.data[i]['cohort_learners_count'] !== undefined && cohortsData.data[i]['cohort_learners_count'] !== null ? parseInt(cohortsData.data[i]['cohort_learners_count']) : 0;
                    cohortsData.data[i]['uid'] = cohortsData.data[i]['id'] !== undefined && cohortsData.data[i]['id'] !== null ? "UID" + formatSixDigitNumber(cohortsData.data[i]['id']) : "";
                    chs.push(cohortsData.data[i]);
                }

            }
            setCohorts(chs);
        }
    };
    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className='py-10'>
                    <h3 className='text-2xl text-secondary text-center inter-700 lg:hidden mb-4'>Manage Cohort</h3>
                    <div className='flex justify-end lg:mx-12 lg:px-0 px-6 pb-5 mb-6 border-b-2 border-[#D0D0D0]'>
                        <button onClick={() => navigate('/admin/createcohort')} type="button" className='bg-secondary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow w-full md:w-auto'>+ Add New Cohort</button>
                    </div>
                    <div className='lg:px-20 px-6'>
                        <div
                            className="ag-theme-quartz"  
                            style={{ width: '100%', height: 500 }} // the Data Grid will fill the size of the parent container
                        >
                            <AgGridReact
                                rowData={cohorts}
                                columnDefs={colDefs}
                                rowSelection="multiple"
                                defaultColDef={defaultColDef}
                                suppressRowClickSelection={true}
                                pagination={true}
                                paginationPageSize={20}
                                paginationPageSizeSelector={pageSizes}
                                onGridReady={onGridReady}
                                rowClass='rowClass'
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}