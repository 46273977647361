import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { pageSizes } from '../../utility/constants';
import {formatSixDigitNumber} from '../../services/CommonService';
import { toast } from 'react-toastify';
import { userDefaultPicture } from '../../utility/constants';
import '../../spinner.css';
import Select from 'react-select';
import { getAllCohortsWithCustomer, getSuperAdminCohortLearnerReport } from '../../services/SuperAdminReportService';

export default function SuperAdminLearnerReport() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [allCohorts, setAllCohorts] = useState([]);
    const [cohorts, setCohorts] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedCohort, setSelectedCohort] = useState(null);
    const [userReportData, setUserReportData] = useState([]);
    const [type, setType] = useState("all");

    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
            const initializeCohort = async () => {
                await getCohorts(userDetails.token);
            };
            initializeCohort();
            
            
        }
    },[]);
    

    const [colDefs, setColDefs] = useState([
        {headerName: "Unique ID", field: "uid"},
        {headerName: 'Profile',
            cellRenderer: params => {
                return (
                    <>
                        <div className='flex items-center justify-center h-full'>
                            <div className='tabl-prof-img w-8 h-8 rounded-lg bg-white flex items-center justify-center'>
                                <img className='cursor-pointer w-6 h-6 rounded-image' src={params.data.image_link !== null && params.data.image_link !== undefined ? params.data.image_link : userDefaultPicture} alt='user'/>
                            </div>
                        </div>
                    </>
                );
            },
            
        },
        {headerName: "User", field: "name"},
        {headerName: "Email ID", field: "email" },
        {headerName: "Modules Completed", field: "modules_completed" },
        {headerName: "Tests Completed", field: "tests_completed" },
        {headerName: "Avg Score", field: "avg_po_score" },
        {headerName: 'Time Spent',
            cellRenderer: params => {
                return (
                    <>
                        <div className='flex items-center h-full'>
                            {params.data.hour !== undefined && params.data.hour !== null ? (
                                params.data.hour+ " Hour " + params.data.min + " Min" 
                            ):
                            (
                                "0 Hour 0 Min"
                            )}
                        </div>
                    </>
                );
            },
            
        },
        {headerName: 'Status',
            cellRenderer: params => {
                return (
                    <>
                        <div className='flex items-center h-full'>
                            {params.data.status === 'ACTIVE' ? (
                                <div className='active-status text-xs inter-500 leading-none bg-[#EBF9F1] text-[#1F9254] py-2 px-2 rounded-3xl'>Active</div>
                            ) : (
                                null
                            )}
                            {params.data.status === 'SUSPENDED' ? (
                                <div className='suspend-status text-xs inter-500 leading-none bg-[#FEF2E5] text-[#CD6200] py-2 px-2 rounded-3xl'>Suspended</div>
                            ) : (
                                null
                            )}
                            {params.data.status === 'CANCELLED' ? (
                                <div className='cancel-status text-xs inter-500 leading-none bg-[#FBE7E8] text-[#A30D11] py-2 px-2 rounded-3xl'>Cancelled</div>
                            ) : (
                                null
                            )}
                        </div>
                    </>
                );
            },
            
        },
        {headerName: 'Actions',
            cellRenderer: params => {
                const onClickViewReport = () => {
                    sessionStorage.setItem('userReport', JSON.stringify(params.data));
                    navigate('/superadmin/superadminuserreport')
                };

                return (
                    <>
                        <div className='relative course-navigate-btns flex items-center justify-between h-full'>
                            <a onClick={onClickViewReport} className='cursor-pointer bg-tertiary hover:bg-black flex items-center justify-center px-4 h-7 text-white inter-600 text-xs rounded-3xl lg:rounded-full transition duration-300 button-shadow'>View Report</a>
                        </div>
                    </>
                );
            },
            
        }
    ]);
    const defaultColDef = useMemo(() => {
        return {
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            flex: 1,
            minWidth: 100,
            maxWidth: 300,
        };
    }, []);

    const getCohorts = async (token) => {
        const cohortsData = await getAllCohortsWithCustomer(token);
        if(cohortsData.error){
            return [];
        }else{
            setAllCohorts(cohortsData.data);
            let customerObj = {};
            let cohortList = cohortsData.data;
            for(let i=0; i<cohortList.length;i++){
                if(customerObj[cohortList[i].customer_id] === undefined || customerObj[cohortList[i].customer_id] === null){
                    customerObj[cohortList[i].customer_id] = {customer_id: cohortList[i].customer_id, customer_name: cohortList[i].customer_name, value: cohortList[i].customer_id, label: cohortList[i].customer_name, cohorts: []};
                }
                customerObj[cohortList[i].customer_id].cohorts.push({cohort_id: cohortList[i]['cohort_id'], cohort_name: cohortList[i]['cohort_desc'], value: cohortList[i]['cohort_id'], label: (cohortList[i]['cohort_desc'] + (cohortList[i]['org_name'] !== null && cohortList[i]['org_name'] !== undefined ? " - " + cohortList[i]['org_name'] : ""))})
            }
            let allCustomers = Object.values(customerObj);
            setCustomers(allCustomers);

            let selectedCohortForReport = (sessionStorage.getItem('selectedCohortForReport') !== null && sessionStorage.getItem('selectedCohortForReport') !== undefined) ? JSON.parse(sessionStorage.getItem('selectedCohortForReport')) : null;
            if(selectedCohortForReport !== null){
                if(customerObj[parseInt(selectedCohortForReport.customer_id)] !== undefined && customerObj[parseInt(selectedCohortForReport.customer_id)] !== null){
                    // setSelectedCustomer(parseInt(selectedCohortForReport.customer_id));
                    handleCustomerChange(parseInt(selectedCohortForReport.customer_id), allCustomers);
                }
            }else{
                if(allCustomers.length > 0){
                    // setSelectedCustomer(allCustomers[0].customer_id);
                    handleCustomerChange(allCustomers[0].customer_id, allCustomers);
                }
            }
        }
    };

    const handleCustomerChange = async (value, customers) => {
        setSelectedCustomer(value);
        let allCohorts = [];
        for(let i=0; i < customers.length; i++){
            if(value === customers[i].customer_id){
                allCohorts = customers[i].cohorts;
            }
        }
        setCohorts(allCohorts);
        let selectedCohortForReport = (sessionStorage.getItem('selectedCohortForReport') !== null && sessionStorage.getItem('selectedCohortForReport') !== undefined) ? JSON.parse(sessionStorage.getItem('selectedCohortForReport')) : null;
        if(selectedCohortForReport !== null){
            handleCohortChange(parseInt(selectedCohortForReport.cohort_id), allCohorts, value);
        }else{
            if(allCohorts.length > 0){
                handleCohortChange(allCohorts[0].cohort_id, allCohorts, value);
            }
        }
    };

    const handleCohortChange = async (cohort_id, allCohorts, customer_id) => {
        setSelectedCohort(cohort_id);
        sessionStorage.removeItem('selectedCohortForReport')
        for(let i = 0;i < allCohorts.length; i++){
            if(cohort_id === allCohorts[i].cohort_id){
                await getCohortLearnerReportData(cohort_id, customer_id, allCohorts[i], type);
            }
        }
    };

    const getCohortLearnerReportData = async (cohort_id, customer_id, cohort, reportType) => {
        const userDetails = getUserDetails();
        let resp = await getSuperAdminCohortLearnerReport(userDetails.token, cohort_id, customer_id);
        if(resp.error === false){
            let user_data = resp.data.user_data;
            let module_data = resp.data.module_data;
            let user_report = {};
            for(let i=0; i< user_data.length; i++){
                let rptData = Object.assign(user_data[i]);
                rptData['cohort_name'] = cohort["label"]; 
                rptData['uid'] = "UID" + formatSixDigitNumber(rptData.user_id);
                rptData['name'] = (rptData.first_name !== undefined && rptData.first_name !== null ? rptData.first_name : "")
                + " " + (rptData.middle_name !== undefined && rptData.middle_name !== null ? rptData.middle_name: "")
                + " " + (rptData.last_name !== undefined && rptData.last_name !== null ? rptData.last_name: "");
                user_report[user_data[i].user_id] = rptData;
                user_report[user_data[i].user_id]["modules_completed"] = 0;
                user_report[user_data[i].user_id]["learning_sec"] = 0;
                user_report[user_data[i].user_id]["hour"] = 0;
                user_report[user_data[i].user_id]["min"] = 0;
                user_report[user_data[i].user_id]["avg_po_score"] = 0;
                user_report[user_data[i].user_id]["tests_completed"] = 0;

            }

            for(let i=0; i< module_data.length; i++){
                if(user_report[module_data[i].user_id]!==undefined){
                    user_report[module_data[i].user_id]["modules_completed"] =  parseInt(module_data[i]["modules_completed"]);
                    user_report[module_data[i].user_id]["learning_sec"] =  module_data[i]["learning_sec"];
                    user_report[module_data[i].user_id]["hour"] =  module_data[i]["learning_sec"] !== undefined && module_data[i]["learning_sec"] !== null ? Math.floor(parseInt(module_data[i]["learning_sec"])/3600) : 0;
                    user_report[module_data[i].user_id]["min"] =  module_data[i]["learning_sec"] !== undefined && module_data[i]["learning_sec"] !== null ? Math.floor((parseInt(module_data[i]["learning_sec"])%3600)/60) : 0;
                    user_report[module_data[i].user_id]["avg_po_score"] =  parseInt(module_data[i]["avg_po_score"]);
                    user_report[module_data[i].user_id]["tests_completed"] =  parseInt(module_data[i]["tests_completed"]);
                }
            }
            let user_report_array = Object.values(user_report);

            let filtered_user_report_array = [];
            let passing_percentage = parseInt(cohort.passing_per);
            for(let i=0; i<user_report_array.length; i++){
                if(reportType === 'high'){
                    if(passing_percentage + 1 <= user_report_array[i].avg_po_score){
                        filtered_user_report_array.push(user_report_array[i]);
                    }
                }else if(reportType === 'low'){
                    if(passing_percentage - 1 >= user_report_array[i].avg_po_score){
                        filtered_user_report_array.push(user_report_array[i]);
                    }
                }else{
                    filtered_user_report_array.push(user_report_array[i]);
                }
            }
            setUserReportData(filtered_user_report_array);
        }
        
    };


    return (
        <div>
          {loading ? (
            <div className="spinner-container">
              <img src='images/loading.gif' alt='GIF' />
            </div>
            ) : (
                <div className='pt-10 lg:px-12 px-6 pb-12'>
                    <div className='back-btn flex justify-start mb-6 md:mb-6'>
                        <a onClick={() => navigate('/superadmin/superadminreport')} className='cursor-pointer w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-7 2xl:h-8 text-white inter-500 text-xs 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a>
                    </div>
                    <h3 className='text-2xl text-secondary inter-700 mb-4'>Learners Report</h3>
                    <div className='flex justify-center flex-wrap mb-8'>
                            <div className='w-full md:w-3/5'>
                                <Select className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md'
                                    options={customers}
                                    value={customers.find(option => option.value === selectedCustomer)}
                                    onChange={(values) => handleCustomerChange(values.value, customers)}
                                />
                            </div>
                    </div>
                    <div className='flex justify-center flex-wrap mb-8'>
                            <div className='w-full md:w-3/5'>
                                <Select className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md'
                                    options={cohorts}
                                    value={cohorts.find(option => option.value === selectedCohort)}
                                    onChange={(values) => handleCohortChange(values.value, cohorts, selectedCustomer)}
                                />
                            </div>
                    </div>
                    {selectedCohort !== null ? 
                        (
                            <div className='lg:px-12 px-0'>
                                <div
                                    className="ag-theme-quartz" // applying the Data Grid theme
                                    style={{ width: '100%', height: 500 }} // the Data Grid will fill the size of the parent container
                                    >
                                    <AgGridReact
                                        rowData={userReportData}
                                        columnDefs={colDefs}
                                        defaultColDef={defaultColDef}
                                        rowSelection="multiple"
                                        suppressRowClickSelection={true}
                                        pagination={true}
                                        paginationPageSize={20}
                                        paginationPageSizeSelector={pageSizes}
                                    />
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }
                </div>
            )
          }
        </div>
    );
}