import React, { useState, useEffect } from 'react';
import { login, forgotChangePassword, generatePassword, clearStore } from '../services/UserService';
import '../spinner.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { storeUserDetails } from '../services/UserService';

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [loginFormData, setLoginFormData] = useState({email: '', password: '', oldPassword: '', newPassword: '', confirmNewPassword: '', passwordResetEmail: ''});
  const [showPassword, setShowPassword] = useState({password: false, oldPassword: false, newPassword: false, confirmNewPassword: false});
  const [pageType, setPageType] = useState({login: true, returnToLogin: false, forgotPassword: false, confirmPassword: false});
  const navigate = useNavigate();
  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', '#C13F63');
    document.documentElement.style.setProperty('--secondary-color', '#366494');
    document.documentElement.style.setProperty('--tertiary-color', '#58C181');

    clearStore();
  },[]);
  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginFormData({
      ...loginFormData,
      [name]: value,
    });
  };
  const handleSubmit = async (e, buttonClicked) => {
    e.preventDefault();
    if(buttonClicked===1){
      await handleLoginSubmit();
    }else{
      await handleForgotPassword();
    }
  };
  const handleLoginSubmit = async () => {
    setLoading(true);
    let resp = await login(loginFormData);
    setLoading(false);
    if(resp.error===false){
      storeUserDetails(resp.data);
      //localStorage.setItem("userDetails", JSON.stringify(resp.data));
      toast.success("Login Successful!");
      setLoginFormData({email: '', password: '', oldPassword: '', newPassword: '', confirmNewPassword: '', passwordResetEmail: ''});
      const is_first_time_login = resp.data.user.is_first_time_login;
      const role = resp.data.user.role;
      if(role === 'ACCOUNT_OWNER'){
        if(is_first_time_login){
          navigate('/adminguidedtour');
        }else{
          navigate('/admin/dashboard');
        }
      } else if(role === 'LEARNER'){
        if(is_first_time_login){
          navigate('/learnerguidedtour');
        }else{
          navigate('/learner/dashboard');
        }
      } else if(role === 'SUPER_ADMIN'){
        if(is_first_time_login){
          navigate('/saguidedtour');
        }else{
          navigate('/superadmin/dashboard');
        }
      }

    }else{
      toast.error(resp.data);
    }
  };
  const handleForgotPassword = async () => {
    setPageType({login: false, returnToLogin: false, forgotPassword: true, confirmPassword: false});
  };
  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    let resp = await generatePassword({ email: loginFormData.passwordResetEmail});
    setLoading(false);
    if(resp.error===false){
      toast.success(resp.data);
      setPageType({login: false, returnToLogin: false, forgotPassword: false, confirmPassword: true});
    }else{
      toast.error(resp.data);
    }
  };
  const validatePassword = (pwd) => {
    const lengthCheck = pwd.length >= 8;
    const uppercaseCheck = /[A-Z]/.test(pwd);
    const lowercaseCheck = /[a-z]/.test(pwd);
    const numeralCheck = /[0-9]/.test(pwd);
    const specialCharCheck = /[!@#$%^&*(),.?":{}|<>]/.test(pwd);

    let isValid = lengthCheck && uppercaseCheck && lowercaseCheck && numeralCheck && specialCharCheck;
    if(!isValid){
      toast.warning("Password is not valid. Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a numeral, and a special character.");
    }
    return isValid;
  };
  const handleConfirmPassword = async () => {
    if(validatePassword(loginFormData.newPassword)){
      if(loginFormData.newPassword === loginFormData.confirmNewPassword){
        setLoading(true);
        let resp = await forgotChangePassword({oldPassword: loginFormData.oldPassword, newPassword: loginFormData.newPassword, confirmNewPassword: loginFormData.confirmNewPassword, passwordResetEmail: loginFormData.passwordResetEmail});
        setLoading(false);
        if(resp.error===false){
          toast.success(resp.data);
          setPageType({login: false, returnToLogin: true, forgotPassword: false, confirmPassword: false});
          setLoginFormData({email: '', password: '', oldPassword: '', newPassword: '', confirmNewPassword: '', passwordResetEmail: ''});
        }else{
          toast.error(resp.data);
        }
      }else{
        toast.error("Password is not matching!");
      }
    }
    
  };
  const handleReturnToLoginPassword = async () => {
    setPageType({login: true, returnToLogin: false, forgotPassword: false, confirmPassword: false});
  };
  const handleShowPassword = (type) => {
    if(type === 'password'){
      setShowPassword({password: !showPassword.password, oldPassword: false, newPassword: false, confirmNewPassword: false});
    }else if(type === 'oldPassword'){
      setShowPassword({password: 0, oldPassword: !showPassword.oldPassword, newPassword: showPassword.newPassword, confirmNewPassword: showPassword.confirmNewPassword});
    }else if(type === 'newPassword'){
      setShowPassword({password: 0, oldPassword: showPassword.oldPassword, newPassword: !showPassword.newPassword, confirmNewPassword: showPassword.confirmNewPassword});
    }else{
      setShowPassword({password: 0, oldPassword: showPassword.oldPassword, newPassword: showPassword.newPassword, confirmNewPassword: !showPassword.confirmNewPassword});
    }
  };
  return (
    <div>
      {loading ? (
        <div className="spinner-container">
              <img src='images/loading.gif' alt='GIF' />
        </div>
      ) : (
        <div>
        <div className='flex flex-wrap md:h-screen'>
            <div className='w-full lg:w-1/2 h-1/2 lg:h-full'>
              <div className='h-full bg-center bg-no-repeat bg-cover flex items-center justify-center py-9 pl-9 md:pl-12 md:pr-12 lg:pl-12 lg:pr-28 pr-9' style={{backgroundImage : "url('../../images/login-bg.webp')"}}>
                {/* Login left text start */}
                <div className={pageType.login? '': ''}>
                  <h2 className='poppins-bold text-center lg:text-left leading-none text-4xl lg:text-5xl 2xl:text-7xl'><span className='pacifico-regular text-3xl lg:text-4xl 2xl:text-6xl mb-2 lg:mb-6 2xl:mb-9 inline-block'>Welcome to</span><br />
                      {/* QLITE<sup className='text-2xl 2xl:text-4xl align-super leading-none inline-block'>X</sup><br /> */}
                      <img onClick={() => window.open("https://quantumliteracy.org", '_blank')} className='cursor-pointer w-24 md:w-32 mx-auto lg:ml-0 brightness-0 md:mb-4 mb-3' src='../images/qlite-logo.webp' alt='qlite logo'/>
                      E-Learning
                  </h2>
                  <p className='text-base md:text-xl lg:text-3xl 2xl:text-5xl inter-300 mt-3 lg:mt-8 2xl:mt-14 mb-3 lg:mb-8 2xl:mb-12 text-center lg:text-left px-10 md:px-0'>Quantum Literacy to Ignite Technology Education</p>
                  <p className='text-lg text-black inter-700 text-center lg:text-left'>LOG IN</p>
                  <div className='lgn-lft-btm border-t border-white pt-4 lg:pt-8 2xl:pt-10 mt-4'>
                    <div className='flex justify-between'>
                    <div className='w-3/5 md:w-1/2'>
                        <div className='lgn-logos'>
                          <p className='text-white text-xs lg:text-base 2xl:text-lg inter-400 mb-4 lg:mb-5'>From</p>
                          <img onClick={() => window.open("https://quantumliteracy.org", '_blank')} className='cursor-pointer h-10 lg:h-12 2xl:h-12 object-contain' src='../images/quantum-logo.webp' alt='quantum logo'/>
                        </div>
                      </div>
                      <div className='w-auto'>
                        <div className='lgn-logos'>
                          <p className='text-white text-xs lg:text-base 2xl:text-lg inter-400 mb-4 lg:mb-6'>Powered by</p>
                          <img onClick={() => window.open("https://ina-solutions.com", '_blank')} className='cursor-pointer h-8 lg:h-8 2xl:h-12 object-contain' src='../images/ina-logo.webp' alt='ina logo'/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                  <p className='text-sm text-white inter-400 md:mt-8 mt-3'>© Copyright to National Quantum Literacy Network, USA.</p>                  </div>
                </div>
                {/* Login left text end */}
                {/* forgot left text start */}
                <div className={'hidden'}>
                  <h2 className='poppins-bold text-center lg:text-left leading-none text-4xl lg:text-7xl 2xl:text-7xl'>
                      QLITE<sup className='text-4xl 2xl:text-4xl align-super leading-none inline-block'>X</sup>
                  </h2>
                </div>
                {/* forgot left text end */}
              </div>
            </div>
            <div className='w-full lg:w-1/2  h-1/2 lg:h-full bg-white flex items-center'>
              <div className='login-form-card w-full h-full lg:w-11/12 lg:h-3/4 box-shadow-center flex items-center justify-center relative lg:-left-14 p-9' style={{background : "linear-gradient(42deg, #FFF 63.82%, #D9D9D9 158.74%)"}}>
                {/* login right start */}
                <div className={`login-inner ${pageType.login ? '' : 'hidden'} w-full md:w-4/5 lg:w-9/12`}>
                  <h3 className='text-2xl lg:text-4xl 2xl:text-5xl color-366494 inter-700 mb-6 2xl:mb-11'>Log In</h3>
                  <div className='login-form'>
                    <form onSubmit={(e) => handleSubmit(e, 1)} action="">
                      <div className='form-field mb-5'>
                        <label className='block mb-2 lg:mb-4 2xl:mb-5 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Email ID <span className='text-[#f00]'>*</span></label>
                        <input type='email' name="email" placeholder='' value={loginFormData.email} onChange={handleLoginChange} className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                      </div>
                      <div className='form-field'>
                        <label className='block mb-2 lg:mb-4 2xl:mb-5 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Password <span className='text-[#f00]'>*</span></label>
                        <div className='relative'>
                          <input type={showPassword.password ? 'text' : 'password'} name="password" value={loginFormData.password} onChange={handleLoginChange} className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                          <button onClick={() => handleShowPassword('password')}  type='button' className='absolute right-4 top-0 h-full w-5 flex items-center justify-center'>
                            <img className={showPassword.password ? 'pass-view-btn' : 'pass-hide-btn opacity-50'} src={showPassword.password ? '../images/pass-view.webp' : '../images/pass-hide.webp'} alt='password hide'/>
                          </button>
                        </div>
                      </div>
                      <div className='flex items-center mt-8 2xl:mt-11'>
                        <button type='submit' onSubmit={(e) => handleSubmit(e, 1)}  className='login-btn bg-[#c13f63] hover:bg-[#366494] flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Log In</button>
                        <button type='submit' onClick={(e) => handleSubmit(e, 2)} className='fgt-pass text-[#E44E3F] hover:text-[#366494] ml-6 lg:ml-10 2xl:ml-16 text-base 2xl:text-lg underline inter-600 underline-offset-4'>Forgot Password?</button>
                      </div>
                    </form>
                  </div>
                </div>
                {/* login right end */}
                {/* Forgot right start */}
                <div className={`login-inner ${pageType.forgotPassword ? '' : 'hidden'} w-full lg:w-9/12`}>
                  <h3 className='text-2xl lg:text-4xl 2xl:text-5xl color-366494 inter-700 mb-6 2xl:mb-11'>Forgot Your Password?</h3>
                  <p className='text-base lg:text-xl 2xl:text-2xl color-366494 inter-400 mb-6 2xl:mb-11'>Enter your registered ID, and we will send a reset link.</p>
                  <div className='login-form'>
                    <form onSubmit={(e) => handleResetPassword(e)} action="">
                      <div className='form-field mb-5'>
                        <label className='block mb-2 lg:mb-4 2xl:mb-5 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Email ID <span className='text-[#f00]'>*</span></label>
                        <input type='email' name="passwordResetEmail" value={loginFormData.passwordResetEmail} onChange={handleLoginChange} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                      </div>
                      <div className='flex items-center mt-8 2xl:mt-11'>
                        <button type='submit' onSubmit={(e) => handleResetPassword(e)} className='bg-[#c13f63] hover:bg-[#366494] flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow w-full md:w-auto'>Reset My Password</button>
                      </div>
                    </form>
                  </div>
                </div>
                {/* forgot right end */}
                {/* Confirm password right start */}
                <div className={`login-inner ${pageType.confirmPassword ? '' : 'hidden'} w-full lg:w-9/12`}>
                  <h3 className='text-2xl lg:text-4xl 2xl:text-5xl color-366494 inter-700 mb-6 2xl:mb-11'>Update New Password</h3>
                  <div className='login-form'>
                    <form action="">

                      <div className='form-field mb-5'>
                        <label className='block mb-2 lg:mb-4 2xl:mb-5 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Enter Code Sent On Email <span className='text-[#f00]'>*</span></label>
                        <div className='relative'>
                          <input type={showPassword.oldPassword ? 'text' : 'password'} name="oldPassword" value={loginFormData.oldPassword} onChange={handleLoginChange} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                          <button onClick={() => handleShowPassword('oldPassword')} type='button' className='absolute right-4 top-0 h-full w-5 flex items-center justify-center'>
                            <img className={showPassword.oldPassword ? 'pass-view-btn' : 'pass-hide-btn opacity-50'} src={showPassword.oldPassword ? '../images/pass-view.webp' : '../images/pass-hide.webp'} alt='password hide'/>
                          </button>
                        </div>
                      </div>
                      <div className='form-field mb-5'>
                        <label className='block mb-2 lg:mb-4 2xl:mb-5 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>New Password <span className='text-[#f00]'>*</span></label>
                        <div className='relative'>
                          <input type={showPassword.newPassword ? 'text' : 'password'} name="newPassword" value={loginFormData.newPassword} onChange={handleLoginChange} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                          <button onClick={() => handleShowPassword('newPassword')} type='button' className='absolute right-4 top-0 h-full w-5 flex items-center justify-center'>
                            <img className={showPassword.newPassword ? 'pass-view-btn' : 'pass-hide-btn opacity-50'} src={showPassword.newPassword ? '../images/pass-view.webp' : '../images/pass-hide.webp'} alt='password hide'/>
                          </button>
                        </div>
                        <p className='text-[10px] text-gray inter-400 pt-1 leading-none'>Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a numeral, and a special character.</p>
                      </div>
                      <div className='form-field'>
                        <label className='block mb-2 lg:mb-4 2xl:mb-5 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Confirm New Password <span className='text-[#f00]'>*</span></label>
                        <div className='relative'>
                          <input type={showPassword.confirmNewPassword ? 'text' : 'password'} name="confirmNewPassword" value={loginFormData.confirmNewPassword} onChange={handleLoginChange} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                          <button onClick={() => handleShowPassword('confirmNewPassword')} type='button' className='absolute right-4 top-0 h-full w-5 flex items-center justify-center'>
                            <img className={showPassword.confirmNewPassword ? 'pass-view-btn' : 'pass-hide-btn opacity-50'} src={showPassword.confirmNewPassword ? '../images/pass-view.webp' : '../images/pass-hide.webp'} alt='password hide'/>
                          </button>
                        </div>
                      </div>
                      <div className='flex items-center mt-8 2xl:mt-11'>
                        <button onClick={handleConfirmPassword} type="button" className='bg-[#c13f63] hover:bg-[#366494] flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow w-full md:w-auto'>Update My Password</button>
                      </div>
                    </form>
                  </div>
                </div>
                {/* Confirm password right end */}
                {/* Reset password right start */}
                <div className={`login-inner ${pageType.returnToLogin ? '' : 'hidden'} w-full lg:w-9/12`}>
                  <h3 className='text-2xl lg:text-4xl 2xl:text-5xl color-366494 inter-700 mb-6 2xl:mb-11'>Your password has been reset. <br />Log in again.</h3>
                  <div className='login-form'>
                    <div className='flex items-center mt-8 2xl:mt-11'>
                      <button onClick={handleReturnToLoginPassword} type="button" className='bg-[#c13f63] hover:bg-[#366494] flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow w-full md:w-auto'>Log In with New Password</button>
                    </div>
                  </div>
                </div>
                {/* Reset password right end */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}