import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { getAllModules, getPedagogy, deletePedagogy, updateSequenceNumber } from '../../services/CourseService';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { pageSizes } from '../../utility/constants';
import { formatSixDigitNumber, capitalizeFirstLetter } from '../../services/CommonService';

export default function EditPedagogySequence() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    // const [selectedCourse, setSelectedCourse] = useState(0);
    // const [selectedModule, setSelectedModule] = useState({});
    const [pedagogy, setPedagogy] = useState([]);
    
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
            navigate('/login');
        }else{
            setUserDetails(userDetails);
        }
        let pedagogies = sessionStorage.getItem('pedagogies') === null ? null : JSON.parse(sessionStorage.getItem('pedagogies'));
        if(pedagogies !== null){
            for(let i=0; i<pedagogies.length; i++){
                pedagogies[i]["new_pedagogy_seq_no"] = pedagogies[i]["pedagogy_seq_no"];
            }
            setPedagogy(pedagogies);
            sessionStorage.removeItem('pedagogies');
        }else{
            toast.warn("Please select a course and module in manage pedagogy page!");
            navigate('/superadmin/managepedagogy')   
        }
    },[]);

    const handleValueChange = (e, pd) => {
        const { name, value } = e.target;
        setPedagogy((prevItems) =>
            prevItems.map((item) =>
                item.id === pd.id ? { ...item, new_pedagogy_seq_no: value } : item
            )
        );
    };

    const updateSequence = async () => {
        let objectToPost = [];
        for(let i=0; i<pedagogy.length; i++){
            if(pedagogy[i].new_pedagogy_seq_no !== undefined && parseInt(pedagogy[i].new_pedagogy_seq_no) > 0 && parseInt(pedagogy[i].new_pedagogy_seq_no) !== parseInt(pedagogy[i].pedagogy_seq_no)){
                objectToPost.push(pedagogy[i]);
            }
        }
        if(objectToPost.length > 0){
            let resp = await updateSequenceNumber(userDetails.token, {updates: objectToPost});
            if(resp.error === false){
                toast.success("Sequence Number Updated!");
                navigate('/superadmin/managepedagogy');  
    
            }else{
                toast.error("Some Error occurred!");
            }
        }else{
            toast.warn("Nothing to update yet!");
        }
        
    };

    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className='py-10 lg:px-12 px-6'>
                    <h3 className='text-2xl text-secondary inter-700 lg:hidden mb-6'>Edit Pedagogy Sequence</h3>
                    {/* <div className='back-btn flex justify-start mb-5 md:mb-8'>
                        <a onClick={() => navigate('/superadmin/managepedagogy')} className='cursor-pointer w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-7 2xl:h-8 text-white inter-500 text-xs 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a>
                    </div> */}
                    <div className='flex justify-between flex-wrap pb-5 mb-6 border-b-2 border-[#D0D0D0]'>
                        <div className='order-2 lg:order-1 lg:w-[12.5%]'>
                            <button onClick={() => navigate('/superadmin/managepedagogy')} type="button" className='bg-tertiary hover:bg-black flex items-center justify-center px-5 h-8 2xl:h-12 text-white inter-600 text-sm 2xl:text-base rounded-3xl lg:rounded-full transition duration-300 button-shadow w-full md:w-auto'>Back</button>
                        </div>
                        <div className='order-3 lg:order-3 lg:w-[12.5%] flex justify-end'>
                            <button onClick={() => updateSequence()} type="button" className='bg-secondary hover:bg-black flex items-center justify-center px-5 h-8 2xl:h-12 text-white inter-600 text-sm 2xl:text-base rounded-3xl lg:rounded-full transition duration-300 button-shadow w-full md:w-auto'>Update</button>
                        </div>
                    </div>
                    <div className='custom-table-wrap'>
                        <table className='w-full border border-[#D0D0D0]'>
                            <tr>
                                <th className='text-sm md:text-base leading-none inter-600 text-left p-3 border border-[#d0d0d0] bg-[#eee]'>Current Sequence</th>
                                <th className='text-sm md:text-base leading-none inter-600 text-left p-3 border border-[#d0d0d0] bg-[#eee]'>Pedagogy Name</th>
                                <th className='text-sm md:text-base leading-none inter-600 text-left p-3 border border-[#d0d0d0] bg-[#eee]'>New Sequence</th>
                            </tr>
                            {pedagogy.map((pd, index) => (
                            <tr>
                                <td className='text-sm leading-none inter-400 text-left p-3 border border-[#d0d0d0]'>{pd.pedagogy_seq_no}</td>
                                <td className='text-sm leading-none inter-400 text-left p-3 border border-[#d0d0d0]'>{pd.title}</td>
                                <td className='text-sm leading-none inter-400 text-left p-3 border border-[#d0d0d0]'>
                                    <input type='number' name="new_pedagogy_seq_no" value={pedagogy[index].new_pedagogy_seq_no} onChange={(event) => handleValueChange(event, pd)} placeholder='' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                </td>
                            </tr>
                            ))}
                        </table>
                    </div>
                    
                </div>
            )}
        </div>
    );
}