import { API_URL } from "../utility/constants";

export async function getSuperAdminDashboardData(token) {
    try{
        const response = await fetch(API_URL+"superAdmin/adminDashboard/getSuperAdminDashboardData", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getAllAppAdmin(token) {
    try{
        const response = await fetch(API_URL+"superAdmin/appAdmin/getAllAppAdmin", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function addAppAdmin(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/appAdmin/addAppAdmin`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function updateAppAdmin(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/appAdmin/updateAppAdmin`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function deleteAppAdmin(data, token) {
    try{
        const response = await fetch(API_URL+`superAdmin/appAdmin/deleteAppAdmin?user_id=`+data.id, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function getAppAdminPermission(token, id) {
    try{
        const response = await fetch(API_URL+"superAdmin/appAdmin/getAppAdminPermissions?user_id="+id, {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function addAppAdminPermission(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/appAdmin/addAppAdminPermissions`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function updateAppAdminPermission(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/appAdmin/deleteAppAdminPermissions`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function addMultipleAppAdminPermission(token, data) {
    try{
        const response = await fetch(API_URL+`superAdmin/appAdmin/addMultiplePermissions`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};