import React, { useState, useEffect} from 'react';
import '../../spinner.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import {PDFDownloadLink, Page, Document, Image, StyleSheet, View, Text, Font} from '@react-pdf/renderer';
import { formatDateInText } from '../../services/CommonService';
import Confetti from 'react-confetti';

Font.register({
    family: 'Open Sans',
    fonts: [
      { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
      { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
      { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 },
      { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-400.ttf', fontWeight: 400 }

    ]
})

const styles = StyleSheet.create({
    pageBackground: {
      position: 'absolute',
      minWidth: '100%',
      minHeight: '100%',
      display: 'block',
      height: '100%',
      width: '100%',
    },
    page: {
        flexDirection: 'column',
        backgroundColor: '#ffffff'
    },
    title: {
        fontSize: 15,
        marginBottom: 10,
        marginTop: 60,
        textAlign: 'center',
        fontFamily: 'Open Sans',
        fontWeight: 400
    },
    description1: {
        fontSize: 30,
        textAlign: 'center',
        fontFamily: 'Open Sans',
        fontWeight: 1000,
        marginTop: 5,
    },
    description2: {
        fontSize: 15,
        textAlign: 'center',
        fontFamily: 'Open Sans',
        marginTop: 5,
        fontWeight: 'bold'
    },
    description3: {
        fontSize: 13,
        textAlign: 'center',
        fontFamily: 'Open Sans',
        marginTop: 8
    },
    description4: {
        fontSize: 13,
        textAlign: 'center',
        fontFamily: 'Open Sans',
        marginTop: 4
    },
    description5: {
        fontSize: 15,
        textAlign: 'center',
        fontFamily: 'Open Sans',
        marginTop: 5,
        fontWeight: 'bold'
    },
    descriptionInline: {
        fontSize: 17,
        textAlign: 'center',
        fontFamily: 'Open Sans',
        fontWeight: 'bold'
    },
    imageContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 110,
        marginLeft: 100
    },
    marginTop: {
        marginTop: 2,
    },
    image: {
        width: 300,
        height: 44,
        marginTop: 120,
        marginRight: 30,
        marginLeft: 20
    },
    image1: {
        width: 68,
        height: 44,
        marginTop: 50,
        marginLeft: 390
    },
    greenText: {
        color: '#507d2a',
    },
    redText: {
        color: '#fa2222',
    },
});



export default function LearnerCertificateCompletion() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [certificate, setCertificate] = useState({});
    const [userDetail, setUserDetail] = useState({name: "", customer_name: ""});
    const [isActive, setIsActive] = useState(false);
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
            setLoading(true);
            let cert = sessionStorage.getItem('cert') !== undefined ? JSON.parse(sessionStorage.getItem('cert')) : {};
            if(cert !== undefined && cert!== null && cert.course_name !== undefined){
                sessionStorage.removeItem('cert');
                let name = userDetails.user.first_name !== undefined && userDetails.user.first_name !== null ? userDetails.user.first_name : "";
                name = name + (userDetails.user.middle_name !== undefined && userDetails.user.middle_name !== null ? (" "+userDetails.user.middle_name) : "");
                name = name + (userDetails.user.last_name !== undefined && userDetails.user.last_name !== null ? (" " + userDetails.user.last_name ): "");
                let certDate = formatDateInText(cert.updated);
                setUserDetail({name: name, customer_name: userDetails.user.customer_name, cohort_desc: userDetails.user.cohort_desc});
                cert['certDate'] = certDate;
                setCertificate(cert);
            }else{
                navigate('/learner/certificate');
            }
            setLoading(false);
            setIsActive(true);

            // After 7 seconds, start fading out
            const fadeTimer = setTimeout(() => {
            setFadeOut(true);
            }, 7000);

            // Stop confetti after 8 seconds
            const stopTimer = setTimeout(() => {
            setIsActive(false);
            }, 8000);

        }
    },[]);
    const CertificateDoc = () => (
        <Document>
            <Page size="LETTER" orientation="landscape" style={styles.page}>
                <Image src="images/certificate-bg.png" style={styles.pageBackground} />
                <View style={styles.imageContainer}>
                </View>
                <View style={styles.marginTop}>
                    <Text style={styles.title}>{certificate.course_name}</Text>
                    <Text style={styles.description2}>Presented To</Text>
                    <Text style={[styles.description1, styles.greenText]}>{userDetail.name}</Text>
                    <Text style={[styles.description3, styles.greenText]}>In recognition of your dedication and achievement in</Text>
                    <Text style={[styles.description4, styles.greenText]}>Mastering Quantum Literacy Education,</Text>
                    <Text style={[styles.description4, styles.greenText]}>and for increasing your content knowledge in Quantum Physics.</Text>
                    <Text style={[styles.description4, styles.greenText]}>Your commitment to advancing quantum literacy enriches our</Text>
                    <Text style={[styles.description4, styles.greenText]}>learning community and inspires future innovations in workforce development and research.</Text>
                    {/* <Text style={[styles.description4, styles.greenText]}>community and inspires future innovations for workforce development.</Text> */}
                    <Text style={[styles.description5, styles.redText]}>Awarded</Text>
                    <Text style={[styles.description5, styles.redText]}>{certificate.certDate}</Text>
                </View>
                <View style={styles.imageContainer}>
                    <Image style={styles.image1} src="images/sqc-logo.png" />
                </View>
            </Page>
        </Document>
    );
    return (
        <div>
          {loading ? (
            <div className="spinner-container">
              <img src='images/loading.gif' alt='GIF' />
            </div>
            ) : (
                <div style={{height: 'initial'}} className='xl:py-10 xl:px-20 lg:px-12 lg:py-10 py-6 px-6'>
                    {isActive && <Confetti 
                        numberOfPieces={1500}
                        gravity={0.3}  
                        width={window.innerWidth}   // Full width for confetti
                        height={window.innerHeight}  // Full height for confetti
                        style={{
                            position: 'absolute',
                            left: 0,                    // Align to the left
                            bottom: 0,                     // Align to the top
                            opacity: fadeOut ? 0 : 1,
                            transition: 'opacity 1s ease-out',
                        }}
                    />}

                    <div id="contentToDownload" className='background-div relative lg:pt-20 md:pt-32 lg:pb-20 md:pb-32 pb-20 lg:px-20 md:px-24 px-12 flex items-center justify-center'>
                        <div className='flex justify-center'>
                            {/* <div className='flex items-center justify-center bg-white w-32 h-32 rounded-full overflow-hidden'>
                                <img className='w-20 lg:w-20 2xl:w-24' src='../images/course-logo.webp' alt='course logo'/>
                            </div> */}
                            <div className='header-logos flex absolute lg:right-40 md:right-24 right-14 lg:bottom-24 md:bottom-[4.5rem] bottom-20'>
                                {/* <div className='header-logo-sngl md:mr-10 mr-8'>
                                    <img className='h-11 object-contain' src='../images/quantum-black.webp' alt='quantum logo'/>
                                </div> */}
                                <div className='header-logo-sngl'>
                                    <img className='h-10 md:h-16 lg:h-24 object-contain' src='../images/sqc-logo.webp' alt='sqc logo'/>
                                </div>
                            </div>
                        </div>
                        {/* for desktop start */}
                        <div className='text-center hidden lg:block'>
                            <h3 className='text-xl text-black inter-500 mb-3'>{certificate.course_name}</h3>
                            <p className='text-lg text-black inter-700 italic mb-3'>Presented To</p>
                            <h2 className='text-4xl text-[#507d2a] inter-800 mb-3'>{userDetail.name}</h2>
                            {/* <p className='text-base text-[#507d2a] inter-500 mb-4'>In recognition of your dedication and achievement in<br /> Mastering Quantum Literacy Education,<br /> and for meeting the<br /> Next Generation Science Standards in Quantum Physics.<br /> Your commitment to advancing quantum knowledge enriches our learning<br /> community and inspires future innovations for workforce development.</p> */}
                            <p className='text-base text-[#507d2a] inter-500 mb-4'>In recognition of your dedication and achievement in<br /> Mastering Quantum Literacy Education,<br /> and for increasing your content knowledge in Quantum Physics.<br /> Your commitment to advancing quantum literacy enriches our<br /> learning community and inspires future innovations in workforce development and research.</p>
                            <p className='text-xl text-[#fa2222] inter-700'>Awarded<br /> {certificate.certDate}</p>
                            {/* <p className='text-xl text-black inter-700'>{userDetail.cohort_desc}, {userDetail.customer_name}</p>
                            <p className='text-xl text-black inter-700 mb-2'>in</p>
                            <p className='text-2xl text-black inter-400'><b className='md:mr-5'>{certificate.course_name}</b></p> */}
                        </div>
                        {/* for desktop end */}
                        {/* for mobile start */}
                        <div className='text-center lg:hidden'>
                            {/* <h3 className='text-3xl text-black inter-400 mb-4 md:hidden'>Certificate of Completion</h3> */}
                            <h3 className='text-xs md:text-lg text-black inter-500 mb-0'>{certificate.course_name}</h3>
                            <p className='text-xs md:text-base text-black inter-700 italic mb-0'>Presented To</p>
                            <h2 className='text-lg md:text-2xl text-[#507d2a] inter-700 mb-0'>{userDetail.name}</h2>
                            <p className='text-xs md:text-base text-[#507d2a] md:leading-[1.15] leading-tight inter-500 mb-1'>In recognition of your dedication and achievement in Mastering Quantum Literacy Education, and for increasing your content knowledge in Quantum Physics. Your commitment to advancing quantum literacy enriches our<br /> learning community and inspires future innovations in workforce development and research.</p>
                            <p className='text-xs md:text-base text-[#fa2222] inter-700'>Awarded<br /> {certificate.certDate}</p>
                            {/* <p className='text-xl text-black inter-700'>{userDetail.cohort_desc}, {userDetail.customer_name}</p>
                            <p className='text-xl text-black inter-700 mb-2'>in</p>
                            <p className='text-2xl text-black inter-400'><b className='md:mr-5'>{certificate.course_name}</b></p> */}
                        </div>
                        {/* for mobile end */}
                    </div>
                    <div className='flex justify-between flex-wrap'>
                        <div className='w-full md:w-1/2 md:px-10 lg:px-20 mt-4 md:mb-8'>
                            {certificate.course_name !== undefined ? (
                                <a className='cursor-pointer mb-4 md:mb-0 w-full md:w-auto bg-secondary hover:bg-black flex items-center text-center justify-center px-6 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>
                                    <PDFDownloadLink document={<CertificateDoc />} fileName={certificate.course_name.replace(/\s+/g, '_')+"_qlite_certificate.pdf"}>
                                        {({ blob, url, loading, error }) =>
                                            'Download now'
                                        }
                                    </PDFDownloadLink>
                                </a>
                            ):(
                                null
                            )}

                        </div>
                        <div className='w-full md:w-1/2 md:px-10 lg:px-20  mt-4 md:mb-8'>
                            <a onClick={() => navigate('/learner/certificate')} className='cursor-pointer mb-4 md:mb-0 w-full md:w-auto bg-secondary hover:bg-black flex items-center text-center justify-center px-6 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back to Certificates</a>
                        </div>
                    </div>
                    
                </div>
            )
          }
        </div>
    );
}