import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { toast } from 'react-toastify';
import { addCourse, updateCourse, getCourseInSubscription, addCourseInSubscription, deleteCourseInSubscription } from '../../services/CourseService';
import { getAllSubscriptions } from '../../services/SubscriptionService';

export default function AddCourse() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [edit, setEdit] = useState(false);
    const [course, setCourse] = useState({"course_status": 'ACTIVE'});
    const [subscriptions, setSubscriptions] = useState([]);
    const [courseId, setCourseId] = useState(0);
    const [courseSeqNo, setCourseSeqNo] = useState(1);
    useEffect(() => {
        const userDetail = getUserDetails();
        if(userDetail.token === undefined){
          navigate('/login');
        }else{
            setUserDetails(userDetail);
            let courseEdit = sessionStorage.getItem('courseEdit') === null ? null : JSON.parse(sessionStorage.getItem('courseEdit'));
            if(courseEdit !== null){
                setCourse(courseEdit);
                setCourseSeqNo(courseEdit.course_seq_no)
                setEdit(true);
                sessionStorage.removeItem('courseEdit');
            }
            getAllSubscriptionsData(userDetail.token, courseEdit === null ? null : courseEdit.course_id);            
        }
    },[]);

    const getAllSubscriptionsData = async (token, course_id) => {
        setLoading(true);
        let resp = await getAllSubscriptions(token);
        if(resp.error === false){
            let allSubscriptions = resp.data;
            for(let i=0;i<allSubscriptions.length ;i++){
                allSubscriptions[i]["is_present"] = false;
            }
            if(course_id !== null){
                await getCourseSubscriptionsData(course_id, allSubscriptions);
            }else{
                setSubscriptions(allSubscriptions);
            }
            setCourseId(course_id);

        }
        
        setLoading(false);
    };

    const getCourseSubscriptionsData = async (course_id, allSubscriptions) => {
        let subscriptionObj = {};
        for(let i=0;i<allSubscriptions.length ;i++){
            allSubscriptions[i]["is_present"] = false;
            subscriptionObj[allSubscriptions[i].id] = allSubscriptions[i];
        }
        const userDetail = getUserDetails();
        let resp = await getCourseInSubscription(userDetail.token, course_id);
        if(resp.error === false){
            let allCourseSubscriptions = resp.data;
            for(let i=0;i<allCourseSubscriptions.length ;i++){
                if(subscriptionObj[allCourseSubscriptions[i].subscription_id] !== undefined && subscriptionObj[allCourseSubscriptions[i].subscription_id] !== null){
                    subscriptionObj[allCourseSubscriptions[i].subscription_id]["is_present"] = true;
                }
            }
            setSubscriptions(Object.values(subscriptionObj));
        }
    };


    const handleValueChange = (e) => {
        const { name, value } = e.target;
        setCourse({
          ...course,
          [name]: value,
        });
    };

    const handleStatusChange = (course_status) => {
        setCourse({
          ...course,
          ["course_status"]: course_status,
        });
    };

    const handleCourseSubscriptionChange = async (subscription_id, new_status) => {
        const userDetail = getUserDetails();
        let dataToPost = {course_id: courseId, subscription_id: subscription_id, subscription_course_seq_no : courseSeqNo}
        if(new_status){
            setLoading(true);

            let resp = await addCourseInSubscription(userDetail.token, dataToPost);
            if(resp.error === false){
                await getCourseSubscriptionsData(courseId, Object.assign(subscriptions));
                setLoading(false);
                toast.success("Technology added successfully in subscription!");
            }else{
                setLoading(false);
                toast.error("Some error occurred");
            }
        }else{
            setLoading(true);

            let resp = await deleteCourseInSubscription(userDetail.token, dataToPost);
            if(resp.error === false){
                await getCourseSubscriptionsData(courseId, Object.assign(subscriptions));
                setLoading(false);
                toast.success("Technology deleted successfully from subscription!");
            }else{
                setLoading(false);
                toast.error("Some error occurred");
            }
        }
    };

    const validateCourse = () => {
        if(course.course_name === undefined || course.course_name === null || course.course_name === ''
            || course.course_desc === undefined || course.course_desc === null || course.course_desc === ''
            || course.course_code === undefined || course.course_code === null || course.course_code === ''
            || course.color === undefined || course.color === null || course.color === '' 
        ){
           return false; 
        }
        return true;
    };

    const addCourseData = async () => {
        if(validateCourse()){
            setLoading(true);
            let resp = await addCourse(userDetails.token, course);
            if(resp.error === false){
                toast.success("Technology added successfully!");
                setCourseId(resp.course_id);
                setCourseSeqNo(resp.course_seq_no);
            }else{
                toast.error("Some error occurred while adding Technology!");
            }
            setLoading(false);
        }else{
            toast.warn("All fields are mandatory! Please fill all fields.")
        }
    };

    const updateCourseData = async () => {
        if(validateCourse()){
            setLoading(true);
            let resp = await updateCourse(userDetails.token, course);
            if(resp.error == false){
                toast.success("Technology added successfully!");
            }else{
                toast.error("Some error occurred while adding Technology!");
            }
            setLoading(false);
        }else{
            toast.warn("All fields are mandatory! Please fill all fields.")
        }

    };


    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className='lg:py-10 py-6'>
                    <h3 className='text-2xl text-secondary inter-700 px-6 mb-6 md:mb-4 lg:mb-3 lg:hidden'>Add New Technology</h3>
                    <div className='back-btn flex justify-start lg:px-12 px-6 mb-5 md:mb-8'>
                        <a onClick={() => navigate('/superadmin/managecourses')} className='cursor-pointer w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-7 2xl:h-8 text-white inter-500 text-xs 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a>
                    </div>
                    <form className='w-full'>
                        <div className='lg:px-12 px-6 mb-6 md:mb-0 md:pb-6 flex flex-wrap'>
                            <div className='w-full md:w-1/2'>
                                <div className='flex justify-between flex-wrap'>
                                    <div className='form-field w-full md:w-4/5 mb-5 md:mb-8'>
                                        <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Technology Code</label>
                                        <input type='text' name="course_code" value={course.course_code} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                    </div>
                                </div>
                                <div className='flex justify-between flex-wrap'>
                                    <div className='form-field w-full md:w-4/5 mb-5 md:mb-8'>
                                        <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Technology Name</label>
                                        <input type='text' name="course_name" value={course.course_name} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                    </div>
                                </div>

                                <div className='flex justify-between flex-wrap'>
                                    <div className='form-field w-full md:w-4/5 mb-5 md:mb-8'>
                                        <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Description of Technology</label>
                                        <input type='text' name="course_desc" value={course.course_desc} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                    </div>
                                </div>
                                <div className='flex justify-between flex-wrap'>
                                    <div className='form-field w-full md:w-4/5 mb-5 md:mb-8'>
                                        <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Card Color of Technology</label>
                                        <input type='color' name="color" value={course.color} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                    </div>
                                </div>
                                {/* <div>
                                    <div className='flex items-center flex-wrap'>
                                        <div className='customer-radio-sngl mr-5 md:mr-12 mb-5 md:mb-8'>
                                            <input type="radio" value='SUSPENDED' checked={course["course_status"] === "SUSPENDED"} onChange={() => handleStatusChange("SUSPENDED")} id="suspended" name="radio-group" />
                                            <label htmlFor="suspended">Suspended</label>
                                        </div>
                                        <div className='customer-radio-sngl mr-5 md:mr-12 mb-5 md:mb-8'>
                                            <input type="radio" value='ACTIVE' checked={course["course_status"] === "ACTIVE"} onChange={() => handleStatusChange("ACTIVE")} id="active" name="radio-group" />
                                            <label htmlFor="active">Active</label>
                                        </div>
                                        <div className='customer-radio-sngl mr-5 md:mr-12 mb-5 md:mb-8'>
                                            <input type="radio" value='CANCELLED' checked={course["course_status"] === "CANCELLED"} onChange={() => handleStatusChange("CANCELLED")} id="cancelled" name="radio-group" />
                                            <label htmlFor="cancelled">Cancelled</label>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className='w-full md:w-1/2'>
                                {courseId > 0 ? (
                                    <div className='pt-10 lg:px-10 px-6 pb-6 bg-[#F7F6FE]'>
                                        <p className='inter-400 text-xl text-[#6F7482] mb-4'>Available in </p>
                                        <div className='flex items-center flex-wrap'>
                                            {subscriptions.map((value, index) => (
                                                <div key={index} className='icon-check-medium md:pr-0 w-full md:w-1/2'>
                                                <div  className='icon-radio-button flex items-center relative text-black hover:text-tertiary mb-6'>
                                                    <input className='absolute opacity-0 cursor-pointer w-6 h-6 md:w-8 md:h-8 z-20' id={index.toString()} type='checkbox' checked={value.is_present} onChange={() => handleCourseSubscriptionChange(value.id, !value.is_present)} />
                                                    <div className='radio-icon-box cursor-pointer relative z-10'>
                                                        <svg className='mx-auto w-6 h-6 md:w-8 md:h-8 transition duration-300' xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M10.416 8.33317C9.26542 8.33317 8.33268 9.26591 8.33268 10.4165V39.5832C8.33268 40.7338 9.26542 41.6665 10.416 41.6665H39.5827C40.7333 41.6665 41.666 40.7338 41.666 39.5832V10.4165C41.666 9.26591 40.7333 8.33317 39.5827 8.33317H10.416ZM4.16602 10.4165C4.16602 6.96472 6.96424 4.1665 10.416 4.1665H39.5827C43.0345 4.1665 45.8327 6.96472 45.8327 10.4165V39.5832C45.8327 43.0349 43.0345 45.8332 39.5827 45.8332H10.416C6.96424 45.8332 4.16602 43.0349 4.16602 39.5832V10.4165Z" fill="black"/>
                                                        </svg>
                                                        <span className='checkbox-tick inline-block w-3 h-3 absolute left-1/2 -translate-x-1/2 scale-0 rotate-45'></span>
                                                    </div>
                                                    <label className='cursor-pointer' htmlFor="photonics1">
                                                        <p className='text-lg md:text-xl inter-400 text-black pl-4'>{value.subscription_desc}</p>
                                                    </label>
                                                </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ): (
                                    null
                                )}
                            </div>
                        </div>
                        
                        <div className='flex items-center lg:px-12 px-6 mt-6 lg:mt-5'>
                            {edit ? (
                                <button type="button" onClick={() => updateCourseData()} className='w-full md:w-auto bg-secondary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Update Technology</button>
                            ) : (
                                <button type="button" onClick={() => addCourseData()} className='w-full md:w-auto bg-secondary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>+ Add New Technology</button>
                            )}
                        </div>
                        


                    </form>
                </div>
            )}
        </div>
    );
}