import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { getAllModules, updateModuleSequenceNumber } from '../../services/CourseService';
import { toast } from 'react-toastify';


export default function EditModuleSequence() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [modules, setModules] = useState([]);
    
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
            navigate('/login');
        }else{
            setUserDetails(userDetails);
            getAllModulesData(userDetails.token);
        }
        
    },[]);

    const getAllModulesData = async (token) => {
        setLoading(true);
        const modulesData = await getAllModules(token);
        if(modulesData.error){
            return [];
        }else{
            let moduleArray = [];
            for(let i=0;i<modulesData.data.length; i++){
                modulesData.data[i]['new_module_seq_no'] = modulesData.data[i]['module_seq_no'];
                moduleArray.push(modulesData.data[i]);
            }
            setModules(moduleArray);
            console.log(moduleArray);
        }
        setLoading(false);
    };

    const handleValueChange = (e, pd) => {
        console.log(pd);
        const { name, value } = e.target;
        setModules((prevItems) =>
            prevItems.map((item) =>
                item.id === pd.id ? { ...item, new_module_seq_no: value } : item
            )
        );
    };

    const updateSequence = async () => {
        let objectToPost = [];
        for(let i=0; i<modules.length; i++){
            if(modules[i].new_module_seq_no !== undefined && parseInt(modules[i].new_module_seq_no) > 0 && parseInt(modules[i].new_module_seq_no) !== parseInt(modules[i].module_seq_no)){
                objectToPost.push(modules[i]);
            }
        }
        console.log(objectToPost);
        if(objectToPost.length > 0){
            let resp = await updateModuleSequenceNumber(userDetails.token, {updates: objectToPost});
            if(resp.error === false){
                await getAllModulesData(userDetails.token);
                toast.success("Sequence Number Updated!");    
            }else{
                toast.error("Some Error occurred!");
            }
        }else{
            toast.warn("Nothing to update yet!");
        }
        
    };

    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className='py-10 lg:px-12 px-6'>
                    <h3 className='text-2xl text-secondary inter-700 lg:hidden mb-6'>Edit Module Sequence</h3>
                    <div className='flex justify-between flex-wrap pb-5 mb-6 border-b-2 border-[#D0D0D0]'>
                        <div className='order-2 lg:order-1 lg:w-[12.5%]'>
                            <button onClick={() => navigate('/superadmin/managemodules')} type="button" className='bg-tertiary hover:bg-black flex items-center justify-center px-5 h-8 2xl:h-12 text-white inter-600 text-sm 2xl:text-base rounded-3xl lg:rounded-full transition duration-300 button-shadow w-full md:w-auto'>Back</button>
                        </div>
                        <div className='order-3 lg:order-3 lg:w-[12.5%] flex justify-end'>
                            <button onClick={() => updateSequence()} type="button" className='bg-secondary hover:bg-black flex items-center justify-center px-5 h-8 2xl:h-12 text-white inter-600 text-sm 2xl:text-base rounded-3xl lg:rounded-full transition duration-300 button-shadow w-full md:w-auto'>Update</button>
                        </div>
                    </div>
                    <div className='custom-table-wrap'>
                        <table className='w-full border border-[#D0D0D0]'>
                            <tr>
                                <th className='text-sm md:text-base leading-none inter-600 text-left p-3 border border-[#d0d0d0] bg-[#eee]'>Current Sequence</th>
                                <th className='text-sm md:text-base leading-none inter-600 text-left p-3 border border-[#d0d0d0] bg-[#eee]'>Course</th>
                                <th className='text-sm md:text-base leading-none inter-600 text-left p-3 border border-[#d0d0d0] bg-[#eee]'>Module</th>
                                <th className='text-sm md:text-base leading-none inter-600 text-left p-3 border border-[#d0d0d0] bg-[#eee]'>New Sequence</th>
                            </tr>
                            {modules.map((c, index) => (
                            <tr>
                                <td className='text-sm leading-none inter-400 text-left p-3 border border-[#d0d0d0]'>{c.module_seq_no}</td>
                                <td className='text-sm leading-none inter-400 text-left p-3 border border-[#d0d0d0]'>{c.course_code} - {c.course_name}</td>
                                <td className='text-sm leading-none inter-400 text-left p-3 border border-[#d0d0d0]'>{c.module_code} - {c.module_desc}</td>
                                <td className='text-sm leading-none inter-400 text-left p-3 border border-[#d0d0d0]'>
                                    <input type='number' name="new_module_seq_no" value={modules[index].new_module_seq_no} onChange={(event) => handleValueChange(event, c)} placeholder='' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                </td>
                            </tr>
                            ))}
                        </table>
                    </div>
                    
                </div>
            )}
        </div>
    );
}