import React, { useState, useEffect, useRef} from 'react';
import '../spinner.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../services/UserService';

export default function Ngss() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    


    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
           
        }
    },[]);


    return (
        <>
        <div>
          {loading ? (
            <div className="spinner-container">
              <img src='images/loading.gif' alt='GIF' />
            </div>
          ) : (
            <div className='pt-10 lg:px-24 2xl:px-32 px-6 pb-12'>
                <h3 className='text-3xl text-[#366494] inter-700 mb-5'>Standards</h3>
                <p className='text-base text-black text-justify inter-400 mb-6'>The quantum literacy education topics below are modelled after the Next Generation Science Standards*.</p>
                <p className='text-sm text-black text-justify inter-400 mb-3 pb-8'>* "NGSS and Next Generation Science Standards is a registered trademark of WestEd. Neither WestEd nor the lead states and partners that developed the Next Generation Science Standards were involved in the production of this product, and do not endorse it."</p>
                <h3 className='text-xl text-[#C13F63] inter-700 mb-4'> 1. Classical and Quantum Theory Overview</h3>
                <ul className='list-disc pl-5 pb-8'>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Mechanics (HS-PS4-1, HS-PS4-3):</strong> Quantum mechanics is the branch of physics that deals with the behavior of matter and energy on the atomic and subatomic scale. It introduces principles like wave-particle duality and the uncertainty principle, which describe how particles like electrons and photons behave differently from classical objects.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Classical Physics (MS-PS2-2):</strong> Classical physics encompasses theories that describe the behavior of macroscopic objects under normal conditions. It includes Newtonian mechanics, electromagnetism, and thermodynamics, and it is contrasted with quantum mechanics, which applies at the atomic scale.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Superposition (HS-PS4-3):</strong> In quantum mechanics, superposition is the principle that a quantum system can exist in multiple states at once until it is observed or measured. This concept is key to phenomena like quantum computing, where qubits can be in superpositions of '0' and '1'.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Entanglement (HS-PS4-3):</strong> Quantum entanglement is a phenomenon where particles become linked, so the state of one particle instantly influences the state of another, regardless of the distance separating them. This challenges classical ideas of locality and causality.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Wave-Particle Duality (HS-PS4-3):</strong> Wave-particle duality is the concept that particles such as electrons and photons exhibit both wave-like and particle-like properties. For instance, light can be described as both a wave (interference patterns) and a particle (photons).</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Uncertainty Principle (HS-PS4-3):</strong> The uncertainty principle, formulated by Werner Heisenberg, states that it is impossible to simultaneously know the exact position and momentum of a particle. This principle is a fundamental aspect of quantum mechanics.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Atoms (MS-PS1-1):</strong> Atoms are the basic units of matter, composed of a nucleus surrounded by electrons. Quantum mechanics explains the behavior of electrons in atoms, which classical physics cannot.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Electrons (MS-PS1-1):</strong> Electrons are subatomic particles with a negative charge that orbit the nucleus of an atom. Their behavior is described by quantum mechanics, particularly in terms of their energy levels and wavefunctions.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Neutrons (MS-PS1-1):</strong> Neutrons are subatomic particles found in the nucleus of an atom. They have no electric charge and play a key role in the stability of the nucleus.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Protons (MS-PS1-1):</strong> Protons are positively charged particles in the nucleus of an atom. Along with neutrons, they define the mass and the atomic number of an element.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Photons (HS-PS4-3):</strong> Photons are the elementary particles of light, exhibiting both wave-like and particle-like properties. They are the quantum of the electromagnetic field, responsible for electromagnetic radiation.</li>
                </ul>

                <h3 className='text-xl text-[#C13F63] inter-700 mb-4'>2. Photonics</h3>
                <ul className='list-disc pl-5 pb-8'>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Mechanics (HS-PS4-1, HS-PS4-3):</strong> Quantum mechanics is the branch of physics that deals with the behavior of matter and energy on the atomic and subatomic scale. It introduces principles like wave-particle duality and the uncertainty principle, which describe how particles like electrons and photons behave differently from classical objects.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Classical Physics (MS-PS2-2):</strong> Classical physics encompasses theories that describe the behavior of macroscopic objects under normal conditions. It includes Newtonian mechanics, electromagnetism, and thermodynamics, and it is contrasted with quantum mechanics, which applies at the atomic scale.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Superposition (HS-PS4-3):</strong> In quantum mechanics, superposition is the principle that a quantum system can exist in multiple states at once until it is observed or measured. This concept is key to phenomena like quantum computing, where qubits can be in superpositions of '0' and '1'.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Entanglement (HS-PS4-3):</strong> Quantum entanglement is a phenomenon where particles become linked, so the state of one particle instantly influences the state of another, regardless of the distance separating them. This challenges classical ideas of locality and causality.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Wave-Particle Duality (HS-PS4-3):</strong> Wave-particle duality is the concept that particles such as electrons and photons exhibit both wave-like and particle-like properties. For instance, light can be described as both a wave (interference patterns) and a particle (photons).</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Uncertainty Principle (HS-PS4-3):</strong> The uncertainty principle, formulated by Werner Heisenberg, states that it is impossible to simultaneously know the exact position and momentum of a particle. This principle is a fundamental aspect of quantum mechanics.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Atoms (MS-PS1-1):</strong> Atoms are the basic units of matter, composed of a nucleus surrounded by electrons. Quantum mechanics explains the behavior of electrons in atoms, which classical physics cannot.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Electrons (MS-PS1-1):</strong> Electrons are subatomic particles with a negative charge that orbit the nucleus of an atom. Their behavior is described by quantum mechanics, particularly in terms of their energy levels and wavefunctions.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Neutrons (MS-PS1-1):</strong> Neutrons are subatomic particles found in the nucleus of an atom. They have no electric charge and play a key role in the stability of the nucleus.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Protons (MS-PS1-1):</strong> Protons are positively charged particles in the nucleus of an atom. Along with neutrons, they define the mass and the atomic number of an element.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Photons (HS-PS4-3):</strong> Photons are the elementary particles of light, exhibiting both wave-like and particle-like properties. They are the quantum of the electromagnetic field, responsible for electromagnetic radiation.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Photonics (HS-PS4-5):</strong> Photonics is the science and technology of generating, controlling, and detecting photons, particularly in the visible and near-infrared spectrum. It includes the study of light's wave properties and its applications in communication and imaging systems.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Photons (HS-PS4-3):</strong> Photons are particles of light that carry energy proportional to the radiation's frequency. In photonics, they are manipulated for various technological applications, including fiber optics and laser systems.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Wave-Particle Duality (HS-PS4-3):</strong> As described earlier, this principle is fundamental in photonics, explaining how light can behave both as a wave and as particles (photons), depending on the experimental setup.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Coherence (HS-PS4-3):</strong> Coherence refers to the fixed phase relationship between waves of light over time. In quantum optics, coherence is essential for the formation of laser beams and interference patterns.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Interference (MS-PS4-2):</strong> Interference occurs when two or more waves overlap, resulting in a new wave pattern. It is a key concept in wave optics and photonics, explaining phenomena such as diffraction and holography.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Diffraction (HS-PS4-3):</strong> Diffraction is the bending of light waves around obstacles or through openings, which results in interference patterns. This phenomenon is used in various optical instruments and experiments.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Optical Fibers (HS-PS4-5):</strong> Optical fibers are thin strands of glass or plastic that transmit light over long distances. They are widely used in telecommunications, where light signals are modulated to carry data.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Waveguides (HS-PS4-5):</strong> Waveguides are structures that direct electromagnetic waves, including light, along a specific path. They are used in a variety of photonic devices, including optical fibers and lasers.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Dots (HS-PS4-5):</strong> Quantum dots are nanoscale semiconductor particles that have quantum mechanical properties. Their electronic characteristics are closely related to their size and shape, making them useful in photonics, particularly in display technologies and biological imaging.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Detectors (HS-PS4-5):</strong> Photonic detectors convert light into electrical signals. They are critical components in cameras, sensors, and other devices that rely on detecting and processing light.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Coherence (HS-PS4-3):</strong> Quantum coherence refers to the property of quantum states where particles exhibit a fixed phase relationship. In photonics, this is crucial for the operation of quantum devices such as lasers and quantum computers.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Photon Polarization (HS-PS4-3):</strong> Polarization describes the orientation of light waves' oscillations. Control over polarization is important in many photonics applications, including liquid crystal displays (LCDs) and optical filters.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Cryptography (HS-PS4-5):</strong> Quantum cryptography uses principles of quantum mechanics, such as superposition and entanglement, to secure data. It promises theoretically unbreakable encryption due to the laws of quantum physics.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Teleportation (HS-PS4-3):</strong> Quantum teleportation is a process by which quantum information can be transmitted from one location to another, using entanglement and classical communication. It does not involve the physical transfer of particles but rather the transfer of their quantum state.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Interference (HS-PS4-3):</strong> Quantum interference is the phenomenon where the probability amplitudes of quantum states interfere, leading to different probabilities of outcomes. It underlies many quantum mechanical phenomena, such as the double-slit experiment.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Beam Splitter (HS-PS4-3):</strong> A beam splitter is an optical device that divides a beam of light into two or more separate beams. It is widely used in interferometers, spectrometers, and quantum experiments.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Key Distribution (QKD) (HS-PS4-5):</strong> QKD is a secure communication method that uses quantum mechanics to enable two parties to produce a shared, secret random key. It is a key technology in quantum cryptography.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Measurement (HS-PS4-3):</strong> Quantum measurement involves observing a quantum system, which causes the system to collapse into one of the eigenstates of the observable. It plays a crucial role in quantum mechanics and photonics.</li>
                </ul>
                <h3 className='text-xl text-[#C13F63] inter-700 mb-4'>3. Lasers</h3>
                <ul className='list-disc pl-5 pb-8'>
                    <li className='text-base text-black text-justify mb-3'><strong>Laser (HS-PS4-5):</strong> A laser (Light Amplification by Stimulated Emission of Radiation) is a device that emits a focused beam of coherent light through the process of stimulated emission. Lasers are used in various applications, including medicine, communication, and manufacturing.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Stimulated Emission (HS-PS4-5):</strong> This is the process by which an incoming photon stimulates an excited electron in an atom to drop to a lower energy level, resulting in the emission of a second photon. The emitted photon is coherent with the stimulating photon, which is fundamental to laser operation.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Optical Gain (HS-PS4-5):</strong> Optical gain refers to the amplification of light within a laser medium. When a medium provides more stimulated emission than absorption, the intensity of light increases as it passes through, leading to a coherent laser beam.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Coherence (HS-PS4-5):</strong> Coherence in lasers refers to the phase relationship between photons in a laser beam. This coherence allows lasers to produce very narrow, focused beams of light, which is essential for applications requiring precision.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Population Inversion (HS-PS4-5):</strong> This occurs when more electrons in a material are in an excited state than in a lower energy state. Population inversion is a necessary condition for laser operation, enabling stimulated emission to dominate over absorption.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Spiking (HS-PS4-5):</strong> Spiking refers to fluctuations in laser output during the initial stages of laser operation as the system reaches a steady state. This phenomenon can affect the stability and quality of the laser beam.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Photons (HS-PS4-3):</strong> As previously defined, photons are the elementary particles of light, carrying energy that corresponds to the light's frequency. In the context of lasers, photons are emitted coherently to form a laser beam.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Wavelength (MS-PS4-2):</strong> Wavelength is the distance between successive peaks of a wave. In lasers, the wavelength determines the color of the light and is critical for matching the laser's application, whether in surgery, communication, or other uses.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Dot (HS-PS4-5):</strong> Quantum dots are nanoscale semiconductor particles that can emit specific wavelengths of light when stimulated. They are used in lasers to produce precise wavelengths and in displays for vivid color reproduction.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Excited State (HS-PS4-5):</strong> An excited state is a higher energy state of an electron in an atom. In lasers, electrons are pumped into excited states, and their return to lower energy levels produces the photons that form the laser beam.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Relaxation (HS-PS4-5):</strong> Relaxation is the process by which an excited electron returns to a lower energy state, releasing energy in the form of a photon. This process is essential for maintaining the population inversion needed in lasers.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Continuous Wave Mode (HS-PS4-5):</strong> In continuous wave mode, a laser emits a constant, steady beam of light. This mode is useful in applications like cutting, welding, or continuous illumination in experiments.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Pulsed Mode (HS-PS4-5):</strong> In pulsed mode, a laser emits light in pulses rather than a continuous stream. Pulsed lasers are used in applications requiring high peak power, such as in medical treatments or materials processing.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Photoelectric Effect (HS-PS4-5):</strong> The photoelectric effect occurs when light shining on a material causes it to emit electrons. This effect, explained by quantum theory, is fundamental to understanding how light interacts with matter and underpins technologies like photodetectors and solar cells.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Resonator (HS-PS4-5):</strong> A resonator in a laser is a component that reflects light back and forth through the laser medium, amplifying the light through repeated stimulated emission. The resonator is crucial for producing a coherent laser beam.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Gain Medium (HS-PS4-5):</strong> The gain medium is the material in a laser where population inversion and stimulated emission occur. The choice of gain medium determines the laser's wavelength and application.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Output Beam (HS-PS4-5):</strong> The output beam is the coherent light emitted by a laser. The quality, power, and coherence of the output beam are determined by the laser's design and the properties of its components.</li>
                </ul>
                <h3 className='text-xl text-[#C13F63] inter-700 mb-4'>4. Sensors</h3>
                <ul className='list-disc pl-5 pb-8'>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Sensors (HS-PS4-5):</strong> Quantum sensors utilize quantum properties, such as superposition and entanglement, to achieve measurements of physical quantities with higher precision than classical sensors. They are used in fields like metrology, navigation, and medical imaging.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Superposition (HS-PS4-3):</strong> In the context of sensors, superposition allows a quantum sensor to exist in multiple states simultaneously, enhancing the sensor's sensitivity and accuracy in detecting minute changes in the environment.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Entanglement (HS-PS4-3):</strong> Quantum entanglement in sensors ensures that measurements taken by one part of the system instantly affect the state of another part, even if they are far apart. This property is exploited in quantum communication and cryptography.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Heisenberg Uncertainty Principle (HS-PS4-3):</strong> This principle states that certain pairs of physical properties, like position and momentum, cannot both be known to arbitrary precision. Quantum sensors must account for this uncertainty in their measurements.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Tunneling (HS-PS4-3):</strong> Quantum tunneling allows particles to pass through energy barriers that would be insurmountable according to classical physics. This effect is used in scanning tunneling microscopes to image surfaces at the atomic level.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Coherent States (HS-PS4-3):</strong> In quantum sensors, coherent states refer to specific quantum states where quantum uncertainty is minimized, optimizing the sensor's performance in detecting weak signals.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Decoherence (HS-PS4-3):</strong> Decoherence is the process by which a quantum system loses its quantum properties due to interaction with its environment. Understanding and controlling decoherence is crucial for the effectiveness of quantum sensors.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Magnetometers (HS-PS4-5):</strong> Magnetometers are sensors that measure magnetic fields. Quantum magnetometers use principles of quantum mechanics, such as the spin of particles, to achieve highly sensitive measurements.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Atomic Clocks (HS-PS4-5):</strong> Atomic clocks use the vibrations of atoms, usually cesium or rubidium, to measure time with extreme precision. These clocks are crucial for GPS technology, telecommunications, and scientific research.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Scanning Tunneling Microscopes (HS-PS4-5):</strong> These microscopes use quantum tunneling to image surfaces at the atomic level. They are vital tools in nanotechnology, allowing scientists to manipulate atoms and molecules directly.</li>
                </ul>
                <h3 className='text-xl text-[#C13F63] inter-700 mb-4'>5. Networks and Communications</h3>
                <ul className='list-disc pl-5 pb-8'>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Cryptography (HS-PS4-5):</strong> Quantum cryptography leverages the principles of quantum mechanics, such as superposition and entanglement, to enable secure communication. The most common application is Quantum Key Distribution (QKD), which allows two parties to share encryption keys securely.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Internet (HS-PS4-5):</strong> The quantum internet envisions a global network that uses quantum signals for data transmission, offering enhanced security and potentially faster data processing through quantum communication channels.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Teleportation (HS-PS4-3):</strong> This process allows the transfer of quantum states from one location to another without physical movement of the particles, relying on entanglement and classical communication. It is a crucial concept in quantum communication networks.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Qubits (HS-PS4-3):</strong> Qubits are the fundamental units of quantum information, analogous to classical bits but capable of existing in superpositions of '0' and '1'. They are central to quantum computing and communication technologies.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Repeaters (HS-PS4-5):</strong> Quantum repeaters are devices used in quantum communication to extend the distance over which quantum information can be transmitted. They combat loss and noise in quantum signals by entangling distant qubits.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Memory (HS-PS4-5):</strong> Quantum memory stores quantum information, typically in qubits, for later retrieval and use. It is essential for tasks like quantum computing and long-distance quantum communication.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Channels (HS-PS4-5):</strong> Quantum channels are the communication pathways that carry quantum information, such as entangled photons, between different parts of a quantum network.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Key Distribution (QKD) (HS-PS4-5):</strong> QKD is a secure communication method that uses quantum mechanics to generate and distribute cryptographic keys, ensuring that any attempt to eavesdrop on the key exchange is detectable.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Entanglement (HS-PS4-3):</strong> As previously defined, entanglement allows particles to be interconnected in such a way that the state of one instantly influences the state of another, even at a distance. This property is foundational for quantum networks and communications.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Superposition (HS-PS4-3):</strong> Superposition enables qubits to exist in multiple states simultaneously, significantly increasing the computational power and communication efficiency of quantum systems.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Gates (HS-PS4-3):</strong> Quantum gates manipulate qubits in a quantum computer or network, performing operations analogous to classical logic gates but exploiting quantum phenomena like superposition and entanglement.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Circuits (HS-PS4-3):</strong> Quantum circuits are the quantum analogs of classical electronic circuits, consisting of a sequence of quantum gates that process qubits to perform complex calculations or transmit quantum information.</li>
                </ul>
                <h3 className='text-xl text-[#C13F63] inter-700 mb-4'>6. Computing and Quantum Information Science</h3>
                <ul className='list-disc pl-5 pb-8'>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Computing (HS-PS4-5):</strong> Quantum computing uses qubits and quantum gates to perform computations that are infeasible for classical computers. It leverages quantum superposition and entanglement to solve complex problems more efficiently.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Qubits (HS-PS4-3):</strong> As previously described, qubits are the basic units of quantum information. They can represent both '0' and '1' simultaneously, enabling parallel computation in quantum computers.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Gates (HS-PS4-3):</strong> Quantum gates are the building blocks of quantum circuits. They manipulate qubits according to the principles of quantum mechanics, allowing quantum computers to perform computations.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Circuits (HS-PS4-3):</strong> Quantum circuits are sequences of quantum gates that perform complex quantum algorithms. These circuits are fundamental to quantum computing and are used to solve problems that are intractable for classical computers.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Error Correction (HS-PS4-3):</strong> Quantum error correction is a method used to protect quantum information from errors due to decoherence and other quantum noise, enabling more reliable quantum computations.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Cryptography (HS-PS4-5):</strong> As previously defined, quantum cryptography ensures secure communication using quantum mechanics, particularly through techniques like QKD.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Fourier Transform (HS-PS4-5):</strong> The Quantum Fourier Transform (QFT) is a quantum algorithm that is a key component of many quantum algorithms, including Shor's algorithm for factoring large numbers, which has significant implications for cryptography.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Adder (HS-PS4-5):</strong> A quantum adder is a quantum circuit designed to perform addition on qubits. It is an essential component in quantum algorithms for performing arithmetic operations.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Algorithms (HS-PS4-5):</strong> Quantum algorithms are procedures that run on a quantum computer, solving problems more efficiently than classical algorithms by exploiting quantum principles like superposition and entanglement.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Network Diagrams (HS-PS4-5):</strong> These diagrams represent the structure and operation of quantum communication networks, illustrating the connections between quantum nodes, channels, and operations.</li>
                </ul>
                <h3 className='text-xl text-[#C13F63] inter-700 mb-4'>7. Cryogenics</h3>
                <ul className='list-disc pl-5 pb-8'>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Cryogenics (HS-PS1-8):</strong> Quantum cryogenics involves cooling quantum systems to near absolute zero to minimize thermal noise and maintain quantum coherence. It is critical for the operation of superconducting qubits in quantum computers.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Bose-Einstein Condensation (BEC) (HS-PS3-5):</strong> BEC occurs when particles called bosons are cooled to temperatures near absolute zero, causing them to occupy the same quantum state. This state of matter has unique quantum properties used in various quantum experiments.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Fermionic Condensation (HS-PS3-5):</strong> Fermionic condensation is similar to BEC but involves fermions, which obey different quantum statistics. It occurs under extreme conditions and is used to study quantum phenomena in particles that make up matter, like electrons.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Superconductivity (HS-PS1-8):</strong> Superconductivity is the property of certain materials to conduct electricity without resistance when cooled below a critical temperature. It is a key phenomenon used in quantum computing, particularly in superconducting qubits.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Superfluidity (HS-PS1-8):</strong> Superfluidity is the property of liquid helium and certain other substances to flow without viscosity at temperatures close to absolute zero. This quantum phenomenon is used in cryogenic systems and quantum experiments.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Qubits (HS-PS4-3):</strong> As previously described, qubits are the basic units of quantum information, and their behavior at cryogenic temperatures is crucial for maintaining coherence and minimizing errors in quantum computations.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Cryostat (HS-PS1-8):</strong> A cryostat is an apparatus used to maintain very low temperatures, often used in quantum experiments to cool systems like superconducting qubits to the necessary cryogenic temperatures.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Dilution Refrigerators (HS-PS1-8):</strong> These refrigerators achieve temperatures near absolute zero by exploiting the mixing properties of helium-3 and helium-4 isotopes. They are essential for cooling quantum systems in experiments and quantum computing.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Helium-3 (HS-PS1-8):</strong> Helium-3 is an isotope of helium used in cryogenics for achieving ultra-low temperatures, particularly in dilution refrigerators. It is crucial for reaching the millikelvin temperatures needed in quantum experiments.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Helium-4 (HS-PS1-8):</strong> Helium-4 is the more common isotope of helium, used in both cryogenic cooling and in achieving superfluid states, which are critical for certain quantum experiments and cooling methods.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Superconducting Materials (HS-PS1-8):</strong> These materials exhibit superconductivity when cooled below their critical temperature. They are used to create qubits in quantum computers and for other applications requiring zero electrical resistance.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Niobium-Titanium Alloys (HS-PS1-8):</strong> This alloy is commonly used in superconducting magnets, which are crucial components in quantum computing and MRI machines, thanks to their ability to conduct electricity without resistance at cryogenic temperatures.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Heat Exchangers (HS-PS3-5):</strong> Heat exchangers transfer heat between different fluids and are used in cryogenic systems to maintain low temperatures by efficiently managing the thermal load.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Phonons (HS-PS3-5):</strong> Phonons are quantum mechanical vibrations in a crystal lattice that can carry thermal energy. Controlling phonons is essential in cryogenics to reduce heat and maintain the low temperatures needed for quantum computing.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Critical Temperature (HS-PS1-8):</strong> This is the temperature below which a material becomes superconducting. It is a key parameter in the design of superconductors and the cryogenic systems that cool them.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Cooling Power (HS-PS3-5):</strong> Cooling power refers to the ability of a cryogenic system to remove heat from a quantum system, measured in watts at a specific temperature. It determines the system's effectiveness in maintaining the necessary low temperatures.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Heat Leaks (HS-PS3-5):</strong> Heat leaks occur when unwanted heat enters a cryogenic system, raising its temperature. Minimizing heat leaks is critical for maintaining the extremely low temperatures required in quantum experiments.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Thermal Anchoring (HS-PS3-5):</strong> Thermal anchoring involves connecting different parts of a cryogenic system to a common cold point to stabilize the temperature and prevent heat leaks, ensuring the system remains at the desired low temperature.</li>
                </ul>
                <h3 className='text-xl text-[#C13F63] inter-700 mb-4'>8. Quantum Machine Learning</h3>
                <ul className='list-disc pl-5 pb-8'>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Machine Learning (QML) (HS-PS4-5):</strong> Quantum Machine Learning (QML) integrates principles from quantum computing with machine learning algorithms. It aims to leverage the computational power of quantum computers to solve complex problems more efficiently than classical methods, particularly in areas like data analysis, pattern recognition, and optimization.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Computing (HS-PS4-5):</strong> Quantum computing, as previously defined, uses qubits and quantum gates to perform calculations that are exponentially faster for certain tasks than classical computers. It is a foundational technology for QML.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Qubits (HS-PS4-3):</strong> In the context of QML, qubits serve as the data carriers, enabling quantum parallelism, which allows quantum algorithms to process vast amounts of data simultaneously, significantly enhancing machine learning tasks.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Gates (HS-PS4-3):</strong> Quantum gates are the quantum equivalent of classical logic gates and are used to manipulate qubits in quantum circuits. In QML, these gates are used to create quantum algorithms that process and analyze data.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Circuits (HS-PS4-3):</strong> Quantum circuits, composed of sequences of quantum gates, are utilized in QML to implement machine learning models that can perform complex data analysis tasks much faster than their classical counterparts.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Speedup (HS-PS4-5):</strong> Quantum speedup refers to the significant improvement in processing speed that quantum algorithms can achieve over classical algorithms. In QML, this speedup is crucial for handling large datasets and complex computations.</li>
                    <li className='text-base text-black text-justify mb-3'><strong>Quantum Processors (HS-PS4-5):</strong> Quantum processors are the hardware on which quantum algorithms run. In QML, these processors perform the calculations required for machine learning tasks, benefiting from the inherent parallelism of quantum computing.</li>
                </ul>
            </div>
          )}
        </div>
        </>
    );
}