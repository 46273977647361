import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import '../../../spinner.css';
import { H5P } from "h5p-standalone";


const VideoContent = ({ onCompleteVideo, pedagogyValue, idx, total }) => {
    const [fullView, setFullView] = useState(0);
    const [videoIndex, setVideoIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const h5pContainer = useRef(null);
    const [numOfTimes, setNumOfTimes] = useState(false);
    
    useEffect(() => {
        const loadH5PVideo =  async () => {
            if(pedagogyValue.pedagogy_type === 'H5P' && numOfTimes === false){
                const el = h5pContainer.current;
                const options = {
                    h5pJsonPath: pedagogyValue.url,
                    frameJs: "/assets/frame.bundle.js",
                    frameCss: "/assets/styles/h5p.css"
                };
    
                await new H5P(el, options)
                .then((res) => {
                    setNumOfTimes(true);
                    let iframe = el.querySelector('iframe');
                    if (iframe) {
                        handleVideoEndH5P();
                    }
                })
                .catch((e) => {
                    console.log("Err: ", e);
                });
            }
        }
        loadH5PVideo();
        // return () => {
        //     if (h5pContainer.current) {
        //         // Remove all content inside the container
        //         while (h5pContainer.current.firstChild) {
        //             h5pContainer.current.removeChild(h5pContainer.current.firstChild);
        //         }
        //         setNumOfTimes(false);
        //     }
        // };
    
      }, []);

    const resetFullView = () => {
        fullView === 0 ? setFullView(1) : setFullView(0);
    };

    const handleVideoEnd = () => {
        if(idx + 1 < total){
            onCompleteVideo(idx+1);
        }
    };

    const handleVideoEndH5P = () => {
        const iframe = h5pContainer.current.querySelector('iframe');
        if (iframe) {
            iframe.onload = () => {
                setTimeout(() => {
                    const video = iframe.contentDocument?.querySelector('video'); // Access the video inside the iframe
                    if (video) {
                        video.autoplay = true;
                                
                        video.play().then(() => {
                        }).catch((error) => {
                            console.log('Autoplay failed due to browser restrictions', error);
                        });
                        video.addEventListener('ended', () => {
                            if (idx + 1 < total) {
                                onCompleteVideo(idx + 1);
                            }
                        });
                    }
                });

            };

        }
    };

    return (
        <div>
        {loading ? (
          <div className="spinner-container">
            <img src='images/loading.gif' alt='GIF' />
          </div>
        ) : (
            <div>
                {pedagogyValue.pedagogy_type === 'YOUTUBE' ? (
                    <div className='lg:pt-10 pt-0 pb-8 border-b-2 border-[#D0D0D0]'>
                        <div className='video-box lg:px-12 w-full h-[300px] lg:h-[460px] 2xl:h-[520px]'>
                            <iframe width="100%" height="100%" src={pedagogyValue.url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className='lg:px-12 px-6'>
                            <h4 className='text-xl text-black inter-700 mt-4'>{pedagogyValue.pedagogy_seq_no}. {pedagogyValue.title}</h4>
                            {pedagogyValue.text !== undefined && pedagogyValue.text !== '' && pedagogyValue.text !== null ? (
                                <>
                                    {fullView === undefined || fullView === 0 ? (
                                        <p className='text-base inter-400 text-justify text-black mt-4'>
                                            {pedagogyValue.text.substring(0, 300)} &nbsp;&nbsp;&nbsp;
                                            {pedagogyValue.text.length <= 300 ? (
                                                null
                                            ): (
                                                <a onClick={() => resetFullView()} className='cursor-pointer text-secondary'>Show More</a>
                                            )}                                    
                                        </p>
                                    ) : (
                                        <p className='text-base inter-400 text-justify text-black mt-4'>
                                            {pedagogyValue.text} &nbsp;&nbsp;&nbsp;
                                            <a onClick={() => resetFullView()} className='cursor-pointer text-secondary'>Show Less</a>
                                        </p>
                                    )}
                                </>
                            ) : (
                                null
                            )}
                        </div>
                    </div>
                ) : (
                    null
                )}
                {(pedagogyValue.pedagogy_type === 'H5P') ? (
                    <div className='lg:pt-10 pt-0 pb-8 border-b-2 border-[#D0D0D0]'>
                        <div className='video-box w-full lg:px-12'>
                            <div className='custom-vdo-box relative'>

                               <div ref={h5pContainer}></div>
                            </div>
                        </div>
                        <div className='lg:px-12 px-6'>
                            <h4 className='text-xl text-black inter-700 mt-4'>{pedagogyValue.pedagogy_seq_no}. {pedagogyValue.title}</h4>
                            {pedagogyValue.text !== undefined && pedagogyValue.text !== '' && pedagogyValue.text !== null ? (
                                <>
                                    {fullView === undefined || fullView === 0 ? (
                                        <>
                                        <p className='text-base inter-400 text-justify text-black mt-4' dangerouslySetInnerHTML={{ __html: pedagogyValue.text.substring(0, 300) }} />
                                        <p>
                                            <a onClick={() => resetFullView()} className='cursor-pointer text-secondary'>Show More</a>
                                        </p>
                                        </>
                                    ) : (
                                        <>
                                        <p className='text-base inter-400 text-justify text-black mt-4' dangerouslySetInnerHTML={{ __html: pedagogyValue.text }} />
                                        <p>
                                            <a onClick={() => resetFullView()} className='cursor-pointer text-secondary'>Show Less</a>
                                        </p>
                                        </>
                                    )}
                                </>
                            ) : (
                                null
                            )}
                        </div>
                    </div>
                ) : (
                    null
                )}
                {(pedagogyValue.pedagogy_type === 'INTERACTIVE') ? (
                    <div className='lg:pt-10 pt-0 pb-8 border-b-2 border-[#D0D0D0]'>
                        <div className='video-box w-full lg:px-12'>
                            <div className='custom-vdo-box relative'>
                                <div dangerouslySetInnerHTML={{ __html: pedagogyValue.embed_code }} />

                            </div>
                        </div>
                        <div className='lg:px-12 px-6'>
                            <h4 className='text-xl text-black inter-700 mt-4'>{pedagogyValue.pedagogy_seq_no}. {pedagogyValue.title}</h4>
                            {pedagogyValue.text !== undefined && pedagogyValue.text !== '' && pedagogyValue.text !== null ? (
                                <>
                                    {fullView === undefined || fullView === 0 ? (
                                        <>
                                        <p className='text-base inter-400 text-justify text-black mt-4' dangerouslySetInnerHTML={{ __html: pedagogyValue.text.substring(0, 300) }} />
                                        <p>
                                            <a onClick={() => resetFullView()} className='cursor-pointer text-secondary'>Show More</a>
                                        </p>
                                        </>
                                    ) : (
                                        <>
                                        <p className='text-base inter-400 text-justify text-black mt-4' dangerouslySetInnerHTML={{ __html: pedagogyValue.text }} />
                                        <p>
                                            <a onClick={() => resetFullView()} className='cursor-pointer text-secondary'>Show Less</a>
                                        </p>
                                        </>
                                    )}
                                </>
                            ) : (
                                null
                            )}
                        </div>
                    </div>
                ) : (
                    null
                )}
                {pedagogyValue.pedagogy_type === 'VIDEO' ? (
                    <div className='lg:pt-10 pt-0 pb-8 border-b-2 border-[#D0D0D0]'>
                        <div className='video-box w-full lg:px-12'>
                            {/* <iframe width="100%" height="100%" src={pedagogyValue.url} title="Video Player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
                            <div className='relative'>
                                    <video width='100%' className='w-100' autoPlay controls onEnded={handleVideoEnd}>
                                        <source src={pedagogyValue.url} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>


                                {/* <div className='absolute left-0 top-0 w-full h-full bg-black/60'>
                                    <div className='absolute left-8 bottom-8'>
                                        <button className='video-replay-btn w-20 h-12 bg-primary rounded-full flex items-center justify-center shadow-lg'>
                                            <img className='max-h-full transition-transform duration-1000' src='../images/replay-icon.svg' alt='replay icon' />
                                        </button>
                                    </div>
                                    <div className='timer absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-5xl inter-600 shadow-lg w-32 h-32 rounded-full bg-primary flex items-center justify-center'></div>
                                </div> */}
                            </div>
                        </div>
                        <div className='lg:px-12 px-6'>
                            <h4 className='text-xl text-black inter-700 mt-4'>{pedagogyValue.pedagogy_seq_no}. {pedagogyValue.title}</h4>
                            {pedagogyValue.text !== undefined && pedagogyValue.text !== '' && pedagogyValue.text !== null ? (
                                <>
                                    {fullView === undefined || fullView === 0 ? (
                                        <>
                                        <p className='text-base inter-400 text-justify text-black mt-4' dangerouslySetInnerHTML={{ __html: pedagogyValue.text.substring(0, 300) }} />
                                        <p>
                                            <a onClick={() => resetFullView()} className='cursor-pointer text-secondary'>Show More</a>
                                        </p>
                                        </>
                                    ) : (
                                        <>
                                        <p className='text-base inter-400 text-justify text-black mt-4' dangerouslySetInnerHTML={{ __html: pedagogyValue.text }} />
                                        <p>
                                            <a onClick={() => resetFullView()} className='cursor-pointer text-secondary'>Show Less</a>
                                        </p>
                                        </>
                                    )}
                                </>
                            ) : (
                                null
                            )}
                        </div>
                    </div>
                ) : (
                    null
                )}
            </div>
        )}
        </div>
    );
};

export default VideoContent;