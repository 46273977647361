import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { addPedagogy, updatePedagogy } from '../../services/CourseService';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Select from 'react-select';


export default function AddPedagogy() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [selectedModule, setSelectedModule] = useState(0);
    const [seqNo, setSeqNo] = useState(0);
    const [edit, setEdit] = useState(false);
    const [pedagogy, setPedagogy] = useState({});
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
            navigate('/login');
        }else{
            setUserDetails(userDetails);
            let moduleValue = sessionStorage.getItem('moduleValue') === null ? null : JSON.parse(sessionStorage.getItem('moduleValue'));
            if(moduleValue === null){
                navigate('/superadmin/managepedagogy');
                toast.warning("Please choose a course and module!");
            }else{
                setSelectedModule(moduleValue);
                //sessionStorage.removeItem('moduleValue');
            }
            let pedagogyEdit = sessionStorage.getItem('pedagogyEdit') === null ? null : JSON.parse(sessionStorage.getItem('pedagogyEdit'));
            if(pedagogyEdit !== null){
                setPedagogy(pedagogyEdit);
                setEdit(true);
                sessionStorage.removeItem("pedagogyEdit");
            }else{
                setEdit(false);
            }


        }
    },[]);
    

    const handleValueChange = (e) => {
        const { name, value } = e.target;
        setPedagogy({
          ...pedagogy,
          [name]: value,
        });
    };

    const handleCodeChange = (code) => {
        setPedagogy({
          ...pedagogy,
          ["pedagogy_type"]: code,
        });
    };

    const validatePedagogy = (pedagogy) => {
        if(pedagogy.pedagogy_type === undefined || pedagogy.pedagogy_type === null || pedagogy.pedagogy_type === ''
            || pedagogy.module_id === undefined || pedagogy.module_id === null || pedagogy.module_id === ''
            || pedagogy.course_id === undefined || pedagogy.course_id === null || pedagogy.course_id === ''

        ){
            return false;
        }
        return true;
    };
    const addPedagogyData = async (type) => {
        let objectToPost = Object.assign(pedagogy);
        pedagogy["module_id"] = selectedModule.module_id;
        pedagogy["course_id"] = selectedModule.course_id;
        if(objectToPost.url === undefined){
            objectToPost.url = null;
        }
        if(objectToPost.embed_code === undefined){
            objectToPost.embed_code = null;
        }
        if(objectToPost.json_data === undefined){
            objectToPost.json_data = null;
        } 
        if(objectToPost.pedagogy_seq_no === undefined){
            objectToPost.pedagogy_seq_no = 1;
        }           
        objectToPost["pedagogy_status"] = type;
        
        setLoading(true);
        if(validatePedagogy(objectToPost)){
            if(edit === false){
                let resp = await addPedagogy(userDetails.token, objectToPost);
                if(resp.error === false){
                    toast.success("Pedagogy added successfully!")
                    setPedagogy({});
                    setEdit(false);
                }else{
                    toast.error("Some error occurred while adding pedagogy!")
                }
            }else{
                objectToPost["pedagogy_id"] = objectToPost["id"];
                let resp = await updatePedagogy(userDetails.token, objectToPost);
                if(resp.error === false){
                    toast.success("Pedagogy updated successfully!")
                    setPedagogy({});
                    setEdit(false);
                    navigate('/superadmin/managepedagogy');
                }else{
                    toast.error("Some error occurred while adding pedagogy!")
                }
            }
            
        }else{
            toast.warning("Some required field is missing!");
        }
        setLoading(false);

    };

    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className=''>
                    <h3 className='text-2xl text-secondary inter-700 mt-6 mb-6 md:mb-4 lg:mb-3 lg:hidden'>{edit? "Update Pedagogy" : "Add New Pedagogy"}</h3>
                    <form>
                        <div className='pt-10 lg:px-12 px-6 pb-4 bg-[#FDF8F8] border-b border-[#D9D9D9]'>
                            <div className='back-btn flex justify-start mb-5 md:mb-8'>
                                <a onClick={() => navigate('/superadmin/managepedagogy')} className='cursor-pointer w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-7 2xl:h-8 text-white inter-500 text-xs 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a>
                            </div>
                            <div className='flex flex-wrap mb-4 md:mb-0'>
                                <div className='w-full md:w-1/5'>
                                    <p className='text-xl text-[#9E9E9E] inter-700 mb-2'>Technology</p>
                                </div>
                                <div className='w-full md:w-4/5'>
                                    <p className='text-xl text-[#9E9E9E] inter-700 mb-2'>{selectedModule.course_name}</p>
                                </div>
                            </div>
                            <div className='flex flex-wrap'>
                                <div className='w-1/2 md:w-1/5'>
                                    <p className='text-base text-[#9E9E9E] inter-400 mb-2'>Module No.</p>
                                </div>
                                <div className='w-1/2 md:w-1/5'>
                                    <p className='text-base text-[#9E9E9E] inter-400 mb-2'>{selectedModule.module_seq_no}</p>
                                </div>
                            </div>
                            <div className='flex flex-wrap'>
                                <div className='w-1/2 md:w-1/5'>
                                    <p className='text-base text-[#9E9E9E] inter-400 mb-2'>Module </p>
                                </div>
                                <div className='w-1/2 md:w-1/5'>
                                    <p className='text-base text-[#9E9E9E] inter-400 mb-1'>{selectedModule.module_code + " - " + selectedModule.module_name}</p>
                                    {/* Add module type according to selection */}
                                    {/* <p className='text-base text-[#9E9E9E] inter-400 mb-1'>Text 01</p> */}
                                </div>
                            </div>
                        </div>
                        <div className='lg:px-12 px-6 pb-6 md:pb-10'>
                            <div className='pt-10 pb-2'>
                                <p className='text-xl 2xl:text-2xl text-black inter-400 mb-6'>What do you want to add?</p>
                                <div className='flex items-center flex-wrap w-2/3'>
                                    <div className='customer-radio-sngl w-full md:w-1/2 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="preassessment" name="radio-group" value="TEXT" checked={pedagogy.pedagogy_type === "TEXT"} onChange={() => handleCodeChange("TEXT")} />
                                        <label htmlFor="preassessment" className=''>Text/Content</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="theory" name="radio-group" value="IMAGE" checked={pedagogy.pedagogy_type === "IMAGE"} onChange={() => handleCodeChange("IMAGE")} />
                                        <label htmlFor="theory">Images/animations</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="application" name="radio-group" value="VIDEO" checked={pedagogy.pedagogy_type === "VIDEO"} onChange={() => handleCodeChange("VIDEO")} />
                                        <label htmlFor="application">Video</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="component" name="radio-group" value="AUDIO" checked={pedagogy.pedagogy_type === "AUDIO"} onChange={() => handleCodeChange("AUDIO")} />
                                        <label htmlFor="component">Audio</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="taxonomy" name="radio-group" value="TEST" checked={pedagogy.pedagogy_type === "TEST"} onChange={() => handleCodeChange("TEST")} />
                                        <label htmlFor="taxonomy">Test/Quiz</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="schematics" name="radio-group" value="GAME" checked={pedagogy.pedagogy_type === "GAME"} onChange={() => handleCodeChange("GAME")} />
                                        <label htmlFor="schematics">Games</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="chain" name="radio-group" value="SIMULATION" checked={pedagogy.pedagogy_type === "SIMULATION"} onChange={() => handleCodeChange("SIMULATION")} />
                                        <label htmlFor="chain">Simulations</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="h5p" name="radio-group" value="QBOT" checked={pedagogy.pedagogy_type === "H5P"} onChange={() => handleCodeChange("H5P")} />
                                        <label htmlFor="h5p">Interactive</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='lg:px-12 px-6 pb-10'>
                            <div className='md:pt-2 pb-2'>
                                {pedagogy.pedagogy_type === 'TEXT'? (
                                    <div>
                                        <div className='form-field w-full lg:w-3/4 mb-4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Title of Text</label>
                                            <input type='text' name="title" value={pedagogy.title} onChange={handleValueChange} placeholder='Enter title here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                        <div className='form-field w-full lg:w-3/4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Text Content</label>
                                            <textarea type='text' name="text" value={pedagogy.text} onChange={handleValueChange} placeholder='Enter content here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-40 2xl:h-56 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                    </div>
                                ): (
                                    null
                                )}
                                {pedagogy.pedagogy_type === 'IMAGE'? (
                                    <div>
                                        <div className='form-field w-full lg:w-3/4 mb-4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Title of Image</label>
                                            <input type='text' name='title' value={pedagogy.title} onChange={handleValueChange} placeholder='Enter title here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                        <div className='form-field w-full lg:w-3/4 mb-4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Image URL</label>
                                            <input type='text' name='url' value={pedagogy.url} onChange={handleValueChange} placeholder='Enter URL here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                        <div className='form-field w-full lg:w-3/4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Image Content</label>
                                            <textarea type='text' name='text' value={pedagogy.text} onChange={handleValueChange} placeholder='Enter content here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-40 2xl:h-56 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                    </div>
                                ): (
                                    null
                                )}
                                {pedagogy.pedagogy_type === 'VIDEO'? (
                                    <div>
                                        <div className='form-field w-full lg:w-3/4 mb-4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Title of Video</label>
                                            <input type='text' name="title" value={pedagogy.title} onChange={handleValueChange} placeholder='Enter title here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                        <div className='form-field w-full lg:w-3/4 mb-4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Video URL</label>
                                            <input type='text' name="url" value={pedagogy.url} onChange={handleValueChange} placeholder='Enter URL here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                        <div className='form-field w-full lg:w-3/4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Video Content</label>
                                            <textarea type='text' name="text" value={pedagogy.text} onChange={handleValueChange} placeholder='Enter content here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-40 2xl:h-56 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                    </div>
                                ): (
                                    null
                                )}
                                {pedagogy.pedagogy_type === 'AUDIO'? (
                                    <div>
                                        <div className='form-field w-full lg:w-3/4 mb-4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Title of Audio</label>
                                            <input type='text' name="title" value={pedagogy.title} onChange={handleValueChange} placeholder='Enter title here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                        <div className='form-field w-full lg:w-3/4 mb-4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Audio URL</label>
                                            <input type='text' name="url" value={pedagogy.url} onChange={handleValueChange} placeholder='Enter URL here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                        <div className='form-field w-full lg:w-3/4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Audio Content</label>
                                            <textarea type='text' name="text" value={pedagogy.text} onChange={handleValueChange} placeholder='Enter content here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-40 2xl:h-56 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                    </div>
                                ): (
                                    null
                                )}
                                {pedagogy.pedagogy_type === 'SIMULATION'? (
                                    <div>
                                        <div className='form-field w-full lg:w-3/4 mb-4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Title of Simulation</label>
                                            <input type='text' name="title" value={pedagogy.title} onChange={handleValueChange} placeholder='Enter title here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                        <div className='form-field w-full lg:w-3/4 mb-4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Simulation URL</label>
                                            <input type='text' name="url" value={pedagogy.url} onChange={handleValueChange} placeholder='Enter URL here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                        <div className='form-field w-full lg:w-3/4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Simulation Content</label>
                                            <textarea type='text' name="text" value={pedagogy.text} onChange={handleValueChange} placeholder='Enter content here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-40 2xl:h-56 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                    </div>
                                ): (
                                    null
                                )}
                                {pedagogy.pedagogy_type === 'GAME'? (
                                    <div>
                                        <div className='form-field w-full lg:w-3/4 mb-4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Title of Game</label>
                                            <input type='text' name="title" value={pedagogy.title} onChange={handleValueChange} placeholder='Enter title here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                        <div className='form-field w-full lg:w-3/4 mb-4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Game URL</label>
                                            <input type='text' name="url" value={pedagogy.url} onChange={handleValueChange} placeholder='Enter URL here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                        <div className='form-field w-full lg:w-3/4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Game Content</label>
                                            <textarea type='text' name="text" value={pedagogy.text} onChange={handleValueChange} placeholder='Enter content here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-40 2xl:h-56 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                    </div>
                                ): (
                                    null
                                )}
                                {pedagogy.pedagogy_type === 'TEST'? (
                                    <div>
                                        <div className='form-field w-full lg:w-3/4 mb-4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Title of Test/Quiz</label>
                                            <input type='text' name="title" value={pedagogy.title} onChange={handleValueChange} placeholder='Enter title here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                        <div className='form-field w-full lg:w-3/4 mb-4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Test URL</label>
                                            <input type='text' name="url" value={pedagogy.url} onChange={handleValueChange} placeholder='Enter URL here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                        <div className='form-field w-full lg:w-3/4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Test Content</label>
                                            <textarea type='text' name="text" value={pedagogy.text} onChange={handleValueChange} placeholder='Enter content here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-40 2xl:h-56 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                    </div>
                                ): (
                                    null
                                )}
                                {pedagogy.pedagogy_type === 'H5P'? (
                                    <div>
                                        <div className='form-field w-full lg:w-3/4 mb-4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Title</label>
                                            <input type='text' name="title" value={pedagogy.title} onChange={handleValueChange} placeholder='Enter title here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                        <div className='form-field w-full lg:w-3/4 mb-4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>URL</label>
                                            <input type='text' name="url" value={pedagogy.url} onChange={handleValueChange} placeholder='Enter URL here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                        <div className='form-field w-full lg:w-3/4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Content</label>
                                            <textarea type='text' name="text" value={pedagogy.text} onChange={handleValueChange} placeholder='Enter content here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-40 2xl:h-56 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                    </div>
                                ): (
                                    null
                                )}
                                {/* {pedagogy.pedagogy_type === 'ASSESSMENT'? (
                                    <div>
                                        <div className='form-field w-full lg:w-3/4 mb-4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Title of Quiz</label>
                                            <input type='text' name="title-text" placeholder='Enter title here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                        <div className='form-field w-full lg:w-3/4 mb-4'>
                                            <label className='text-base md:text-lg text-[#6F7482] inter-400'>Quiz URL</label>
                                            <input type='text' name="title-text" placeholder='Enter URL here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        </div>
                                    </div>
                                ): (
                                    null
                                )} */}
                                {pedagogy.pedagogy_type !== undefined ? (
                                    <div className='flex flex-wrap justify-between mt-12 lg:w-3/4'>
                                        <div className='w-full md:w-2/5'>
                                            <button type="button" onClick={() => addPedagogyData("DRAFT")} className='w-full bg-secondary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow mb-5 md:mb-10'>+ Save in Draft</button>
                                        </div>
                                        <div className='w-full md:w-2/5'>
                                            <button type="button" onClick={() => addPedagogyData("PUBLISHED")} className='w-full bg-tertiary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow mb-5 md:mb-10'>+ Publish</button>
                                        </div>
                                        {/* <div className='w-full md:w-2/5'>
                                            <button type="button" className='w-full bg-[#E76457] hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow mb-5 md:mb-10'>Preview</button>
                                        </div> */}
                                    </div>
                                ) : (
                                    null
                                )}
                                {/* Show only when quiz is selected start */}
                                {/* <div className='lg:w-3/4'>
                                    <p className='text-xl text-[#2D2D2D] inter-400mb-5 mb-5 md:mb-10'>OR</p>
                                    <div className='w-full'>
                                        <button type="button" className='w-full bg-primary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Create Quiz Here</button>
                                    </div>
                                </div> */}
                                {/* Show only when quiz is selected start */}
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
}