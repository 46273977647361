import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { createAssessment, updateAssessment } from '../../services/CourseService';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Select from 'react-select';


export default function AddAssessment() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [selectedModule, setSelectedModule] = useState(0);
    const [seqNo, setSeqNo] = useState(0);
    const [edit, setEdit] = useState(false);
    const [assessment, setAssessment] = useState({});
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
            navigate('/login');
        }else{
            setUserDetails(userDetails);
            let moduleValue = sessionStorage.getItem('moduleValue') === null ? null : JSON.parse(sessionStorage.getItem('moduleValue'));
            if(moduleValue === null){
                navigate('/superadmin/manageassessment');
                toast.warning("Please choose a course and module!");
            }else{
                setSelectedModule(moduleValue);
                // sessionStorage.removeItem('moduleValue');
            }
            let assessmentEdit = sessionStorage.getItem('assessmentEdit') === null ? null : JSON.parse(sessionStorage.getItem('assessmentEdit'));
            if(assessmentEdit !== null){
                setAssessment(assessmentEdit);
                setEdit(true);
                sessionStorage.removeItem("assessmentEdit");
            }else{
                setEdit(false);
            }
        }
    },[]);
    

    const handleValueChange = (e) => {
        const { name, value } = e.target;
        setAssessment({
          ...assessment,
          [name]: value,
        });
    };

    const handleCodeChange = (code) => {
        setAssessment({
          ...assessment,
          ["type"]: code,
        });
    };
    
    const handleCorrectOptionChange = (correct_option_no) => {
        setAssessment({
          ...assessment,
          ["correct_option_no"]: correct_option_no,
        });
    };

    const validateAssessment = (assessment) => {
        if(assessment.type === undefined || assessment.type === null || assessment.type === ''
            || assessment.module_id === undefined || assessment.module_id === null || assessment.module_id === ''
            || assessment.primary_text === undefined || assessment.primary_text === null || assessment.primary_text === ''
            || assessment.correct_option_no === undefined || assessment.correct_option_no === null || assessment.correct_option_no === ''
            || assessment.course_id === undefined || assessment.course_id === null || assessment.course_id === ''

        ){
            return false;
        }
        return true;
    };
    const addAssessmentData = async (status) => {
        let objectToPost = Object.assign(assessment);
        objectToPost["module_id"] = selectedModule.module_id;
        objectToPost["course_id"] = selectedModule.course_id;
        if(objectToPost.option3 === undefined){
            objectToPost.option3 = null;
        }
        if(objectToPost.option4 === undefined){
            objectToPost.option4 = null;
        }
        if(objectToPost.option5 === undefined){
            objectToPost.option5 = null;
        } 
        if(objectToPost.assessment_seq_no === undefined){
            objectToPost.assessment_seq_no = 1;
        }           
        objectToPost["assessment_status"] = status;
        
        if(objectToPost.type === 'YES_NO'){
            objectToPost['option1'] = 'Yes';
            objectToPost['option2'] = 'No';

        }
        if(objectToPost.type === 'TRUE_FALSE'){
            objectToPost['option1'] = 'True';
            objectToPost['option2'] = 'False';

        }
        setLoading(true);
        if(validateAssessment(objectToPost)){
            if(edit === false){
                let resp = await createAssessment(userDetails.token, objectToPost);
                if(resp.error === false){
                    toast.success("Assessment added successfully!")
                    setAssessment({});
                    setEdit(false);
                }else{
                    toast.error("Some error occurred while adding Assessment!")
                }
            }else{
                objectToPost["assessment_id"] = objectToPost["id"];
                let resp = await updateAssessment(userDetails.token, objectToPost);
                if(resp.error === false){
                    toast.success("Assessment updated successfully!")
                    setAssessment({});
                    setEdit(false);
                    navigate('/superadmin/manageassessment');
                }else{
                    toast.error("Some error occurred while adding Assessment!")
                }
            }
            
        }else{
            toast.warning("Some required field is missing!");
        }
        setLoading(false);

    };

    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className=''>
                    <h3 className='text-2xl text-secondary inter-700 px-6 mt-6 mb-6 lg:hidden'>{edit? "Update Question" : "Add New Question"}</h3>
                    <form>
                        <div className='pt-10 lg:px-12 px-6 pb-4 bg-[#FDF8F8] border-b border-[#D9D9D9]'>
                            <div className='back-btn flex justify-start mb-5 md:mb-8'>
                                <a onClick={() => navigate('/superadmin/manageassessment')} className='cursor-pointer w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-7 2xl:h-8 text-white inter-500 text-xs 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a>
                            </div>
                            <div className='flex flex-wrap mb-4 md:mb-0'>
                                <div className='w-full md:w-1/5'>
                                    <p className='text-xl text-[#9E9E9E] inter-700 mb-2'>Technology</p>
                                </div>
                                <div className='w-full md:w-4/5'>
                                    <p className='text-xl text-[#9E9E9E] inter-700 mb-2'>{selectedModule.course_name}</p>
                                </div>
                            </div>
                            <div className='flex flex-wrap'>
                                <div className='w-1/2 md:w-1/5'>
                                    <p className='text-base text-[#9E9E9E] inter-400 mb-2'>Module No.</p>
                                </div>
                                <div className='w-1/2 md:w-1/5'>
                                    <p className='text-base text-[#9E9E9E] inter-400 mb-2'>{selectedModule.module_seq_no}</p>
                                </div>
                            </div>
                            <div className='flex flex-wrap'>
                                <div className='w-1/2 md:w-1/5'>
                                    <p className='text-base text-[#9E9E9E] inter-400 mb-2'>Module </p>
                                </div>
                                <div className='w-1/2 md:w-1/5'>
                                    <p className='text-base text-[#9E9E9E] inter-400 mb-1'>{selectedModule.module_code + " - " + selectedModule.module_name}</p>
                                </div>
                            </div>
                        </div>
                        <div className='lg:px-12 px-6 pb-10'>
                            <div className='pt-10 pb-2'>
                                <p className='text-xl 2xl:text-2xl text-black inter-400 mb-6'>What do you want to add?</p>
                                <div className='flex items-center flex-wrap md:w-2/3'>
                                    <div className='customer-radio-sngl w-full md:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="mcq" name="radio-group" value="MCQ" checked={assessment.type === "MCQ"} onChange={() => handleCodeChange("MCQ")} />
                                        <label htmlFor="mcq" className=''>Multiple Choice Questions</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="yes_no" name="radio-group" value="YES_NO" checked={assessment.type === "YES_NO"} onChange={() => handleCodeChange("YES_NO")} />
                                        <label htmlFor="yes_no">Yes/No</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="true_false" name="radio-group" value="TRUE_FALSE" checked={assessment.type === "TRUE_FALSE"} onChange={() => handleCodeChange("TRUE_FALSE")} />
                                        <label htmlFor="true_false">True/False</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='lg:px-12 px-6 pb-10'>
                            <div className=''>
                                {assessment.type === 'YES_NO'? (
                                    <>
                                        <h3 className='text-xl md:text-2xl inter-400 text-secondary mb-2'>Set up Question</h3>
                                        <div className='w-full md:w-4/5 lg:w-1/2'>
                                            <div className='form-field mb-6 md:mb-16'>
                                                <input type='text' name="primary_text" value={assessment.primary_text} onChange={handleValueChange} placeholder='Type the question here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                            </div>
                                            <p className='text-lg md:text-xl inter-400 text-secondary mb-2'>Select the correct answer</p>
                                            <div className='flex items-center flex-wrap mt-4 md:mt-6'>
                                                <div className='customer-radio-sngl w-full md:w-1/2 mb-5 md:mb-8'>
                                                    <input type="radio" id="yes" name="yes-no-radio-group" value={1} checked={assessment.correct_option_no === 1} onChange={() => handleCorrectOptionChange(1)} />
                                                    <label htmlFor="yes">Yes</label>
                                                </div>
                                                <div className='customer-radio-sngl w-full md:w-1/2 mb-5 md:mb-8'>
                                                    <input type="radio" id="no" name="yes-no-radio-group" value={2} checked={assessment.correct_option_no === 2} onChange={() => handleCorrectOptionChange(2)} />
                                                    <label htmlFor="no">No</label>
                                                </div>
                                            </div>
                                        </div>
                                   </>
                                ): (
                                    null
                                )}
                                {assessment.type === 'TRUE_FALSE'? (
                                    <>
                                        <div className='w-full md:w-4/5 lg:w-1/2'>
                                            <div className='form-field mb-6 md:mb-16'>
                                                <input type='text' name="primary_text" value={assessment.primary_text} onChange={handleValueChange} placeholder='Type the question here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                            </div>
                                            <p className='text-lg md:text-xl inter-400 text-secondary mb-2'>Select the correct answer</p>
                                            <div className='flex items-center flex-wrap mt-4 md:mt-6'>
                                                <div className='customer-radio-sngl w-full md:w-1/2 mb-5 md:mb-8'>
                                                    <input type="radio" id="true" name="true-false-radio-group" value={1} checked={assessment.correct_option_no === 1} onChange={() => handleCorrectOptionChange(1)} />
                                                    <label htmlFor="true">True</label>
                                                </div>
                                                <div className='customer-radio-sngl w-full md:w-1/2 mb-5 md:mb-8'>
                                                    <input type="radio" id="false" name="true-false-radio-group" value={2} checked={assessment.correct_option_no === 2} onChange={() => handleCorrectOptionChange(2)} />
                                                    <label htmlFor="false">False</label>
                                                </div>
                                            </div>
                                        </div>
                                   </>
                                ): (
                                    null
                                )}
                                
                                {assessment.type === 'MCQ'? (
                                    <>
                                        <div className='w-full md:w-4/5 lg:w-1/2'>
                                            <div className='form-field mb-6 md:mb-16'>
                                                <input type='text' name="primary_text" value={assessment.primary_text} onChange={handleValueChange} placeholder='Type the question here...' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                            </div>
                                            <p className='text-lg md:text-xl inter-400 text-secondary mb-2'>Enter the options</p>
                                            <div className='flex items-center flex-wrap mt-4 md:mt-6'>
                                                <div className='quiz-ans-sngl flex items-center w-full mb-5 md:mb-8'>
                                                    <div className='quiz-answer-field flex-1 pl-3'>
                                                        <input type='text' name="option1" value={assessment.option1} onChange={handleValueChange} placeholder='Answer Option 01' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                                    </div>
                                                </div>
                                                <div className='quiz-ans-sngl flex items-center w-full mb-5 md:mb-8'>
                                                    <div className='quiz-answer-field flex-1 pl-3'>
                                                        <input type='text' name="option2" value={assessment.option2} onChange={handleValueChange} placeholder='Answer Option 02' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                                    </div>
                                                </div>
                                                <div className='quiz-ans-sngl flex items-center w-full mb-5 md:mb-8'>
                                                    <div className='quiz-answer-field flex-1 pl-3'>
                                                        <input type='text' name="option3" value={assessment.option3} onChange={handleValueChange} placeholder='Answer Option 03' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                                    </div>
                                                </div>
                                                <div className='quiz-ans-sngl flex items-center w-full mb-5 md:mb-8'>
                                                    <div className='quiz-answer-field flex-1 pl-3'>
                                                        <input type='text' name="option4" value={assessment.option4} onChange={handleValueChange} placeholder='Answer Option 04' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className='text-lg md:text-xl inter-400 text-secondary mb-2'>Select the correct answer</p>
                                            <div className='flex items-center flex-wrap mt-4 md:mt-6'>
                                                <div className='customer-radio-sngl w-1/2 md:w-1/4 mb-5 md:mb-8'>
                                                    <input type="radio" id="option1" name="mcq1-radio-group" value={1} checked={assessment.correct_option_no === 1} onChange={() => handleCorrectOptionChange(1)} />
                                                    <label htmlFor="option1">Option 1</label>
                                                </div>
                                                <div className='customer-radio-sngl w-1/2 md:w-1/4 mb-5 md:mb-8'>
                                                    <input type="radio" id="option2" name="mcq1-radio-group" value={2} checked={assessment.correct_option_no === 2} onChange={() => handleCorrectOptionChange(2)} />
                                                    <label htmlFor="option2">Option 2</label>
                                                </div>
                                                <div className='customer-radio-sngl w-1/2 md:w-1/4 mb-5 md:mb-8'>
                                                    <input type="radio" id="option3" name="mcq1-radio-group" value={3} checked={assessment.correct_option_no === 3} onChange={() => handleCorrectOptionChange(3)} />
                                                    <label htmlFor="option3">Option 3</label>
                                                </div>
                                                <div className='customer-radio-sngl w-1/2 md:w-1/4 mb-5 md:mb-8'>
                                                    <input type="radio" id="option4" name="mcq1-radio-group" value={4} checked={assessment.correct_option_no === 4} onChange={() => handleCorrectOptionChange(4)} />
                                                    <label htmlFor="option4">Option 4</label>
                                                </div>
                                            </div>
                                        </div>
                                   </>
                                ): (
                                    null
                                )}

                                {assessment.type !== undefined ? (
                                    <div className='flex flex-wrap justify-between mt-12 lg:w-3/4'>
                                        <div className='w-full md:w-2/5'>
                                            <button type="button" onClick={() => addAssessmentData("DRAFT")} className='w-full bg-secondary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow mb-5 md:mb-10'>+ Save in Draft</button>
                                        </div>
                                        <div className='w-full md:w-2/5'>
                                            <button type="button" onClick={() => addAssessmentData("PUBLISHED")} className='w-full bg-tertiary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow mb-5 md:mb-10'>+ Publish</button>
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
}