import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { pageSizes } from '../../utility/constants';
import { formatSixDigitNumber, capitalizeFirstLetter } from '../../services/CommonService';
import { getAllModules, createModule, updateModule } from '../../services/CourseService';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Select from 'react-select';


export default function AddModule() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [modules, setModules] = useState([]);
    const [module, setModule] = useState({});
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(0);
    const [seqNo, setSeqNo] = useState(0);
    const [edit, setEdit] = useState(false);
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
            navigate('/login');
        }else{
            setUserDetails(userDetails);
            let moduleToEdit = sessionStorage.getItem('moduleToEdit') === null ? null : JSON.parse(sessionStorage.getItem('moduleToEdit'));
            if(moduleToEdit !== null){
                setModule(moduleToEdit);
                sessionStorage.removeItem('moduleToEdit');
                setSeqNo(moduleToEdit.module_seq_no);
                setSelectedCourse(moduleToEdit.course_id);
                setEdit(true);
                getAllModulesData(userDetails.token, true, moduleToEdit.course_id);

            }else{
                getAllModulesData(userDetails.token, false, 0);

            }
        }
    },[]);
    const getAllModulesData = async (token, edit, course_id) => {
        setLoading(true);
        const modulesData = await getAllModules(token);
        if(modulesData.error === false){
            let allModules = modulesData.data;
            let courseObj = {};
            for(let i=0;i<allModules.length; i++){
                if(courseObj[allModules[i].course_id] === undefined){
                    courseObj[allModules[i].course_id] = {label: allModules[i].course_name, value: allModules[i].course_id};

                }
            }
            setCourses(Object.values(courseObj));
            setModules(allModules);
            if(course_id > 0 && edit === false){
                handleCourseValueChange(course_id, allModules);
            }
        }
        setLoading(false);
    };

    const handleCourseValueChange = async (value, allModules) => {
        setSelectedCourse(value);
        let num_of_modules = 0;
        for(let i=0; i<allModules.length; i++){
            if(allModules[i].course_id === value){
                num_of_modules = num_of_modules + 1;
            }
        }
        setSeqNo(num_of_modules + 1);
    };

    const handleValueChange = (e) => {
        const { name, value } = e.target;
        setModule({
          ...module,
          [name]: value,
        });
    };

    const handleCodeChange = (code) => {
        setModule({
          ...module,
          ["module_code"]: code,
        });
    };

    const validateModule = (module) => {
        if(module.module_code === undefined || module.module_code === null || module.module_code === ''
            || module.module_name === undefined || module.module_name === null || module.module_name === ''
        ){
            return false;
        }
        return true;
    };

    const addModule = async () => {
        let objectToPost = {};
        objectToPost["module_seq_no"] = seqNo;
        objectToPost["module_desc"] = module["module_name"];
        objectToPost["module_type"] = module["module_code"] === 'PR' || module["module_code"] === 'PO' ? "ASSESSMENT" : "THEORY";
        objectToPost["course_id"] = selectedCourse;
        objectToPost["module_code"] = module["module_code"]
        objectToPost["module_name"] = module["module_name"]
        if(validateModule(objectToPost)){
            setLoading(true);
            let resp = await createModule(userDetails.token, objectToPost);
            if(resp.error === false){
                await getAllModulesData(userDetails.token, edit, selectedCourse);
                toast.success("Module added successfully!");
            }else{
                toast.error("Some error occurred while adding module!");
            }
            setLoading(false);
        }else{
            toast.warn("All fields are mandatory! Please fill all fields.")
        }

    };

    const updateModuleData = async () => {
        let objectToPost = {};
        objectToPost["module_seq_no"] = seqNo;
        objectToPost["module_desc"] = module["module_name"];
        objectToPost["module_type"] = module["module_code"] === 'PR' || module["module_code"] === 'PO' ? "ASSESSMENT" : "THEORY";
        objectToPost["course_id"] = selectedCourse;
        objectToPost["module_code"] = module["module_code"]
        objectToPost["module_name"] = module["module_name"]
        objectToPost["module_id"] = module["id"]
        if(validateModule(objectToPost)){
            setLoading(true);
            let resp = await updateModule(userDetails.token, objectToPost);
            if(resp.error === false){
                toast.success("Module updated successfully!");
            }else{
                toast.error("Some error occurred while updating module!");
            }
            setLoading(false);
        }else{
            toast.warn("All fields are mandatory! Please fill all fields.")
        }
        
    };


    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className=''>
                    <h3 className='text-2xl text-secondary inter-700 lg:mb-6 mb-0 lg:hidden pt-6 lg:pt-0 px-6'>{edit ? "Update Module" : "Add New Module to a Technology"}</h3>
                    <form>
                        <div className='lg:py-10 py-6 lg:px-12 px-6 border-b border-[#D9D9D9]'>
                            <div className='back-btn flex justify-start mb-5 md:mb-8'>
                                <a onClick={() => navigate('/superadmin/managemodules')} className='cursor-pointer w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-7 2xl:h-8 text-white inter-500 text-xs 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a>
                            </div>
                            <div className='form-field w-full md:w-1/2'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-[#6F7482]'>Select Technology</label>
                                <Select className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md'
                                    options={courses}
                                    value={courses.find(option => option.value === selectedCourse)}
                                    onChange={(values) => handleCourseValueChange(values.value, modules)}
                                />
                            </div>
                        </div>
                        <div className='lg:py-10 py-6 lg:px-12 px-6 border-b border-[#D9D9D9]'>
                            <div className='flex flex-wrap lg:mb-6 mb-2'>
                                <div className='w-10/12 md:w-max'>
                                    <p className='text-base text-[#9E9E9E] inter-400 mb-2'>{edit ? "Module Serial Number:" : "New Module Serial Number:"}</p>
                                </div>
                                <div className='w-2/12 md:w-max ml-0 md:ml-4'>
                                    <p className='text-base text-[#9E9E9E] inter-400 mb-2 text-right md:text-left'>{seqNo}</p>
                                </div>
                            </div>
                            <div className='form-field w-full md:w-1/2'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-[#6F7482]'>Give a name for this Module</label>
                                <input type='text' name="module_name" value={module.module_name} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                        </div>
                        <div className='lg:px-12 px-6 pb-10'>
                            <div className='pt-6 lg:pt-10 pb-2 border-b border-[#D9D9D9]'>
                                <p className='text-xl 2xl:text-2xl text-[#BF2D88] inter-400 mb-6'>Select the type of Module for this Module</p>
                                <div className='flex items-center flex-wrap w-2/3'>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="preassessment" name="radio-group" value='PR' checked={module["module_code"] === "PR"} onChange={() => handleCodeChange("PR")} />
                                        <label htmlFor="preassessment" className=''>Pre-assessment</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="theory" name="radio-group" value='TH' checked={module["module_code"] === "TH"} onChange={() => handleCodeChange("TH")} />
                                        <label htmlFor="theory">Theory</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="application" name="radio-group" value='AP' checked={module["module_code"] === "AP"} onChange={() => handleCodeChange("AP")} />
                                        <label htmlFor="application">Application</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="component" name="radio-group" value='CO' checked={module["module_code"] === "CO"} onChange={() => handleCodeChange("CO")} />
                                        <label htmlFor="component">Components</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="taxonomy" name="radio-group" value='TA' checked={module["module_code"] === "TA"} onChange={() => handleCodeChange("TA")} />
                                        <label htmlFor="taxonomy">Taxonomy</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="schematics" name="radio-group" value='SCH' checked={module["module_code"] === "SCH"} onChange={() => handleCodeChange("SCH")} />
                                        <label htmlFor="schematics">Schematics</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="chain" name="radio-group" value='SC' checked={module["module_code"] === "SC"} onChange={() => handleCodeChange("SC")} />
                                        <label htmlFor="chain">Supply Chain</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="visual" name="radio-group" value='V' checked={module["module_code"] === "V"} onChange={() => handleCodeChange("V")} />
                                        <label htmlFor="visual">Visualization</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="problem" name="radio-group" value='PS' checked={module["module_code"] === "PS"} onChange={() => handleCodeChange("PS")} />
                                        <label htmlFor="problem">Problem-solving</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="game" name="radio-group" value='G' checked={module["module_code"] === "G"} onChange={() => handleCodeChange("G")} />
                                        <label htmlFor="game">Game</label>
                                    </div>
                                    <div className='customer-radio-sngl w-full md:w-1/2 lg:w-1/3 md:pr-3 mb-5 md:mb-8'>
                                        <input type="radio" id="postassessment" name="radio-group" value='PO' checked={module["module_code"] === "PO"} onChange={() => handleCodeChange("PO")} />
                                        <label htmlFor="postassessment">Post-assessment</label>
                                    </div>
                                </div>
                            </div>
                            <div className='flex items-center justify-between flex-wrap pt-8'>
                                {edit ? (
                                    <button type="button" onClick={updateModuleData} className='w-full md:w-auto bg-secondary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Update Module</button>
                                ) : (
                                    <button type="button" onClick={addModule} className='w-full md:w-auto bg-secondary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>+ Add Module</button>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
}