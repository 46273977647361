import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import {getAllLearners, deleteLearner } from '../../services/SuperAdminLearnerService';
import { getAllCustomers, getCustomerById } from '../../services/CustomerService';
import { pageSizes } from '../../utility/constants';
import {formatSixDigitNumber, capitalizeFirstLetter} from '../../services/CommonService';
import { toast } from 'react-toastify';
import { userDefaultPicture } from '../../utility/constants';
import Swal from 'sweetalert2';
import Select from 'react-select';

export default function SuperAdminManageLearners() {
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
            setUserDetails(userDetails);
            getAllData(userDetails.token);
        }
    },[]);

    const getAllData = async (token) => {
        await getAllCustomerData(token);
    };

    const getAllCustomerData = async (token) => {
        setLoading(true);
        const customerData = await getAllCustomers(token);
        if(customerData.error){
            setCustomers([]);
        }else{
            let allCustomers = [];
            for(let i=0; i< customerData.data.length; i++){
                let customer = Object.assign(customerData.data[i]);
                if(customer.id !== 0){
                    customer['label'] = customer['name'];
                    customer['value'] = customer['id'];
                    allCustomers.push(customer);
                }
                
            }
            setCustomers(allCustomers);
            if(selectedCustomer === null && allCustomers.length > 0){
                setSelectedCustomer(allCustomers[0].value);
            }
            if(allCustomers.length > 0){
                await getAllLearnersData(token, allCustomers[0].value);
            }

        }
        setRefreshKey(1);
        setLoading(false);
    };

    const getAllLearnersData = async (token, customer_id) => {
        const usersData = await getAllLearners(token, customer_id);
        if(usersData.error){
            setUsers([]);
        }else{
            let learners = [];
            for(let i=0; i< usersData.data.length; i++){
                let learner = Object.assign(usersData.data[i]);
                if(usersData.data[i].role === 'LEARNER' && usersData.data[i].status !== 'CANCELLED'){
                    learner['uid'] = "UID" + formatSixDigitNumber(learner.id);
                    learner['name'] = (learner.first_name !== undefined && learner.first_name !== null ? learner.first_name : "")
                    + " " + (learner.middle_name !== undefined && learner.middle_name !== null ? learner.middle_name: "")
                    + " " + (learner.last_name !== undefined && learner.last_name !== null ? learner.last_name: "");
                    learner['cohort'] = (learner.cohort_desc !== undefined && learner.cohort_desc !== null ? learner.cohort_desc : "") + " - " + (learner.org_name !== undefined && learner.org_name !== null ? learner.org_name : "");
                    learner['statusInLowerCase'] = capitalizeFirstLetter(learner.status);
                    learners.push(learner);
                }
            }
            setUsers(learners);
        }
    };

    const handleCustomerChange = async (value) => {
        setSelectedCustomer(value);
        setLoading(true);
        await getAllLearnersData(userDetails.token, value);
        setLoading(false);
    };

    const inviteNewLearner = async () => {
        if(selectedCustomer === null){
            toast.warn("Please choose a customer");
        }else{
            for(let i = 0; i<customers.length; i++){
                if(customers[i].value === selectedCustomer){
                    sessionStorage.setItem("selectedCustomerForInviteLearner", JSON.stringify(customers[i]));
                    navigate('/superadmin/invitenewlearner');
                    break;
                }
            }
        }
    };

    const createNewLearner = async () => {
        if(selectedCustomer === null){
            toast.warn("Please choose a customer");
        }else{
            for(let i = 0; i<customers.length; i++){
                if(customers[i].value === selectedCustomer){
                    sessionStorage.setItem("selectedCustomer", JSON.stringify(customers[i]));
                    navigate('/superadmin/createlearner');
                    break;
                }
            }
        }
    };

    const updateLearner = async (learner) => {

        if(learner.customer_id === null){
            toast.warn("Please choose a customer!");
        }else{

            const userDtl = getUserDetails();
            let customerDetail = {};
            let resp = await getCustomerById(userDtl.token, learner.customer_id);
            if(resp.error === false){
                customerDetail = resp.data;
                sessionStorage.setItem("selectedCustomer", JSON.stringify(customerDetail));
                sessionStorage.setItem("selectedLearner", JSON.stringify(learner));
                navigate('/superadmin/createlearner');
            }
            
        }
    };

    const [colDefs, setColDefs] = useState([
        {headerName: "Unique ID", field: "uid"},
        {headerName: 'Profile',
            cellRenderer: params => {
                const onClick = () => {
                    sessionStorage.setItem("userView", JSON.stringify(params.data));
                    sessionStorage.setItem("userViewType", "learner");
                    navigate('/superadmin/userprofile');
                    
                };
                return (
                    <>
                        <div className='flex items-center justify-center h-full'>
                            <div className='tabl-prof-img w-8 h-8 rounded-lg bg-white flex items-center justify-center'>
                                <img onClick={onClick} className='cursor-pointer w-6 h-6 rounded-image' src={params.data.image_link !== null && params.data.image_link !== undefined ? params.data.image_link : 'images/profile_image_default.avif'} alt='user'/>
                            </div>
                        </div>
                    </>
                );
            },
            
        },
        {headerName: "User", field: "name"},
        {headerName: "Email ID", field: "email" },
        {headerName: "Cohort", field: "cohort" },
        {headerName: 'Status',
            valueGetter: (params) => {
                return params.data.status;
            },
            cellRenderer: params => {
                const onClick = () => {
                    updateLearner(params.data);
                };
                return (
                    <>
                        <div onClick={onClick} className='cursor-pointer flex items-center h-full'>
                            {params.data.status === 'ACTIVE' ? (
                                <div className='active-status text-xs inter-500 leading-none bg-[#EBF9F1] text-[#1F9254] py-2 px-2 rounded-3xl'>Active</div>
                            ) : (
                                null
                            )}
                            {params.data.status === 'SUSPENDED' ? (
                                <div className='suspend-status text-xs inter-500 leading-none bg-[#FEF2E5] text-[#CD6200] py-2 px-2 rounded-3xl'>Suspended</div>
                            ) : (
                                null
                            )}
                            {params.data.status === 'CANCELLED' ? (
                                <div className='cancel-status text-xs inter-500 leading-none bg-[#FBE7E8] text-[#A30D11] py-2 px-2 rounded-3xl'>Cancelled</div>
                            ) : (
                                null
                            )}
                        </div>
                    </>
                );
            },
            sortable: true,
            filter: true
        },
        {headerName: 'Actions',
            cellRenderer: params => {
                const onClick = () => {
                    updateLearner(params.data);
                };
                const onClickDelete = async () => {
                    Swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        cancelButtonText: 'No, cancel!',
                        reverseButtons: true
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            setLoading(true);
                            const userDtl = getUserDetails();
                            const resp = await deleteLearner(userDtl.token, params.data.id);
                            if(!resp.error){
                                toast.success("Learner Deleted Successfully!");
                                await getAllLearnersData(userDtl.token, params.data.customer_id);
                            }else{
                                toast.error("Some error occurred!");
                            }
                            setLoading(false);
                        } 
                    });
                };

                return (
                    <>
                        <div className='relative course-navigate-btns flex items-center justify-between h-full'>
                            <a onClick={onClick} className='cursor-pointer flex items-center justify-center transition duration-300'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path d="M11.083 4H4.08301C3.55257 4 3.04387 4.21071 2.66879 4.58579C2.29372 4.96086 2.08301 5.46957 2.08301 6V20C2.08301 20.5304 2.29372 21.0391 2.66879 21.4142C3.04387 21.7893 3.55257 22 4.08301 22H18.083C18.6134 22 19.1221 21.7893 19.4972 21.4142C19.8723 21.0391 20.083 20.5304 20.083 20V13" stroke="#624DE3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M18.583 2.49998C18.9808 2.10216 19.5204 1.87866 20.083 1.87866C20.6456 1.87866 21.1852 2.10216 21.583 2.49998C21.9808 2.89781 22.2043 3.43737 22.2043 3.99998C22.2043 4.56259 21.9808 5.10216 21.583 5.49998L12.083 15L8.08301 16L9.08301 12L18.583 2.49998Z" stroke="#624DE3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </a>
                            <a onClick={onClickDelete} className='cursor-pointer flex items-center justify-center transition duration-300'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path d="M3.08301 6H5.08301H21.083" stroke="#A30D11" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8.08301 6V4C8.08301 3.46957 8.29372 2.96086 8.66879 2.58579C9.04387 2.21071 9.55257 2 10.083 2H14.083C14.6134 2 15.1221 2.21071 15.4972 2.58579C15.8723 2.96086 16.083 3.46957 16.083 4V6M19.083 6V20C19.083 20.5304 18.8723 21.0391 18.4972 21.4142C18.1221 21.7893 17.6134 22 17.083 22H7.08301C6.55257 22 6.04387 21.7893 5.66879 21.4142C5.29372 21.0391 5.08301 20.5304 5.08301 20V6H19.083Z" stroke="#A30D11" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M10.083 11V17" stroke="#A30D11" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M14.083 11V17" stroke="#A30D11" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </a>
                        </div>
                    </>
                );
            },
            
        }
    ]);
    const defaultColDef = useMemo(() => {
        return {
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            flex: 1,
            minWidth: 200,
            maxWidth: 300,
        };
    }, []);
    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className='py-10'>
                    <h3 className='text-2xl text-secondary inter-700 lg:hidden mb-6 md:mb-4 px-6'>Manage Learner</h3>
                    <div className='flex flex-wrap items-center justify-between lg:mx-12 lg:px-0 px-6 pb-5 mb-6 border-b-2 border-[#D0D0D0]'>
                        <button onClick={() => inviteNewLearner()} type="button" className='bg-tertiary hover:bg-black lg:order-1 mb-0 md:mb-0 flex items-center justify-center px-10 h-0 2xl:h-12 text-white inter-600 text-base 2xl:text-xl rounded-3xl lg:rounded-full transition duration-300 button-shadow w-full md:w-auto opacity-0 invisible'>+ Invite New Learner</button>
                        <Select className='inter-400 bg-[#fbfbfb] text-black md:order-3 lg:order-2 w-full md:full lg:w-2/5 xl:w-1/2 mb-4 md:mb-0 md:mt-5 lg:mt-0 text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md'
                            options={customers}
                            value={customers.find(option => option.value === selectedCustomer)}
                            onChange={(values) => handleCustomerChange(values.value)}
                        />
                        <button onClick={() => createNewLearner()} type="button" className='bg-secondary hover:bg-black lg:order-3 flex items-center justify-center px-10 h-10 2xl:h-12 text-white inter-600 text-base 2xl:text-xl rounded-3xl lg:rounded-full transition duration-300 button-shadow w-full md:w-auto'>+ Add New Learner</button>
                    </div>
                    <div className='lg:px-20 px-6'>
                        <div
                            className="ag-theme-quartz" // applying the Data Grid theme
                            style={{ width: '100%', height: 500 }} // the Data Grid will fill the size of the parent container
                            >
                                {customers.length > 0 ? (
                                    <AgGridReact
                                        key={refreshKey}
                                        rowData={users}
                                        columnDefs={colDefs}
                                        defaultColDef={defaultColDef}
                                        rowSelection="multiple"
                                        suppressRowClickSelection={true}
                                        pagination={true}
                                        paginationPageSize={20}
                                        paginationPageSizeSelector={pageSizes}
                                    />
                                ) : (
                                    null
                                )}

                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};