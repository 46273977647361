import React, { useState, useEffect} from 'react';
import '../../spinner.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { getUserReport } from '../../services/EvaluationService';
import {Chart, ArcElement, Tooltip, Legend, Title} from 'chart.js';
import { userDefaultPicture } from '../../utility/constants';
import {Doughnut} from 'react-chartjs-2';
Chart.register(ArcElement, Tooltip, Legend, Title);

export default function UserReport() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});

    const [colors, setColors] = useState(['Red', 'Blue', 'Yellow', 'Green', 'Pink', 'Orange', 'Grey', 'Violet', 'Turquoise']);
    const [certificates, setCertificates] = useState("");
    const [avgPoScore, setAvgPoScore] = useState(0);
    const [courseReport, setCourseReport] = useState({course_completed: 0, modules_completed: 0, tests_completed: 0, min_spent: 0});
    const [courseDetailedReport, setCourseDetailedReport] = useState([]);
    const [data, setData] = useState({
      labels: [],
      datasets: [{
          label: 'Dataset',
          data: [],
          backgroundColor: [],
          hoverOffset: 4
      }]
    });

    const options = {
        plugins: {
            tooltip: {
                callbacks: {
                    label: function(context) {
                        const index = context.dataIndex;
                        let course_name = courseDetailedReport[index].course_name;
                        let modules_completed = courseDetailedReport[index].modules_completed;
                        let pr_completed = courseDetailedReport[index].pr_completed;
                        let po_completed = courseDetailedReport[index].po_completed;
                        let total_learning_time = courseDetailedReport[index].mins_spent;
                        return [course_name, modules_completed+ " Modules Completed", pr_completed+ " Pre-Learning Assessments", po_completed+ " Post-Learning Assessments", total_learning_time+ " Minutes Spent"];
                    },
                    title: function() {
                        return 'Detailed Data';
                    }
                },
                useHTML: true
            }
        }
    };

    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
            let userReport = sessionStorage.getItem('userReport') !== undefined ? JSON.parse(sessionStorage.getItem('userReport')) : {};
            if(userReport !== null && userReport !== undefined){
                setUser(userReport);
                sessionStorage.removeItem('userReport');
                getAllData(userDetails, userReport);

            }else{
                navigate('/admin/learnerreport');
            }
            
        }


        Chart.defaults.plugins.tooltip.backgroundColor = 'rgb(0, 0, 156)';
        Chart.defaults.plugins.legend.position = 'bottom';
        Chart.defaults.plugins.legend.title.display = true;
        Chart.defaults.plugins.legend.title.text = 'User Data';
        Chart.defaults.plugins.legend.title.font = 'Helvetica Neue';
    },[]);

    const getAllData = async (userDetails, userReport) => {
        setLoading(true);
        await getUserReportData(userDetails.token, userReport.user_id);
        setLoading(false);
    };

    const getUserReportData = async (token, user_id) => {
        let resp = await getUserReport(token, user_id);
        if(resp.error === false){
            let reportData = resp.data.score_result !== undefined && resp.data.score_result !== null ? resp.data.score_result : [];
            let dataToSet = "";
            let count = 0;
            let score = 0;
            for(let i=0; i<reportData.length; i++){
                if(dataToSet!==""){
                    dataToSet = dataToSet+" | ";
                }
                dataToSet = dataToSet + reportData[i].course_name;
                score = score + (reportData[i].po_score !== undefined && reportData[i].po_score !== null ? reportData[i].po_score : 0);
                count = count + 1;
            }
            if(count > 0){
                setAvgPoScore(Math.ceil(score/count));
            }
            setCertificates(dataToSet);
            let courseData = resp.data.course_result !== undefined && resp.data.course_result !== null ? resp.data.course_result : [];
            let modules_completed = 0;
            let course_completed = 0;
            let tests_completed = 0;
            let learning_secs = 0;
            let labels = [];
            let dataValues = [];
            let bgColors = [];
          
            let newData = {
                labels: labels,
                datasets: [{
                    label: 'Dataset',
                    data: dataValues,
                    backgroundColor: bgColors,
                    hoverOffset: 4
                }]
            };

            for(let i=0; i<courseData.length; i++){
                courseData[i].learning_sec = parseInt(courseData[i].learning_sec);
                courseData[i].modules_completed = parseInt(courseData[i].modules_completed);
                courseData[i].num_modules = parseInt(courseData[i].num_modules);
                courseData[i].po_modules = parseInt(courseData[i].po_modules);
                courseData[i].pr_modules = parseInt(courseData[i].pr_modules);
                courseData[i].po_completed = parseInt(courseData[i].po_modules);
                courseData[i].pr_completed = parseInt(courseData[i].pr_modules);
                courseData[i].mins_spent = Math.ceil(courseData[i].learning_sec/60);

                labels.push(courseData[i].course_name);
                dataValues.push(courseData[i].mins_spent);
                bgColors.push(colors[i]);

                if(courseData[i].modules_completed + courseData[i].po_modules + courseData[i].pr_modules === courseData[i].num_modules){
                    course_completed = course_completed + 1;
                }
                modules_completed = modules_completed + courseData[i].modules_completed;
                tests_completed = tests_completed + courseData[i].po_modules + courseData[i].pr_modules;
                learning_secs = learning_secs + courseData[i].learning_sec;
            }
            setCourseDetailedReport(courseData)
            setCourseReport({course_completed: course_completed, modules_completed: modules_completed, tests_completed: tests_completed, min_spent: Math.ceil(learning_secs/60)});
            setData(newData);

        }else{
            toast.error(resp.data);
        }
    };

    return (
        <div>
          {loading ? (
            <div className="spinner-container">
              <img src='images/loading.gif' alt='GIF' />
            </div>
            ) : (
                <>
                <div className='pt-10 lg:px-12 px-6 pb-12'>
                    <h3 className='text-2xl text-secondary text-center inter-700 lg:hidden mb-4'>User Report</h3>
                    <div className='back-btn flex justify-start mb-5 md:mb-8'>
                        <a onClick={() => navigate('/admin/learnerreport')} className='cursor-pointer w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-7 2xl:h-8 text-white inter-500 text-xs 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a>
                    </div>
                    <div className='report-prf-row flex items-center flex-wrap  mb-10'>
                        <div className='report-prf-icon w-full md:w-1/12'>
                            <img className='rounded-image h-20 w-20 object-cover' src={user.image_link === undefined || user.image_link === null ? userDefaultPicture : user.image_link} alt='' />
                        </div>
                        <div className='report-prf-dtl w-full md:w-11/12 md:pl-10 mt-4 md:mt-0'>
                            <h3 className='text-2xl text-primary inter-700'>{user.name}</h3>
                            <p className='text-base text-[#A30D11] inter-500'>{user.cohort_name}</p>
                        </div>
                    </div>
                    <div className='report-crtfd flex mb-7'>
                        <div className='md:w-1/12'></div>
                        <div className='w-full md:w-11/12 md:pl-10'>
                            <h3 className='text-2xl text-tertiary inter-700'>Certificates Earned</h3>
                                <p className='text-lg text-[#DF0000] inter-500'>{certificates}</p>
                        </div>
                    </div>
                    <div className='report-card-row flex'>
                        <div className='md:w-1/12'></div>
                        <div className='w-full md:w-11/12 md:pl-10'>
                            <div className='cohort-big-card rounded-3xl lg:p-10 p-8 mb-8'>
                                <h2 className='text-3xl text-white inter-700 mb-4'>Summary</h2>
                                <div className='flex items-center flex-wrap'>
                                    <div className='w-full md:w-1/2'>
                                        <div className='cohort-big-card-left'>
                                            <div className='mb-3 md:mb-6'>
                                                <h3 className='text-2xl text-white inter-700'>{Math.ceil(avgPoScore)}%</h3>
                                                <p className='text-base 2xl:text-lg text-white inter-400'>Avg Post-Learning Assessment Score</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-full md:w-1/2 mt-3 md:mt-0'>
                                        <div className='cohort-big-card-right'>
                                            <p className='text-xl text-white inter-400 mb-3'><span className='inter-700'>{courseReport.course_completed}</span> Technology Completed </p>
                                            <p className='text-xl text-white inter-400 mb-3'><span className='inter-700'>{courseReport.modules_completed}</span> Modules Completed </p>
                                            <p className='text-xl text-white inter-400 mb-3'><span className='inter-700'>{courseReport.tests_completed}</span> Tests Completed </p>
                                            <p className='text-xl text-white inter-400 mb-0'><span className='inter-700'>{courseReport.min_spent}</span> Minutes Spent </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='report-graph flex justify-center'>
                                  <div className='w-full lg:w-2/5 bg-[#F1EAEA] rounded-3xl p-6'>
                                      <Doughnut data={data} options={options} />
                                  </div>
                            </div>
                        </div>
                    </div>
                    
                </div> 
                </>
            )
          }
        </div>
    );
}