import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../services/UserService';
import { toast } from 'react-toastify';
import { getCustomerData, updatePortalColors, customerLogoUpload } from '../services/CustomerService';

export default function CustomizePortal() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [selectedFileName, setSelectedFileName] = useState("Filename");
    const [colors, setColors] = useState({pri_color: "", sec_color: "", ter_color: ""});
    const [customer, setCustomer] = useState({});
    const [file, setFile] = useState(null);
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
          setUserDetails(userDetails);
          getCustomer();
        }
    },[]);

    const getCustomer = async () => {
        const userDetails = getUserDetails();
        let resp = await getCustomerData(userDetails.token);
        if(resp.error === false){
            setCustomer(resp.data);
            let cust = resp.data;
            let clr = {pri_color: cust.pri_color, sec_color: cust.sec_color, ter_color: cust.ter_color};
            setColors(clr);
            let colorPicker1 = document.getElementById("color-picker1");
            let colorValue1 = document.getElementById("value1");
            colorValue1.innerHTML = cust.pri_color;
            colorValue1.style.color = cust.pri_color;
            colorPicker1.value = cust.pri_color;

            let colorPicker2 = document.getElementById("color-picker2");
            let colorValue2 = document.getElementById("value2");
            colorValue2.innerHTML = cust.sec_color;
            colorValue2.style.color = cust.sec_color;
            colorPicker2.value = cust.sec_color;

            let colorPicker3 = document.getElementById("color-picker3");
            let colorValue3 = document.getElementById("value3");
            colorValue3.innerHTML = cust.ter_color;
            colorValue3.style.color = cust.ter_color;
            colorPicker3.value = cust.ter_color;
        }
    };

    const primaryColorChange = () => {
        let colorPicker = document.getElementById("color-picker1");
        let colorValue = document.getElementById("value1");
        colorValue.innerHTML = colorPicker.value
        colorValue.style.color = colorPicker.value
        colorPicker.value = colorPicker.value
        setColors({
            ...colors,
            ['pri_color']: colorPicker.value
        }); 
    };

    const secondaryColorChange = () => {
        let colorPicker = document.getElementById("color-picker2");
        let colorValue = document.getElementById("value2");
        colorValue.innerHTML = colorPicker.value
        colorValue.style.color = colorPicker.value
        colorPicker.value = colorPicker.value
        setColors({
            ...colors,
            ['sec_color']: colorPicker.value
        });
    };

    const tertiaryColorChange = () => {
        let colorPicker = document.getElementById("color-picker3");
        let colorValue = document.getElementById("value3");
        colorValue.innerHTML = colorPicker.value
        colorValue.style.color = colorPicker.value
        colorPicker.value = colorPicker.value
        setColors({
            ...colors,
            ['ter_color']: colorPicker.value
        });
    };

    const handleFileChange = async (event) => {
        const file1 = event.target.files[0];
        if (file1) {
            setFile(event.target.files[0]);
            setSelectedFileName(file1.name);
        } else {
            setSelectedFileName('No file selected');
        }
    };

    const updateColors = async () => {
        const userDetails = getUserDetails();
        setLoading(true);
        let resp = await updatePortalColors(userDetails.token, colors);
        if(resp.error){
            toast.error(resp.data);
        }else{
            if(colors.pri_color !== "" && colors.pri_color !== null && colors.pri_color !== undefined){
                document.documentElement.style.setProperty('--primary-color', colors.pri_color);
            }
            if(colors.sec_color !== "" && colors.sec_color !== null && colors.sec_color !== undefined){
                document.documentElement.style.setProperty('--secondary-color', colors.sec_color);
            }
            if(colors.ter_color !== "" && colors.ter_color !== null && colors.ter_color !== undefined){
                document.documentElement.style.setProperty('--tertiary-color', colors.ter_color);
            }
            toast.success("You Colors are updated. Please login back to see the effects!");
        }
        setLoading(false);
        await getCustomer();
    };

    const handleUpload = async () => {
        const userDetails = getUserDetails();
        if (!file) {
          toast.error('Please select a file.');
          return;
        }
    
        const formData = new FormData();
        formData.append('file', file);
        setLoading(true);
        let resp = await customerLogoUpload(userDetails.token, formData);
        setLoading(false);
        if (resp.error) {
            toast.error('Failed to upload file!');
        }else{
            toast.success(resp.data);
        }
    };

    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className='lg:py-10 lg:px-20 xl:px-32 py-10 px-10'>
                    <h3 className='text-2xl text-secondary text-center inter-700 mb-6 md:mb-4 lg:mb-3 lg:hidden'>Customize Portal</h3>
                    <div className='back-btn flex justify-start mb-5 md:mb-8'>
                        <a onClick={() => navigate('/admin/dashboard')} className='cursor-pointer w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-7 2xl:h-8 text-white inter-500 text-xs 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a>
                    </div>
                    <form className='w-full'>
                        <p className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Upload Logo</p>
                        <div className='flex justify-between flex-wrap w-full mb-5 md:mb-8'>
                            <div className='form-field w-full md:w-3/5 md:pr-5 mb-4 md:mb-0'>
                                <p className='upld-logo-name inter-400 bg-[#cecece] text-black text-xs 2xl:text-base leading-4 border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300 truncate'>{selectedFileName}</p>
                            </div>
                            <div className='form-btn relative w-full md:w-1/5 md:pr-5 mb-4 md:mb-0'>
                                <label htmlFor="upload" className='bg-[#BF2D88] text-white hover:bg-black cursor-pointer flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Browse</label>
                                <input className='absolute left-0 top-0 w-full h-full opacity-0 invisible cursor-pointer' type="file" id="upload" onChange={handleFileChange} />
                            </div>
                            <div className='form-btn relative w-full md:w-1/5 md:pr-5'>
                                <a onClick={() => handleUpload()} className='bg-tertiary text-white hover:bg-black cursor-pointer flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Upload</a>
                            </div>
                        </div>
                        <div className='w-full'>
                            <div className='flex justify-between flex-wrap w-full'>
                                <div className='color-palet w-full md:w-2/6 mb-5 md:mb-8 md:pr-5'>
                                    <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Primary Color</label>
                                    <input type="color" id="color-picker1" onChange={primaryColorChange} className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                    <p className='text-black text-xs 2xl:text-base mt-2 mb-2'>Menu panel/ other sections</p>
                                    <span id="value1" className='text-base inter-600'></span>
                                </div>
                                <div className='color-palet w-full md:w-2/6 mb-5 md:mb-8 md:pr-5'>
                                    <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Secondary Color</label>
                                    <input type="color" id="color-picker2" onChange={secondaryColorChange} className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                    <p className='text-black text-xs 2xl:text-base mt-2'>Headings & Main Buttons</p>
                                    <span id="value2" className='text-base inter-600'></span>
                                </div>
                                <div className='color-palet w-full md:w-2/6 mb-5 md:mb-8 md:pr-5'>
                                    <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Tertiary Color</label>
                                    <input type="color" id="color-picker3" onChange={tertiaryColorChange} className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                    <p className='text-black text-xs 2xl:text-base mt-2'>Secondary buttons & links</p>
                                    <span id="value3" className='text-base inter-600'></span>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center mt-6 2xl:mt-10'>
                            <button onClick={updateColors} type="button" className='w-full md:w-auto bg-secondary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Update Colors</button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
}