import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { addCustomerAdminPermission, updateCustomerAdminPermission, getCustomerAdminPermission } from '../../services/UserService';
import { toast } from 'react-toastify';
import { getCountryList } from '../../services/CommonService';
import Select from 'react-select';

export default function AdminPermission() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [user, setUser] = useState({first_name: "", middle_name: "", last_name: "", email: "", password: "", status: "ACTIVE", role: "ACCOUNT_OWNER", state: "", city: "", country: "USA", addr_line1: "", addr_line2: "", zip_code: "", phone_no: ""});
    const [edit, setEdit] = useState(false);
    const [customerPermission, setCustomerPermission] = useState({});
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
          setUserDetails(userDetails);
          let adminUser = sessionStorage.getItem('adminPermissionEdit') !== undefined ? JSON.parse(sessionStorage.getItem('adminPermissionEdit')) : {};
          if(adminUser !== null && adminUser !== undefined && adminUser.email !== undefined){
            setUser(adminUser);
            setEdit(true);
            sessionStorage.removeItem('adminPermissionEdit');
            getCustomerAdminPermissionData(adminUser.id);
          }else{
            toast.warning("Please select a admin on Manage Admin page.");
            navigate('/admin/manageadmin');
          }
        }
    },[]);

    const getCustomerAdminPermissionData = async (user_id) => {
        const userDtl = getUserDetails();
        const customerPermissionData = await getCustomerAdminPermission(userDtl.token, user_id);
        if(!customerPermissionData.error){
            let data = customerPermissionData.data;
            let permissionObj = {};
            let perms = {};
            for(let i=0; i<data.length; i++){
                permissionObj[data[i].permission_code] = data[i];
                perms[data[i].permission_code] = true;
            }
            setCustomerPermission(permissionObj);
        }
    };

    const handleValueChange = async (permission_code) => {
        const userDetails = getUserDetails();
        if(customerPermission[permission_code]===undefined){
            let dataToPost = {user_id: user.id, permission_code: permission_code};
            await addAdminPermission(userDetails.token, dataToPost);
        }else{
            let permission = customerPermission[permission_code];
            let dataToPost = {id: permission.id, permission_code: "P0"};
            await updateAdminPermission(userDetails.token, dataToPost)
        }
        //await getCustomerAdminPermissionData(user.id);
    };

    const addAdminPermission = async (token, data) => {
        setLoading(true);
        let resp = await addCustomerAdminPermission(token, data);
        if(!resp.error){
            toast.success("Admin Permission changed!");
        }else{
            toast.error("Admin Permission not changed!");
        }
        setLoading(false);
        await getCustomerAdminPermissionData(user.id);

    };

    const updateAdminPermission = async (token, data) => {
        setLoading(true);
        let resp = await updateCustomerAdminPermission(token, data);
        if(!resp.error){
            toast.success("Admin Permission changed!");
        }else{
            toast.error("Admin Permission not changed!");
        }
        setLoading(false);
        await getCustomerAdminPermissionData(user.id);
    };

    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
            <div className='pt-10 lg:px-12 px-6 pb-12'>
                <h3 className='text-2xl text-secondary inter-700 lg:hidden mb-4'>Manage Permissions</h3>
                <div className='back-btn flex justify-start mb-5 md:mb-8'>
                    <a onClick={() => navigate('/admin/manageadmin')} className='cursor-pointer w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-7 2xl:h-8 text-white inter-500 text-xs 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a>
                </div>
                <div className=''>
                    <h4 className='text-xl text-secondary inter-500 mb-4 md:mb-8'>Admin User ID: {user.email}</h4>
                    <form>
                        <div className='permisn-sngl mb-6'>
                            <div className='flex flex-wrap lg:w-4/5'>
                                <div className='w-full md:w-1/2'>
                                    <div className='icon-check-medium md:pr-4'>
                                        <div className='icon-radio-button flex items-center relative text-black hover:text-tertiary mb-6'>
                                            <input className='absolute opacity-0 cursor-pointer w-6 h-6 md:w-8 md:h-8 z-20' type='checkbox' name='checkbox' id='photonics1' value="P1" checked={customerPermission["P1"] !== undefined} onChange={() => handleValueChange("P1")} />
                                            <div className='radio-icon-box cursor-pointer relative z-10'>
                                                <svg className='mx-auto w-6 h-6 md:w-8 md:h-8 transition duration-300' xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M10.416 8.33317C9.26542 8.33317 8.33268 9.26591 8.33268 10.4165V39.5832C8.33268 40.7338 9.26542 41.6665 10.416 41.6665H39.5827C40.7333 41.6665 41.666 40.7338 41.666 39.5832V10.4165C41.666 9.26591 40.7333 8.33317 39.5827 8.33317H10.416ZM4.16602 10.4165C4.16602 6.96472 6.96424 4.1665 10.416 4.1665H39.5827C43.0345 4.1665 45.8327 6.96472 45.8327 10.4165V39.5832C45.8327 43.0349 43.0345 45.8332 39.5827 45.8332H10.416C6.96424 45.8332 4.16602 43.0349 4.16602 39.5832V10.4165Z" fill="black"/>
                                                </svg>
                                                <span className='checkbox-tick inline-block w-3 h-3 absolute left-1/2 -translate-x-1/2 scale-0 rotate-45'></span>
                                            </div>
                                            <label className='cursor-pointer' htmlFor="photonics1">
                                                <p className='text-lg md:text-xl inter-400 text-black pl-4'>Admin Management</p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full md:w-1/2'>
                                    <div className='icon-check-medium md:pr-4'>
                                        <div className='icon-radio-button flex items-center relative text-black hover:text-tertiary mb-6'>
                                            <input className='absolute opacity-0 cursor-pointer w-6 h-6 md:w-8 md:h-8 z-20' type='checkbox' name='checkbox' id='photonics1' value="P1" checked={customerPermission["P2"] !== undefined} onChange={() => handleValueChange("P2")} />
                                            <div className='radio-icon-box cursor-pointer relative z-10'>
                                                <svg className='mx-auto w-6 h-6 md:w-8 md:h-8 transition duration-300' xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M10.416 8.33317C9.26542 8.33317 8.33268 9.26591 8.33268 10.4165V39.5832C8.33268 40.7338 9.26542 41.6665 10.416 41.6665H39.5827C40.7333 41.6665 41.666 40.7338 41.666 39.5832V10.4165C41.666 9.26591 40.7333 8.33317 39.5827 8.33317H10.416ZM4.16602 10.4165C4.16602 6.96472 6.96424 4.1665 10.416 4.1665H39.5827C43.0345 4.1665 45.8327 6.96472 45.8327 10.4165V39.5832C45.8327 43.0349 43.0345 45.8332 39.5827 45.8332H10.416C6.96424 45.8332 4.16602 43.0349 4.16602 39.5832V10.4165Z" fill="black"/>
                                                </svg>
                                                <span className='checkbox-tick inline-block w-3 h-3 absolute left-1/2 -translate-x-1/2 scale-0 rotate-45'></span>
                                            </div>
                                            <label className='cursor-pointer' htmlFor="photonics1">
                                                <p className='text-lg md:text-xl inter-400 text-black pl-4'>Cohort Management</p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full md:w-1/2'>
                                    <div className='icon-check-medium md:pr-4'>
                                        <div className='icon-radio-button flex items-center relative text-black hover:text-tertiary mb-6'>
                                            <input className='absolute opacity-0 cursor-pointer w-6 h-6 md:w-8 md:h-8 z-20' type='checkbox' name='checkbox' id='photonics2' value="P2" checked={customerPermission["P3"] !== undefined} onChange={() => handleValueChange("P3")}/>
                                            <div className='radio-icon-box cursor-pointer relative z-10'>
                                                <svg className='mx-auto w-6 h-6 md:w-8 md:h-8 transition duration-300' xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M10.416 8.33317C9.26542 8.33317 8.33268 9.26591 8.33268 10.4165V39.5832C8.33268 40.7338 9.26542 41.6665 10.416 41.6665H39.5827C40.7333 41.6665 41.666 40.7338 41.666 39.5832V10.4165C41.666 9.26591 40.7333 8.33317 39.5827 8.33317H10.416ZM4.16602 10.4165C4.16602 6.96472 6.96424 4.1665 10.416 4.1665H39.5827C43.0345 4.1665 45.8327 6.96472 45.8327 10.4165V39.5832C45.8327 43.0349 43.0345 45.8332 39.5827 45.8332H10.416C6.96424 45.8332 4.16602 43.0349 4.16602 39.5832V10.4165Z" fill="black"/>
                                                </svg>
                                                <span className='checkbox-tick inline-block w-3 h-3 absolute left-1/2 -translate-x-1/2 scale-0 rotate-45'></span>
                                            </div>
                                            <label className='cursor-pointer' htmlFor="photonics2">
                                                <p className='text-lg md:text-xl inter-400 text-black pl-4'>Learner Management</p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full md:w-1/2'>
                                    <div className='icon-check-medium md:pr-4'>
                                        <div className='icon-radio-button flex items-center relative text-black hover:text-tertiary mb-6'>
                                            <input className='absolute opacity-0 cursor-pointer w-6 h-6 md:w-8 md:h-8 z-20' type='checkbox' name='checkbox' id='photonics3' value="P3" checked={customerPermission["P4"] !== undefined} onChange={() => handleValueChange("P4")}/>
                                            <div className='radio-icon-box cursor-pointer relative z-10'>
                                                <svg className='mx-auto w-6 h-6 md:w-8 md:h-8 transition duration-300' xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M10.416 8.33317C9.26542 8.33317 8.33268 9.26591 8.33268 10.4165V39.5832C8.33268 40.7338 9.26542 41.6665 10.416 41.6665H39.5827C40.7333 41.6665 41.666 40.7338 41.666 39.5832V10.4165C41.666 9.26591 40.7333 8.33317 39.5827 8.33317H10.416ZM4.16602 10.4165C4.16602 6.96472 6.96424 4.1665 10.416 4.1665H39.5827C43.0345 4.1665 45.8327 6.96472 45.8327 10.4165V39.5832C45.8327 43.0349 43.0345 45.8332 39.5827 45.8332H10.416C6.96424 45.8332 4.16602 43.0349 4.16602 39.5832V10.4165Z" fill="black"/>
                                                </svg>
                                                <span className='checkbox-tick inline-block w-3 h-3 absolute left-1/2 -translate-x-1/2 scale-0 rotate-45'></span>
                                            </div>
                                            <label className='cursor-pointer' htmlFor="photonics3">
                                                <p className='text-lg md:text-xl inter-400 text-black pl-4'>Evaluation & Reports</p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full md:w-1/2'>
                                    <div className='icon-check-medium md:pr-4'>
                                        <div className='icon-radio-button flex items-center relative text-black hover:text-tertiary mb-6'>
                                            <input className='absolute opacity-0 cursor-pointer w-6 h-6 md:w-8 md:h-8 z-20' type='checkbox' name='checkbox' id='photonics4' value="P4" checked={customerPermission["P5"] !== undefined} onChange={() => handleValueChange("P5")}/>
                                            <div className='radio-icon-box cursor-pointer relative z-10'>
                                                <svg className='mx-auto w-6 h-6 md:w-8 md:h-8 transition duration-300' xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M10.416 8.33317C9.26542 8.33317 8.33268 9.26591 8.33268 10.4165V39.5832C8.33268 40.7338 9.26542 41.6665 10.416 41.6665H39.5827C40.7333 41.6665 41.666 40.7338 41.666 39.5832V10.4165C41.666 9.26591 40.7333 8.33317 39.5827 8.33317H10.416ZM4.16602 10.4165C4.16602 6.96472 6.96424 4.1665 10.416 4.1665H39.5827C43.0345 4.1665 45.8327 6.96472 45.8327 10.4165V39.5832C45.8327 43.0349 43.0345 45.8332 39.5827 45.8332H10.416C6.96424 45.8332 4.16602 43.0349 4.16602 39.5832V10.4165Z" fill="black"/>
                                                </svg>
                                                <span className='checkbox-tick inline-block w-3 h-3 absolute left-1/2 -translate-x-1/2 scale-0 rotate-45'></span>
                                            </div>
                                            <label className='cursor-pointer' htmlFor="photonics4">
                                                <p className='text-lg md:text-xl inter-400 text-black pl-4'>Customize Portal</p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='flex justify-between lg:mx-12 lg:px-0 px-6 pb-5 mt-6'>
                            <button type="button" className='bg-secondary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-600 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow w-full md:w-auto'>+ Update Permission</button>
                        </div> */}
                    </form>
                </div>
            </div>
            )}
        </div>
    );
}