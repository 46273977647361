import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { toast } from 'react-toastify';
import { getAvgScore, getLearningTime, getMaxScore, getMinScore, getAttendance } from '../../services/SuperAdminReportService';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { getRandomHexColor } from '../../services/CommonService';
import Select from 'react-select';
import { getAllCourse } from '../../services/CourseService';
import { getAllCustomers } from '../../services/CustomerService';
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);


export default function SuperAdminAnalytics() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [course, setCourse] = useState([{label: "All", value: 0}]);
    const [selectedCourse, setSelectedCourse] = useState(0);
    const [customer, setCustomer] = useState([{label: "All", value: 0}]);
    const [selectedCustomer, setSelectedCustomer] = useState(0);
    const [metric, setMetric] = useState([{label: "Avg Score", value: 1}, {label: "Learning Time", value: 2}, {label: "Max Score", value: 3}, {label: "Min Score", value: 4}, {label: "Attendance", value: 5}]);
    const [selectedMetric, setSelectedMetric] = useState(1);
    const [data, setData] = useState([]);
    const [report, setReport] = useState({
        labels: [],
        datasets: [
          {
            label: 'Avg Score',
            data: [],
            backgroundColor: [],
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          },
        ],
    });
    const optionsAvgScore = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          tooltip: {
            callbacks: {
                label: function(context) {
                    const index = context.dataIndex;
                    let cohort_desc = data[index].cohort_desc;
                    let avg_score = data[index].avg_score;
                    let org_name = data[index].org_name;
                    let customer_name = data[index].customer_name;

                    return [
                            "Name: "+cohort_desc,
                            "Avg Score: "+avg_score,
                            "Org: "+ org_name,
                            "Customer: "+ customer_name
                        ]
                },
                title: function() {
                    return 'Detailed Data';
                }
            },
          },
        },
    };
    let optionsLearningTime = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          tooltip: {
            callbacks: {
                label: function(context) {
                    const index = context.dataIndex;
                    let cohort_desc = data[index].cohort_desc;
                    let learning_sec = data[index].learning_sec;
                    let org_name = data[index].org_name;
                    let customer_name = data[index].customer_name;

                    return [
                            "Name: "+cohort_desc,
                            "Learning Time in Seconds: "+learning_sec,
                            "Org: "+ org_name,
                            "Customer: "+ customer_name          
                    ]
                },
                title: function() {
                    return 'Detailed Data';
                }
            },
          },
        },
    };
    let optionsMaxScore = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          tooltip: {
            callbacks: {
                label: function(context) {
                    const index = context.dataIndex;
                    let cohort_desc = data[index].cohort_desc;
                    let max_score = data[index].max_score;
                    let org_name = data[index].org_name;
                    let customer_name = data[index].customer_name;

                    return [
                            "Name: "+cohort_desc,
                            "Max Score: "+max_score,
                            "Org: "+ org_name,  
                            "Customer: "+ customer_name          
                    ]
                },
                title: function() {
                    return 'Detailed Data';
                }
            },
          },
        },
    };
    let optionsMinScore = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          tooltip: {
            callbacks: {
                label: function(context) {
                    const index = context.dataIndex;
                    let cohort_desc = data[index].cohort_desc;
                    let min_score = data[index].min_score;
                    let org_name = data[index].org_name;
                    let customer_name = data[index].customer_name;

                    return [
                            "Name: "+cohort_desc,
                            "Min Score: "+min_score,
                            "Org: "+ org_name,   
                            "Customer: "+ customer_name         
                    ]
                },
                title: function() {
                    return 'Detailed Data';
                }
            },
          },
        },
    };
    let optionsAttendance = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          tooltip: {
            callbacks: {
                label: function(context) {
                    const index = context.dataIndex;
                    let cohort_desc = data[index].cohort_desc;
                    let attendance = data[index].attendance;
                    let org_name = data[index].org_name;
                    let customer_name = data[index].customer_name;

                    return [
                            "Name: "+cohort_desc,
                            "Attendance: "+attendance,
                            "Org: "+ org_name, 
                            "Customer: "+ customer_name           
                    ]
                },
                title: function() {
                    return 'Detailed Data';
                }
            },
          },
        },
    };
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
            navigate('/login');
        }else{
            setUserDetails(userDetails);
            getAllCourseData(userDetails.token);
            getAllCustomerData(userDetails.token);
            handleValueChange(1, "metric");
        }
    },[]);

    const getAvgScoreData = async (fltr) => {
        const userDetails = getUserDetails();
        if(userDetails.token !== undefined){
            const response = await getAvgScore(userDetails.token, fltr);
            if(!response.error){
                setData(response.data);
                let data = {
                    labels: [],
                    datasets: [
                      {
                        label: "Avg Score",
                        data: [],
                        backgroundColor: [],
                        borderColor: "blue",
                        borderWidth: 1,
                      },
                    ],
                };
                for(let i=0; i<response.data.length; i++){
                    let data1 = response.data[i];
                    if(data1.avg_score === undefined || data1.avg_score === null){
                        data.datasets[0].data.push(0);
                    }else{
                        data.datasets[0].data.push(data1["avg_score"]);
                    }
                    data.labels.push(data1.cohort_desc);
                    data.datasets[0].backgroundColor.push(getRandomHexColor());
                }
                setReport(data);
            }
        }
    };

    const getMaxScoreData = async (fltr) => {
        const userDetails = getUserDetails();
        if(userDetails.token !== undefined){
            const response = await getMaxScore(userDetails.token, fltr);
            if(!response.error){
                setData(response.data);
                let data = {
                    labels: [],
                    datasets: [
                      {
                        label: "Max Score",
                        data: [],
                        backgroundColor: [],
                        borderColor: "blue",
                        borderWidth: 1,
                      },
                    ],
                };
                for(let i=0; i<response.data.length; i++){
                    let data1 = response.data[i];
                    if(data1.max_score === undefined || data1.max_score === null){
                        data.datasets[0].data.push(0);
                    }else{
                        data.datasets[0].data.push(data1["max_score"]);
                    }
                    data.labels.push(data1.cohort_desc);
                    data.datasets[0].backgroundColor.push(getRandomHexColor());
                }
                setReport(data);
            }
        }
    };

    const getMinScoreData = async (fltr) => {
        const userDetails = getUserDetails();
        if(userDetails.token !== undefined){
            const response = await getMinScore(userDetails.token, fltr);
            if(!response.error){
                setData(response.data);
                let data = {
                    labels: [],
                    datasets: [
                      {
                        label: "Min Score",
                        data: [],
                        backgroundColor: [],
                        borderColor: "blue",
                        borderWidth: 1,
                      },
                    ],
                };
                for(let i=0; i<response.data.length; i++){
                    let data1 = response.data[i];
                    if(data1.min_score === undefined || data1.min_score === null){
                        data.datasets[0].data.push(0);
                    }else{
                        data.datasets[0].data.push(data1["min_score"]);
                    }
                    data.labels.push(data1.cohort_desc);
                    data.datasets[0].backgroundColor.push(getRandomHexColor());
                }
                setReport(data);
            }
        }
    };

    const getLearningTimeData = async (fltr) => {
        const userDetails = getUserDetails();
        if(userDetails.token !== undefined){
            const response = await getLearningTime(userDetails.token, fltr);
            if(!response.error){
                setData(response.data);
                let data = {
                    labels: [],
                    datasets: [
                      {
                        label: "Learning Time",
                        data: [],
                        backgroundColor: [],
                        borderColor: "blue",
                        borderWidth: 1,
                      },
                    ],
                };
                for(let i=0; i<response.data.length; i++){
                    let data1 = response.data[i];
                    if(data1.learning_sec === undefined || data1.learning_sec === null){
                        data.datasets[0].data.push(0);
                    }else{
                        data.datasets[0].data.push(data1["learning_sec"]);
                    }
                    data.labels.push(data1.cohort_desc);
                    data.datasets[0].backgroundColor.push(getRandomHexColor());
                }
                setReport(data);
            }
        }
    };

    const getAttendanceData = async (fltr) => {
        const userDetails = getUserDetails();
        if(userDetails.token !== undefined){
            const response = await getAttendance(userDetails.token, fltr);
            if(!response.error){
                let rptData = [];
                let data = {
                    labels: [],
                    datasets: [
                      {
                        label: "Attendance",
                        data: [],
                        backgroundColor: [],
                        borderColor: "blue",
                        borderWidth: 1,
                      },
                    ],
                };
                for(let i=0; i<response.data.length; i++){
                    let data1 = response.data[i];
                    if(data1.attendance !== undefined && data1.attendance !== null && parseFloat(data1.attendance) !== 0){
                        data.datasets[0].data.push(data1["attendance"]);
                        data.labels.push(data1.cohort_desc);
                        data.datasets[0].backgroundColor.push(getRandomHexColor());
                        rptData.push(data1);
                    }

                }
                setData(rptData);
                setReport(data);
            }
        }
    };

    const handleValueChange = async (id, type) => {
        setLoading(true);
        let course_id = selectedCourse;
        let customer_id = selectedCustomer;
        if(type === "customer"){
            setSelectedCustomer(id);
            customer_id = id;
        }
        if(type === "course"){
            setSelectedCourse(id);
            course_id = id;
        }
        let metricType = selectedMetric;
        if(type === "metric"){
            setSelectedMetric(id);
            metricType = id;
            
        }
        let filter = {};
        if(course_id > 0){
            filter.course_id = course_id;
        }
        if(customer_id > 0){
            filter.customer_id = customer_id;
        }
        if(metricType === 1){
            await getAvgScoreData(filter);
        }
        if(metricType === 2){
            await getLearningTimeData(filter);
        }
        if(metricType === 3){
            await getMaxScoreData(filter);
        }
        if(metricType === 4){
            await getMinScoreData(filter);
        }
        if(metricType === 5){
            await getAttendanceData(filter);
        }
        setLoading(false); 
    };

    const getAllCourseData = async (token) => {
        setLoading(true);
        const courseData = await getAllCourse(token);
        if(courseData.error){
            return setCourse([{label: "All", value: 0}]);
        }else{
            let courseArray = [{label: "All", value: 0}];
            for(let i=0;i<courseData.data.length; i++){
                courseData.data[i]["label"] = courseData.data[i]['course_name'];
                courseData.data[i]["value"] = courseData.data[i]['course_id'];
                courseArray.push(courseData.data[i]);
            }
            setCourse(courseArray);
        }
        setLoading(false);
    };

    const getAllCustomerData = async (token) => {
        setLoading(true);
        const customerData = await getAllCustomers(token);
        if(customerData.error){
            setCustomer([{label: "All", value: 0}]);
        }else{
            let allCustomers = [{label: "All", value: 0}];
            for(let i=0; i< customerData.data.length; i++){
                let customer = Object.assign(customerData.data[i]);
                if(customer.id !== 0){
                    customer['value'] = customer.id;
                    customer['label'] = customer.name;
                    allCustomers.push(customer);
                }
            }
            setCustomer(allCustomers);
        }
        setLoading(false);
    };

    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className='lg:py-5 py-10 lg:px-12 px-6'>
                    <h3 className='text-2xl text-secondary inter-700 mb-6 md:mb-4 lg:mb-3 lg:hidden'>Analytics</h3>
                    {/* <div className='back-btn flex justify-start mb-5 md:mb-8'>
                        <a href='back' className='w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-7 2xl:h-8 text-white inter-500 text-xs 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a>
                    </div> */}
                    <div>
                        {/* <h4 className='text-2xl inter-700 text-[#6E3174]'>Select Y-Axis Values to generate graph visualization</h4> */}
                        {/* <div className='mt-1'>
                            <div className='flex items-center gap-8 mb-1'>
                                <div className='form-field w-full md:w-1/4'>
                                    <h3 className='text-xl text-primary inter-200'>Customer</h3>
                                </div>
                                <div className='form-field w-full md:w-1/4'>
                                    <h3 className='text-xl text-primary inter-200'>Course</h3>
                                </div>
                                <div className='form-field w-full md:w-1/4'>
                                    <h3 className='text-xl text-primary inter-200'>Metric</h3>
                                </div>
                            </div>
                        </div> */}
                        <div className='mt-6'>
                                <div className='flex items-center flex-wrap gap-4 md:gap-8 mb-6'>
                                    <div className='form-field w-full md:w-1/4'>
                                        <h3 className='text-xl text-primary inter-200 mb-2 md:mb-4'>Customer</h3>
                                        <Select className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md'
                                            options={customer}
                                            value={customer.find(option => option.value === selectedCustomer)}
                                            onChange={(values) => handleValueChange(values.value, "customer")}
                                        />
                                    </div>
                                    <div className='form-field w-full md:w-1/4'>
                                        <h3 className='text-xl text-primary inter-200 mb-2 md:mb-4'>Technology</h3>
                                        <Select className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md'
                                            options={course}
                                            value={course.find(option => option.value === selectedCourse)}
                                            onChange={(values) => handleValueChange(values.value, "course")}
                                        />
                                    </div>
                                    <div className='form-field w-full md:w-1/4'>
                                        <h3 className='text-xl text-primary inter-200 mb-2 md:mb-4'>Metric</h3>
                                        <Select className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md'
                                            options={metric}
                                            value={metric.find(option => option.value === selectedMetric)}
                                            onChange={(values) => handleValueChange(values.value, "metric")}
                                        />
                                    </div>
                                    {/* <div className='form-field flex-[0_0_auto]'>
                                        <select className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2 2xl:py-5 px-6 2xl:px-10 transition duration-300'>
                                            <option value="select-topic">Topic</option>
                                            <option value="topic-one">Topic One</option>
                                            <option value="topic-two">Topic Two</option>
                                        </select>
                                    </div>
                                    <div className='form-field flex-[0_0_auto]'>
                                        <select className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2 2xl:py-5 px-6 2xl:px-10 transition duration-300'>
                                            <option value="select-other">Other</option>
                                            <option value="other-one">Other One</option>
                                            <option value="other-two">Other Two</option>
                                        </select>
                                    </div>
                                    <div className='form-field flex-[0_0_auto]'>
                                        <select className='inter-400 bg-[#F8FAFC] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2 2xl:py-5 px-6 2xl:px-10 transition duration-300'>
                                            <option value="select-other">Other</option>
                                            <option value="other-one">Other One</option>
                                            <option value="other-two">Other Two</option>
                                        </select>
                                    </div> */}
                                </div>
                                {/* <div className='flex items-center overflow-y-auto gap-4 mb-10 no-scroll-visible'>
                                    <div className='flex items-center justify-between bg-[#D9D9D9] px-4 py-1 rounded-full flex-[0_0_auto]'>
                                        <p className='text-sm text-black inter-400 mr-3'>Classical & Quantum</p>
                                        <span className='cursor-pointer text-xs text-[#FF0000]'>X</span>
                                    </div>
                                    <div className='flex items-center justify-between bg-[#D9D9D9] px-4 py-1 rounded-full flex-[0_0_auto]'>
                                        <p className='text-sm text-black inter-400 mr-3'>SQCA</p>
                                        <span className='cursor-pointer text-xs text-[#FF0000]'>X</span>
                                    </div>
                                </div> */}
                                <div className='barg-graph-container'>
                                    {selectedMetric === 1 ? (
                                        <Bar data={report} options={optionsAvgScore} />
                                    ) : (
                                        null
                                    )}
                                    {selectedMetric === 2 ? (
                                        <Bar data={report} options={optionsLearningTime} />
                                    ) : (
                                        null
                                    )}
                                    {selectedMetric === 3 ? (
                                        <Bar data={report} options={optionsMaxScore} />
                                    ) : (
                                        null
                                    )}
                                    {selectedMetric === 4 ? (
                                        <Bar data={report} options={optionsMinScore} />
                                    ) : (
                                        null
                                    )}
                                    {selectedMetric === 5 ? (
                                        <Bar data={report} options={optionsAttendance} />
                                    ) : (
                                        null
                                    )}
                                </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}